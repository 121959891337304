import { createEntryPoint } from '@attentive/data-router';

import Query from './__generated__/AIJourneySettingsDefaultsPageQuery.graphql';

export const AIJourneySettingsFallbackMessagesEntryPoint = createEntryPoint({
  component: () => import('./AIJourneySettingsDefaultsPage'),
  getQueries: ({ getCurrentCompanyId }) => ({
    query: { parameters: Query, variables: { companyId: getCurrentCompanyId() } },
  }),
});
