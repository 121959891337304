declare global {
  interface Window {
    pendo?: {
      initialize(config: PendoConfig): void;
      track(event: string, properties?: Record<string, unknown>): void;
    };
  }
}

type PendoConfig = {
  visitor: {
    id: string;
    [index: string]: string | boolean | number;
  };
  account: {
    databaseId: number;
    [index: string]: string | boolean | number;
  };
};

function init(config: PendoConfig) {
  if (!window.pendo) return;
  window.pendo.initialize(config);
}

export default {
  init,
};
