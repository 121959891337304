import _useReadNotificationsClientUIMutation from "./__generated__/useReadNotificationsClientUIMutation.graphql";
import _useReadNotificationsClientUIFragment_notificationConnection from "./__generated__/useReadNotificationsClientUIFragment_notificationConnection.graphql";
import { useFragment, useMutation } from 'react-relay';
import { graphql } from '@attentive/data';
import { logDatadogError, useCurrentCompanyId } from '@attentive/acore-utils';
import useReadNotificationsClientUIFragment_notificationConnection from './__generated__/useReadNotificationsClientUIFragment_notificationConnection.graphql';
import useReadNotificationsClientUIMutation from './__generated__/useReadNotificationsClientUIMutation.graphql';
_useReadNotificationsClientUIFragment_notificationConnection;
_useReadNotificationsClientUIMutation;
export const useReadNotifications = notificationConnectionRef => {
  const companyId = useCurrentCompanyId();
  const [commitMutation] = useMutation(useReadNotificationsClientUIMutation);
  const notifications = useFragment(useReadNotificationsClientUIFragment_notificationConnection, notificationConnectionRef);
  const unreadNotifications = (notifications === null || notifications === void 0 ? void 0 : notifications.edges.filter(_ref => {
    let {
      node
    } = _ref;
    return node && !node.read;
  })) || [];
  return () => {
    unreadNotifications.forEach(_ref2 => {
      let {
        node: {
          id
        }
      } = _ref2;
      commitMutation({
        variables: {
          companyId,
          notificationInstanceId: id
        },
        onError: error => {
          logDatadogError('client-ui', error);
        }
      });
    });
  };
};