import { Config } from '@attentive/acore-utils';

const ACORE = 'acore';
const ATTENTIVE_PARTIAL_URI = 'attentivemobile';
const FETCH_TYPE = 'fetch';
const MARK_TYPE = 'mark';
const CUSTOM = 'Custom Event';
const ENVIRONMENT = Config.get('env');

export interface CustomUserTiming {
  name: string;
  durationMS: number;
  startMS: number;
  type: 'fetch' | 'mark';
}

let alreadySent = new Set();
let componentLoaderCount = 0;

export const resetModuleLevelItems = () => {
  alreadySent = new Set();
  componentLoaderCount = 0;
};

export const trackPerformanceEvent = (tag: CustomUserTiming) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { gtag } = window as any;
  const event = {
    name: tag.name,
    value: tag.durationMS || tag.startMS,
    event_category: tag.type,
    event_label: ENVIRONMENT,
  };
  if (gtag) {
    gtag('event', 'timing_complete', event);
  }
};

const shouldIncludeValue = (initiatorType: string, entryType: string, name: string) => {
  if (initiatorType !== FETCH_TYPE && entryType !== MARK_TYPE) {
    return false;
  }

  const isComponentLoader = name.startsWith('acore: ComponentLoader');

  componentLoaderCount += isComponentLoader ? 1 : 0;

  if (isComponentLoader && componentLoaderCount > 2) {
    return false;
  }

  if (!name.includes(ACORE) && !name.includes(ATTENTIVE_PARTIAL_URI)) {
    return false;
  }

  return true;
};

export const filterTags = (tags: PerformanceEntry[]) => {
  const newTags: CustomUserTiming[] = [];
  tags.forEach((entry) => {
    const { name, startTime, duration, entryType } = entry;

    // @ts-ignore missing PerformanceEntry.initiatorType definition
    const initiatorType = entry.initiatorType;
    const id = `${name}:${Math.floor(startTime)}`;

    const shouldInclude = shouldIncludeValue(initiatorType, entryType, name);

    if (!alreadySent.has(id) && shouldInclude) {
      const newTag = {
        name,
        startMS: Math.round(startTime),
        durationMS: Math.round(duration),
        type: initiatorType || CUSTOM,
      };
      newTags.push(newTag);
      alreadySent.add(id);
    }
  });
  return newTags;
};

export const sendTrackedTags = () => {
  if (window.performance && window.performance.getEntries) {
    const newTags = filterTags(window.performance.getEntries());
    newTags.forEach(trackPerformanceEvent);
  }
};
