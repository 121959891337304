/**
 * @generated SignedSource<<354e14786eab541c76ec25d62e5a251e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OnboardingTopBarClientUIFragment_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccountMenuClientUIFragment_query">;
  readonly " $fragmentType": "OnboardingTopBarClientUIFragment_query";
};
export type OnboardingTopBarClientUIFragment_query$key = {
  readonly " $data"?: OnboardingTopBarClientUIFragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"OnboardingTopBarClientUIFragment_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OnboardingTopBarClientUIFragment_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccountMenuClientUIFragment_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "3200600a069441ab4ce78bcc2145c4dd";

export default node;
