import React, { ReactNode } from 'react';

import { List, Banner, Text, Link } from '@attentive/picnic';
import { useCompanyFeatureFlag } from '@attentive/acore-utils';

import { useDegradationWarning } from '../../../../store/site-data';
import { useEmergencyMode } from '../../../../store/env-vars';

interface NotificationData {
  id: string;
  message: ReactNode;
}

const CS_EMAIL_ADDRESS = 'cs@attentivemobile.com';

export const NotificationSection: React.FC = () => {
  const inEmergencyMode = useEmergencyMode();

  const slowLoadTimes = useCompanyFeatureFlag('SHOW_SLOW_LOAD_TIMES_DIALOG_CLIENT_UI');
  const body = useDegradationWarning();
  const notifications: NotificationData[] = [];

  if (inEmergencyMode) {
    notifications.push({
      id: 'emergency-mode',
      message: (
        <Text as="span">
          A large AWS outage is currently impacting several parts of the Attentive platform. At this
          time, campaign sending and segment creation are working normally. Journeys, Conversations,
          and Reporting are not currently available in the UI; if you need any help, please email
          your CSM or our on-call team at{' '}
          <Link href={`mailto:${CS_EMAIL_ADDRESS}`}>{CS_EMAIL_ADDRESS}</Link>
        </Text>
      ),
    });
  }

  if (body && slowLoadTimes) {
    notifications.push({ id: 'degraded-state', message: body });
  }

  if (notifications.length === 0) {
    return null;
  }

  return (
    <List variant="unstyled">
      {notifications.map((notification) => (
        <List.Item
          key={notification.id}
          data-id={notification.id}
          css={{ margin: '$space2', padding: 0, listStyle: 'none' }}
        >
          <Banner variant="warning">
            <Banner.Text>{notification.message}</Banner.Text>
          </Banner>
        </List.Item>
      ))}
    </List>
  );
};
