import React from 'react';
import { LoadingIndicator, Box } from '@attentive/picnic';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { KeywordsPage } from '@attentive/tactical-ui';

const CustomCompanyKeywordsPage: React.FC = () => {
  return (
    <React.Suspense fallback={<LoadingIndicator />}>
      <Box css={{ mr: '$space12', ml: '$space6', mt: '$space12' }}>
        <KeywordsPage />
      </Box>
    </React.Suspense>
  );
};

export { CustomCompanyKeywordsPage };
