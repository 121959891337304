import React from 'react';
import { useQuery } from '@attentive/data/react-query';

import {
  Text,
  Grid,
  PicnicCss,
  Heading,
  Box,
  Link,
  TextInput,
  InputGroup,
  Icon,
  Separator,
  Button,
  IconButton,
} from '@attentive/picnic';
import { useAtomValue } from 'jotai';
import { DateFormat, formatDateWithTimezone, Locale, Timezone } from '@attentive/locale-utils';
import { commitDetailsAtom } from './commit-details-atom';

const cellCss: PicnicCss = {
  px: '$space1',
  pt: '$space3',
  pb: 0,
};

const MINIMUM_VISIBLE = 3;

export type DeployInfo = {
  commitSha: string;
  message: string;
  timestamp: string;
  current?: boolean;
};

const GithubIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      color="currentColor"
    >
      <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
    </svg>
  );
};

type AppContext = {
  history: [DeployInfo];
};

const fetchAppContext = async (currentDeploy: DeployInfo): Promise<DeployInfo[]> => {
  const response = await fetch(`${window.location.origin}/app-context.json`);
  const appContext = (await response.json()) as AppContext;
  const previousHistory = appContext.history.map((deploy) => ({
    ...deploy,
    current: deploy.commitSha === currentDeploy.commitSha,
  }));
  return previousHistory;
};

const DisplayDeploys = ({ visibleData }: { visibleData: DeployInfo[] }) => {
  return (
    <React.Fragment>
      {visibleData.map(({ timestamp, commitSha, message, current }, index) => (
        <React.Fragment key={commitSha}>
          <Grid.Cell css={{ ...cellCss, fontWeight: current ? '$bold' : '$normal' }}>
            <Link href={`/sneak-preview/${commitSha.substring(0, 7)}/`}>
              {commitSha.substr(0, 7)}
              {current && <Icon name="Sun" color="success" />}
            </Link>
          </Grid.Cell>
          <Grid.Cell css={{ ...cellCss, fontWeight: current ? '$bold' : '$normal' }}>
            <Text css={{ textAlign: 'right' }}>
              {formatDateWithTimezone(timestamp, DateFormat.SHORT_DATE_WITH_TIME, {
                locale: Locale.enUS,
                timezone: Timezone.EST,
              })}
            </Text>
          </Grid.Cell>
          <Grid.Cell
            colSpan={2}
            css={{
              px: '$space1',
              pb: '$space1',
              fontWeight: current ? '$bold' : '$normal',
            }}
          >
            <Box css={{ display: 'flex', mb: '$space1' }}>
              <Link
                href={`https://github.com/attentive-mobile/frontend-code/commit/${commitSha}`}
                css={{ '&:hover': { fill: '$textHover' } }}
              >
                <GithubIcon />
              </Link>
              <Text variant="caption" css={{ ml: '$space2' }}>
                {message}
              </Text>
            </Box>
            {index !== visibleData.length - 1 && <Separator />}
          </Grid.Cell>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export const RecentDeploys: React.FC = () => {
  const currentCommitDetails = useAtomValue(commitDetailsAtom);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [truncatedList, setTruncatedList] = React.useState(true);
  const currentDeploy = {
    ...currentCommitDetails,
    current: true,
  };

  const { data: deployHistory = [] } = useQuery({
    queryKey: ['buildHistory'],
    queryFn: () => fetchAppContext(currentDeploy),
  });

  const filteredDeploys = deployHistory.filter(({ commitSha, timestamp, message }) => {
    return (
      commitSha.includes(searchTerm) ||
      timestamp.includes(searchTerm) ||
      message.includes(searchTerm)
    );
  });

  const visibleData = truncatedList ? filteredDeploys.slice(0, MINIMUM_VISIBLE) : filteredDeploys;
  const isFiltered = filteredDeploys.length !== deployHistory.length;
  const maxVisibleCount = visibleData.length >= deployHistory.length;
  const resultsText = `Showing ${visibleData.length} of ${filteredDeploys.length}`;

  return (
    <Box css={{ width: '100%' }}>
      <Heading as="h2" variant="md">
        Recent Deploys
      </Heading>
      <Grid columns={2} css={{ rowGap: '$space1', width: '100%', mb: '$space4' }}>
        <Grid.Cell>
          <InputGroup>
            <InputGroup.LeftElement>
              <Icon size="small" name="MagnifyingGlass" />
            </InputGroup.LeftElement>
            <TextInput
              size="small"
              placeholder="Search"
              name="MagnifyingGlass"
              aria-label="Search"
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
            />
            {searchTerm.length && (
              <InputGroup.RightElement>
                <IconButton
                  size="small"
                  iconName="X"
                  description="Clear the search bar"
                  onClick={() => setSearchTerm('')}
                />
              </InputGroup.RightElement>
            )}
          </InputGroup>
        </Grid.Cell>
        <Grid.Cell css={{ display: 'grid', alignContent: 'center', justifyContent: 'right' }}>
          <Text>{resultsText}</Text>
        </Grid.Cell>
        <DisplayDeploys visibleData={visibleData} />
        {!isFiltered && !maxVisibleCount && (
          <Grid.Cell colSpan={2} css={{ display: 'grid', placeItems: 'center' }}>
            <Button size="small" variant="secondary" onClick={() => setTruncatedList(false)}>
              View more
            </Button>
          </Grid.Cell>
        )}
      </Grid>
    </Box>
  );
};
