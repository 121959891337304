import { createEntryPoint } from '@attentive/data-router';

import Query from './__generated__/EmailSettingsPageQueriesSettingsUIQuery.graphql';

export const EmailSettingsPageEntryPoint = createEntryPoint({
  component: () => import('./EmailSettingsPage'),
  getQueries: ({ getCurrentCompanyId }) => ({
    query: {
      parameters: Query,
      variables: { id: getCurrentCompanyId() },
    },
  }),
});
