import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const FidoChallengeChecker = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.has('challenge')) {
      const redir = searchParams.get('redir') ?? location.pathname;
      searchParams.set('redir', redir);
      navigate(`/challenge?${searchParams}`);
    }
  }, [navigate, searchParams]);

  return null;
};
