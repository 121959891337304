import React from 'react';

import { Tracker } from '@attentive/acore-utils';
import { AppStatus } from '@attentive/data';

import { useAuthClient } from '@attentive/auth-core';
import AppLoading from '../../pages/AppLoading';
import { abortAppTeardownTransaction } from '../../store/store-mutations';
import { useAppTeardownReasonMessage, useAppStatus } from '../../store/site-data';

const AppTeardown: React.FC = () => {
  const appStatus = useAppStatus();
  const teardownReason = useAppTeardownReasonMessage();
  const authClient = useAuthClient();

  React.useEffect(() => {
    const logout = () => {
      try {
        Tracker.reset('client-ui');

        authClient.logout(teardownReason);
      } catch (err) {
        abortAppTeardownTransaction(`Error logging out. ${err}`);
      }
    };

    logout();
  });

  if (appStatus === AppStatus.ERROR) {
    return <div>Error logging out</div>;
  }

  return <AppLoading />;
};

export { AppTeardown };
