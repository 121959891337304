/**
 * @generated SignedSource<<f05240cd93d3292290cfda22b1dac800>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NotificationsMenuRowClientUIDismissNotificationMutation$variables = {
  notificationId: string;
};
export type NotificationsMenuRowClientUIDismissNotificationMutation$data = {
  readonly dismissNotification: {
    readonly notification: {
      readonly id: string;
    };
  } | null;
};
export type NotificationsMenuRowClientUIDismissNotificationMutation$rawResponse = {
  readonly dismissNotification: {
    readonly notification: {
      readonly id: string;
    };
  } | null;
};
export type NotificationsMenuRowClientUIDismissNotificationMutation = {
  rawResponse: NotificationsMenuRowClientUIDismissNotificationMutation$rawResponse;
  response: NotificationsMenuRowClientUIDismissNotificationMutation$data;
  variables: NotificationsMenuRowClientUIDismissNotificationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "notificationId"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "notificationId",
        "variableName": "notificationId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationsMenuRowClientUIDismissNotificationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DismissNotificationPayload",
        "kind": "LinkedField",
        "name": "dismissNotification",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Notification",
            "kind": "LinkedField",
            "name": "notification",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationsMenuRowClientUIDismissNotificationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DismissNotificationPayload",
        "kind": "LinkedField",
        "name": "dismissNotification",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Notification",
            "kind": "LinkedField",
            "name": "notification",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteRecord",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fce301f0e0c07c1d32a442b1833f7cbb",
    "id": null,
    "metadata": {},
    "name": "NotificationsMenuRowClientUIDismissNotificationMutation",
    "operationKind": "mutation",
    "text": "mutation NotificationsMenuRowClientUIDismissNotificationMutation(\n  $notificationId: ID!\n) {\n  dismissNotification(input: {notificationId: $notificationId}) {\n    notification {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3918278cfd3cbd7b6b7b78d91cb044c3";

export default node;
