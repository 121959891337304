import React from 'react';

import { Box } from '@attentive/picnic';

import { AdminSidebarNav } from './AdminSidebarNav';
import { SidebarRegion } from '../../client-layout/sidebar/SidebarRegion';
import { LogoEasterEgg } from '../../../common/LogoEasterEgg';

export const AdminSidebar = () => {
  return (
    <SidebarRegion>
      <AdminSidebarNav />
      <Box css={{ flex: 1 }} />
      <Box css={{ padding: '$space8' }}>
        <LogoEasterEgg />
      </Box>
    </SidebarRegion>
  );
};
