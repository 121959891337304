import { atom } from 'jotai';

export enum MockLoginGateStrategy {
  None = 'none',
  Password = 'password',
}

export const mockLoginGateStrategyAtom = atom<MockLoginGateStrategy>(
  MockLoginGateStrategy.Password
);
