import React, { useEffect } from 'react';
import { Outlet, useMatches } from 'react-router-dom';
import {
  LazyMfeKeys,
  ErrorBoundary,
  logDatadogError,
  ProjectNameProvider,
  addDatadogContext,
  useCompanyFeatureFlag,
  AppStore,
  getMfeNameProps,
} from '@attentive/acore-utils';

import { SomethingWentWrong } from './SomethingWentWrong';
import { setDocumentTitle } from '../utils';
import { useEmergencyMode } from '../store/env-vars';
import { EmergencyNotice } from './EmergencyNotice';
import { fetchDegradationWarning } from '../utils/api';
import { logError } from '../utils/loggerInstance';
import { degradationWarningAtom } from '../store/site-data';

const isFailedToFetchError = (error?: Error) =>
  error?.message?.includes('Failed to fetch dynamically imported module');

// TODO(jaden): move into @attentive/router package
function useRouteStaticData<T>() {
  const match = useMatches().find((m) => !!m.handle);

  return match?.handle as T;
}

export const MFELayoutRoute = () => {
  const inEmergencyMode = useEmergencyMode();
  const [showRefreshPagePrompt, setShowRefreshPagePrompt] = React.useState(false);
  const shouldFetchDegradationWarning = useCompanyFeatureFlag(
    'SHOW_SLOW_LOAD_TIMES_DIALOG_CLIENT_UI'
  );
  const { appName, title, availableInAnEmergency } =
    useRouteStaticData<{
      appName: LazyMfeKeys;
      title: string;
      availableInAnEmergency?: boolean;
    }>() || {};

  useEffect(() => {
    const fetchWarning = async () => {
      try {
        const warning = await fetchDegradationWarning();
        AppStore.store.set(degradationWarningAtom, warning);
      } catch (err) {
        logError(err);
        AppStore.store.set(
          degradationWarningAtom,
          'We are currently experiencing service issues and are working on a solution.'
        );
      }
    };

    if (shouldFetchDegradationWarning) {
      fetchWarning();
    }
  }, [shouldFetchDegradationWarning]);

  useEffect(() => {
    if (title && appName) {
      setDocumentTitle(title);
      // update datadog context to scope view events to MFEs
      // https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm#global-context
      addDatadogContext('projectName', appName);
    }
  }, [title, appName]);

  if (!availableInAnEmergency && inEmergencyMode) {
    return <EmergencyNotice appName={appName} title={title} />;
  }

  if (showRefreshPagePrompt) {
    return <SomethingWentWrong />;
  }

  return (
    <ProjectNameProvider value={appName}>
      <ErrorBoundary
        key={appName}
        onError={(...args) => {
          if (isFailedToFetchError(args[0])) {
            setShowRefreshPagePrompt(true);
            // @ts-ignore This is not a valid project type.
            logDatadogError('failedToFetch', ...args);
            return;
          }
          logDatadogError(appName, ...args);
        }}
      >
        <span {...getMfeNameProps(appName)} style={{ display: 'none', visibility: 'hidden' }} />
        <Outlet />
      </ErrorBoundary>
    </ProjectNameProvider>
  );
};
