import React from 'react';

import { useSelectedItemPaths } from './useSelectedItemPaths';

import { Box, IconName, BadgeVariant, Heading, Text, Popover } from '@attentive/picnic';

import { NewFeatureLabel } from '../NewFeatureLabel';
import { SidebarLink, SidebarButton } from './SidebarLink';
import { SidebarIconBadge } from './SidebarIconBadge';
import { SidebarNavAccordion } from './SidebarNavAccordion';
import { useNewFeatureAnnouncement } from './useNewFeatureAnnouncement';

export type SidebarNavOnClickHandler = (parameters?: { hasSubElements?: boolean }) => void;

interface SidebarNavItemProps {
  path: string;
  label: string | JSX.Element;
  iconName?: IconName;
  badgeContent?: string | null;
  badgeVariant?: BadgeVariant | null;
  badgeAriaLabel?: string;
  pill?: 'new';
  onClick?: SidebarNavOnClickHandler;
  trackingId?: string;

  // Announcement props
  featureAnnouncement?: {
    title: string;
    description: React.ReactNode;
    contentId: string;
  };
}

export const SidebarNavItem: React.FC<SidebarNavItemProps> = ({
  path,
  children,
  onClick,
  iconName,
  badgeContent,
  badgeVariant,
  badgeAriaLabel,
  label,
  pill,
  featureAnnouncement,
  trackingId,
}) => {
  const { selectedNavItemPath } = useSelectedItemPaths();
  const isSelected = path === selectedNavItemPath;

  const hasSubItems = !!children;
  const TriggerComponent = hasSubItems ? SidebarButton : SidebarLink;

  const ItemComponent = (
    <SidebarNavAccordion.Item value={path} disabled={!hasSubItems}>
      <SidebarNavAccordion.Header>
        {/* SidebarNavAccordion.Trigger doesn't like to be rendered as an <a>, but we still want to render it for the desired behavior --
        closing the accordion when navigating to any item whether or not it has sub-elements. */}
        {/* @ts-ignore */}
        <SidebarNavAccordion.Trigger asChild>
          <TriggerComponent
            path={path}
            variant={isSelected ? 'selected' : 'default'}
            css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            onClick={() => onClick && onClick({ hasSubElements: hasSubItems })}
            data-nav-id={trackingId}
          >
            <Box css={{ display: 'flex', alignItems: 'center' }}>
              {iconName && (
                <SidebarIconBadge
                  selected={isSelected}
                  iconName={iconName}
                  badgeContent={badgeContent}
                  badgeVariant={badgeVariant}
                  badgeAriaLabel={badgeAriaLabel}
                  size="small"
                />
              )}
              <Text
                css={{
                  display: 'block',
                  ml: iconName ? '$space2' : 0,
                  fontWeight: isSelected ? '$bold' : '$regular',
                }}
              >
                {label}
              </Text>
              {pill === 'new' && <NewFeatureLabel />}
            </Box>
            {hasSubItems && <SidebarNavAccordion.Icon />}
          </TriggerComponent>
        </SidebarNavAccordion.Trigger>
      </SidebarNavAccordion.Header>
      <SidebarNavAccordion.Content>{children}</SidebarNavAccordion.Content>
    </SidebarNavAccordion.Item>
  );

  const { isOpen, dismiss } = useNewFeatureAnnouncement(featureAnnouncement?.contentId || '');

  if (featureAnnouncement) {
    const { title, description } = featureAnnouncement;

    return (
      <Popover
        open={isOpen}
        onOpenChange={(isOpenValue) => {
          !isOpenValue && dismiss();
        }}
      >
        <Popover.Anchor>{ItemComponent}</Popover.Anchor>
        <Popover.Content side="right" align="start" css={{ maxWidth: '450px' }}>
          <Heading variant="sm" css={{ mb: '$space1' }}>
            {title}
          </Heading>
          {typeof description === 'string' ? <Text>{description}</Text> : description}
        </Popover.Content>
      </Popover>
    );
  }

  return ItemComponent;
};
