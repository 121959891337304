// Typings for Zendesk API.
interface ZendeskApiArticle {
  title: string;
  snippet: string;
  html_url: string;
  section_id: number;
}

interface ZendeskApiSection {
  id: number;
  category_id: number;
  name: string;
}

interface ZendeskApiCategory {
  id: number;
  name: string;
}
interface ZendeskApiData {
  count: number;
  nextPage?: string;
  previousPage?: string;
  page: number;
  page_count: number;
  per_page: number;
  results: ZendeskApiArticle[];
  categories?: ZendeskApiCategory[];
  sections?: ZendeskApiSection[];
}

interface ZendeskApiError {
  error: string;
}

export type ZendeskApiResult = ZendeskApiData | ZendeskApiError;

// Typings for exported API.

export interface ZendeskSearchParams {
  query: string;
  offset?: number;
  itemsPerPage?: number;
  includeCategoryMetadata?: boolean;
}
export interface PageInfo {
  itemsPerPage: number;
  offset: number;
  totalItems: number;
  pageCount: number;
}

export interface HelpCenterArticle {
  title: string;
  snippet: string;
  htmlUrl: string;
  // Nest sideloaded records in a single object for easier consumption.
  category?: ZendeskApiCategory;
  section?: ZendeskApiSection;
}

export enum ZendeskSearchUrlType {
  API,
  UI,
}

export interface ZendeskSearchData {
  pageInfo: PageInfo;
  articles: HelpCenterArticle[];
}

const ZENDESK_UI_URL = 'https://help.attentivemobile.com/hc/en-us/search';
export const ZENDESK_API_URL =
  'https://help.attentivemobile.com/api/v2/help_center/articles/search.json';

const embedSectionAndCategoryData = (data?: ZendeskApiData): HelpCenterArticle[] => {
  if (!data || data.results.length === 0) {
    return [];
  }

  const sectionsById =
    data.sections?.reduce((acc, section) => {
      acc[section.id] = section;
      return acc;
    }, {} as Record<number, ZendeskApiSection>) || {};

  const categoriesById =
    data.categories?.reduce((acc, category) => {
      acc[category.id] = category;
      return acc;
    }, {} as Record<number, ZendeskApiCategory>) || {};

  // eslint-disable-next-line @typescript-eslint/naming-convention
  return data.results.map(({ title, snippet, html_url, section_id }) => {
    const section = sectionsById[section_id];
    const category = categoriesById[section?.category_id];

    return {
      title,
      snippet,
      htmlUrl: html_url,
      section,
      category,
    };
  });
};

const processArticles = (articles: HelpCenterArticle[]) => {
  return articles.map((article) => {
    if (!article.snippet) {
      return article;
    }

    return {
      ...article,
      snippet: article.snippet
        // Results are sometimes only whitespace, trim so the snippet becomes falsy in this case.
        .trim()
        // Snippet includes emphasis tags, we want to render as plain text so strip them out.
        .replace(/<\/?em>/g, ''),
    };
  });
};

const transformZendeskApiResult = (data: ZendeskApiData): ZendeskSearchData => {
  let articles = embedSectionAndCategoryData(data);
  articles = processArticles(articles);

  return {
    articles,
    pageInfo: {
      itemsPerPage: data.per_page,
      offset: data.page - 1,
      totalItems: data.count,
      pageCount: data.page_count,
    },
  };
};

export const buildZendeskSearchUrl = (
  type: ZendeskSearchUrlType,
  { query, offset, itemsPerPage, includeCategoryMetadata }: ZendeskSearchParams
) => {
  const searchParams = new URLSearchParams({
    query,
    ...(offset && { page: `${offset + 1}` }),
    ...(itemsPerPage && { per_page: `${itemsPerPage}` }),
    ...(includeCategoryMetadata &&
      // Sideloading other models is only relevant if we're querying the API.
      type === ZendeskSearchUrlType.API && { include: 'sections,categories' }),
  });

  const baseUrl = type === ZendeskSearchUrlType.API ? ZENDESK_API_URL : ZENDESK_UI_URL;

  return `${baseUrl}?${searchParams}`;
};

export const queryZendeskHelpCenter = async (
  params: ZendeskSearchParams
): Promise<ZendeskSearchData> => {
  const queryUrl = buildZendeskSearchUrl(ZendeskSearchUrlType.API, params);

  try {
    const resp = await fetch(queryUrl);
    const data: ZendeskApiResult = await resp.json();

    if ('error' in data) {
      throw data.error;
    }

    return transformZendeskApiResult(data);
  } catch (e) {
    throw new Error(`Zendesk Search API error: ${e}`);
  }
};
