import { Routes } from '../../../../../routes';
import { HelpCenterRoutesConfig, HelpCenterPageConfig } from './types';

const JOURNEYS_ROUTE_CONTENT: HelpCenterPageConfig = {
  sections: [
    {
      label: 'Visit the Help Center',
      links: [
        {
          label: 'What are journeys?',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/360051937831-What-are-journeys-',
        },
        {
          label: 'How to create a journey',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/360051937871-Create-a-journey',
        },
        {
          label: 'Best practices: Creating effective welcome messages',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/360051938231-Best-practices-Creating-effective-welcome-messages',
        },
        {
          label: 'Best practices: Triggered messages',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/360059611271-Ultimate-Guide-Triggered-Messages-best-practices',
        },
      ],
    },
    {
      label: 'Watch a video',
      links: [
        {
          label: 'How-to video: Transactional Journeys',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/360056857672-What-are-transactional-journeys-#01FS5ZBZ8BYHP67JXDZD09G7F9',
        },
      ],
    },
  ],
};

const OFFERS_ROUTE_CONTENT: HelpCenterPageConfig = {
  sections: [
    {
      label: 'Visit the Help Center',
      links: [
        {
          label: 'Working with offers quick reference guide',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/6425908442132-Get-started-with-offers',
        },
        {
          label: 'How to add an offer to a welcome journey',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/360051976551-Add-an-offer-to-a-welcome-journey',
        },
        {
          label: 'The Ultimate Guide to Personalized Mobile Messaging',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/360042001231-Ultimate-Guide-to-Personalized-Mobile-Messaging',
        },
        {
          label: 'Use case: How to use offers in journeys',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/360059641412-Use-cases-Offers-in-journeys',
        },
      ],
    },
    {
      label: 'Watch a video',
      links: [
        {
          label: 'How-to video: Create offers ',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/360051338532-Create-offers',
        },
        {
          label: 'How-to video: Auto-generated discount codes',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/6357184461844-Automatically-replenish-Shopify-discount-codes',
        },
      ],
    },
  ],
};

const SUBSCRIBERS_ROUTE_CONTENT: HelpCenterPageConfig = {
  sections: [
    {
      label: 'Visit the Help Center',
      links: [
        {
          label: 'View subscriber profiles',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/4412398776724-View-subscriber-profiles',
        },
        {
          label: 'Create a landing page',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/4406302222868-Create-a-landing-page',
        },
      ],
    },
  ],
};

const SIGN_UP_UNITS_ROUTE_CONTENT: HelpCenterPageConfig = {
  sections: [
    {
      label: 'Visit the Help Center',
      links: [
        {
          label: 'What are sign-up units',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/360051443552-What-are-sign-up-units-',
        },
        {
          label: 'Design a sign-up unit',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/360054545811-Design-a-sign-up-unit',
        },
        {
          label: 'Add a spin-to-win sign-up unit',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/21547345915284-Add-spin-to-win-to-a-sign-up-unit',
        },
        {
          label: 'Best Practices: Sign-up units ',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/360059184912-Ultimate-Guide-Sign-up-units-best-practices',
        },
      ],
    },
    {
      label: 'Watch a video',
      links: [
        {
          label: 'How-to video: Create a sign-up unit',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/360051929951-Create-a-new-sign-up-unit',
        },
      ],
    },
  ],
};

const HOME_ROUTE_CONTENT: HelpCenterPageConfig = {
  sections: [
    {
      label: 'Visit the Help Center',
      links: [
        {
          label: 'Understand your personalized Home page',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/4409156487060--Beta-Personalized-Home',
        },
        {
          label: 'Looking for inspiration?',
          url: 'https://help.attentivemobile.com/hc/en-us/categories/360004565352-Best-Practices-FAQs',
        },
        {
          label: 'Ultimate guide to personalized mobile messaging',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/360042001231-Ultimate-Guide-to-Personalized-Mobile-Messaging',
        },
        {
          label: 'Analytics and reporting',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/6424431983764-Get-started-with-analytics-and-reporting',
        },
      ],
    },
    {
      label: 'Watch a video',
      links: [
        {
          label: 'Video: Attentive Platform Tour',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/360051737612',
        },
      ],
    },
  ],
};

const CAMPAIGNS_ROUTE_CONTENT: HelpCenterPageConfig = {
  sections: [
    {
      label: 'Visit the Help Center',
      links: [
        {
          label: 'How to design a campaign text message',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/13573418673940-Design-a-campaign-text-message-with-Magic-Composer',
        },
        {
          label: 'How to add images, video, and contact cards to campaigns',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/360060056711-Add-images-video-or-a-contact-card-to-a-text-message',
        },
        {
          label: 'How do I use AI in Attentive?',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/14826254934804-Attentive-AI-overview',
        },
        {
          label: 'Best practices: Testing your campaigns',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/6734786961300-Best-practices-Testing-your-campaigns',
        },
        {
          label: 'Best practices: Copywriting',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/10012619727380-Ultimate-Guide-Campaign-copywriting-best-practices',
        },
      ],
    },
  ],
};

const SEGMENTS_ROUTE_CONTENT: HelpCenterPageConfig = {
  sections: [
    {
      label: 'Visit the Help Center',
      links: [
        {
          label: 'What is Segmentation?',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/360051463172',
        },
        {
          label: 'How to use a segmentation template',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/4408792458132-Create-a-segment-using-a-template',
        },
        {
          label: 'Best practices: Build high ROI segments ',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/360055707652-Segmentation-High-ROI-segments',
        },
        {
          label: 'Best practices: Segment ideas',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/360049349552-Best-practices-Segment-ideas',
        },
      ],
    },
    {
      label: 'Watch a video',
      links: [
        {
          label: 'How-to video: Create segments',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/360051463172#how-to-build-a-segment-0-0',
        },
      ],
    },
  ],
};

const CONVERSATIONS_ROUTE_CONTENT: HelpCenterPageConfig = {
  sections: [
    {
      label: 'Visit the Help Center',
      links: [
        {
          label: 'Conversations tab tour',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/6424104815508-Get-started-managing-current-subscribers#conversations-with-your-subscribers-0-1',
        },
        {
          label: 'Best practice: Using Attentive Concierge ',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/4413000722964-Attentive-Concierge-One-Sheet',
        },
      ],
    },
  ],
};

const INTEGRATIONS_ROUTE_CONTENT: HelpCenterPageConfig = {
  sections: [
    {
      label: 'Visit the Help Center',
      links: [
        {
          label: 'What are integrations',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/360041569412-What-are-integrations-',
        },
        {
          label: 'Create and manage custom apps',
          url: 'https://docs.attentive.com/pages/create-and-manage-custom-apps/',
        },
      ],
    },
  ],
};

const ANALYTICS_DASHBOARD_ROUTE_CONTENT: HelpCenterPageConfig = {
  sections: [
    {
      label: 'Visit the Help Center',
      links: [
        {
          label: 'Use analytics to understand messaging performance',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/4415696076692-Use-analytics-to-understand-messaging-performance',
        },
        {
          label: 'Reporting metrics glossary',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/17876570005140-Reporting-metrics-glossary',
        },
      ],
    },
  ],
};

const ANALYTICS_GENERATED_ROUTE_CONTENT: HelpCenterPageConfig = {
  sections: [
    {
      label: 'Visit the Help Center',
      links: [
        {
          label: 'How to use generated reports',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/360055353571-Generate-a-report',
        },
        {
          label: 'How to schedule a generated report',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/360055353991-Schedule-generated-reports',
        },
      ],
    },
  ],
};

const ANALYTICS_REPORTS_ROUTE_CONTENT: HelpCenterPageConfig = {
  sections: [
    {
      label: 'Visit the Help Center',
      links: [
        {
          label: 'How to use reports',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/6023986879380-Reports',
        },
        {
          label: 'What reports are available?',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/6023986879380-Reports',
        },
        {
          label: 'How to create and save a custom report',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/14553114929556-Create-custom-reports',
        },
        {
          label: 'How to schedule a report',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/14553114929556-Create-custom-reports#h_01HA53QT2N1J0DCSX98EDZVAM5',
        },
        {
          label: 'Reporting metrics glossary',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/17876570005140-Reporting-metrics-glossary',
        },
      ],
    },
  ],
};

const SETTINGS_ROUTE_CONTENT: HelpCenterPageConfig = {
  sections: [
    {
      label: 'Visit the Help Center',
      links: [
        {
          label: 'How to use Smart send, quiet hours, and other settings',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/6426021180180-Get-started-with-settings',
        },
        {
          label: 'When is the best time to send a message?',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/360041543892-Best-practices-When-you-should-send-messages',
        },
        {
          label: 'Best practices: Sending campaign messages',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/6109877404180-Ultimate-Guide-Campaign-message-sending-best-practices',
        },
      ],
    },
  ],
};

const BRAND_KIT_ROUTE_CONTENT: HelpCenterPageConfig = {
  sections: [
    {
      label: 'Visit the Help Center',
      links: [
        {
          label: 'Set up your brand kit',
          url: 'https://help.attentivemobile.com/hc/en-us/articles/11301953843732-Set-up-your-Brand-Kit',
        },
      ],
    },
  ],
};

const DEFAULT_ROUTE_CONTENT: HelpCenterPageConfig = {
  sections: [
    {
      label: '',
      links: [{ label: 'Help Center', url: 'https://help.attentivemobile.com/hc/' }],
    },
  ],
};

/**
 * Content will be rendered in the HelpCenterMenu for the current route.
 */
export const HELP_CENTER_ROUTES: HelpCenterRoutesConfig = [
  { route: { path: `${Routes.Journeys}/*` }, content: JOURNEYS_ROUTE_CONTENT },
  { route: { path: `${Routes.Offers}/*` }, content: OFFERS_ROUTE_CONTENT },
  { route: { path: `${Routes.SignUpUnits}/*` }, content: SIGN_UP_UNITS_ROUTE_CONTENT },
  { route: { path: `${Routes.Subscribers}/*` }, content: SUBSCRIBERS_ROUTE_CONTENT },
  { route: { path: `${Routes.Campaigns}/*` }, content: CAMPAIGNS_ROUTE_CONTENT },
  { route: { path: `${Routes.Segments}/*` }, content: SEGMENTS_ROUTE_CONTENT },
  { route: { path: `${Routes.Conversations}/*` }, content: CONVERSATIONS_ROUTE_CONTENT },
  { route: { path: `${Routes.Integrations}/*` }, content: INTEGRATIONS_ROUTE_CONTENT },
  {
    route: { path: `${Routes.Analytics}/reports/generated*` },
    content: ANALYTICS_GENERATED_ROUTE_CONTENT,
  },
  { route: { path: `${Routes.Analytics}/reports*` }, content: ANALYTICS_REPORTS_ROUTE_CONTENT },
  { route: { path: `${Routes.Analytics}/dashboard*` }, content: ANALYTICS_DASHBOARD_ROUTE_CONTENT },
  { route: { path: `${Routes.Analytics}/*` }, content: ANALYTICS_DASHBOARD_ROUTE_CONTENT },
  { route: { path: `${Routes.Settings}/*` }, content: SETTINGS_ROUTE_CONTENT },
  { route: { path: `${Routes.Home}/*` }, content: HOME_ROUTE_CONTENT },
  { route: { path: `${Routes.BrandKit}/*` }, content: BRAND_KIT_ROUTE_CONTENT },
  // This empty route will match anything not caught by the route configs above.
  { route: { path: '*' }, content: DEFAULT_ROUTE_CONTENT },
];
