import React from 'react';

import { useCompanyFeatureFlag } from '@attentive/acore-utils';

import { SystemStatusDialog } from './SystemStatusDialog';

const SYSTEM_STATUS_ALERT = 'system-status-alert';
const ONE_HOUR_DURATION_MS = 60 * 60 * 1000;

export const AppAnnouncementsContainer: React.FC = () => {
  const isSystemStatusDialogEnabled = useCompanyFeatureFlag('SHOW_SYSTEM_STATUS_MESSAGE');
  const shouldShowSystemStatusAlert =
    Number.parseInt(sessionStorage.getItem(SYSTEM_STATUS_ALERT) || '0') < Date.now();

  const markSystemStatusAlertViewed = () => {
    sessionStorage.setItem(SYSTEM_STATUS_ALERT, `${Date.now() + ONE_HOUR_DURATION_MS}`);
  };

  if (isSystemStatusDialogEnabled) {
    return (
      <SystemStatusDialog
        defaultOpen={shouldShowSystemStatusAlert}
        onClose={markSystemStatusAlertViewed}
      />
    );
  }

  return null;
};
