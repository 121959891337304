import React from 'react';
import { useMatch, Link as RouterLink } from 'react-router-dom';

import { DropdownMenu } from '@attentive/picnic';
import { AdminRoutes } from '../../../../../routes';

export const AppNavigationItems = () => {
  const isOnAdminCenter = useMatch({ path: `${AdminRoutes.AdminCenter}/*` });

  return (
    <>
      <DropdownMenu.TextItem asChild>
        <RouterLink to="/">Client UI</RouterLink>
      </DropdownMenu.TextItem>
      {!isOnAdminCenter && (
        <DropdownMenu.TextItem asChild>
          <RouterLink to={AdminRoutes.AdminCenter}>Admin Center</RouterLink>
        </DropdownMenu.TextItem>
      )}
    </>
  );
};
