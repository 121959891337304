import React from 'react';
import { Navigate } from 'react-router-dom';
import {
  resetPassword,
  sendOnboardingWelcomeEmail,
  useLoginWithPassword,
  verifyCreatePasswordToken,
  InvalidPasswordError,
} from '@attentive/auth-core';
import {
  Banner,
  Button,
  Heading,
  InputGroup,
  Text,
  LoadingIndicator,
  FormField,
  Box,
  TextInput,
  Wordmark,
  List,
} from '@attentive/picnic';
import { setDocumentTitle } from '../utils';
import { AUTH_PAGE_WIDTH } from '../constants';
import { CreatePasswordLayout } from '../components/layouts/create-password-layout/CreatePasswordLayout';
import startTrial from '../assets/start-trial.png';
import { Tracker, TrackerEvents } from '@attentive/acore-utils';

enum UiStatus {
  INITIAL,
  CREATE_PASSWORD,
  VALIDATING_TOKEN,
  NEW_TOKEN_SENT,
}

export const CreatePassword: React.FC = () => {
  const [status, setStatus] = React.useState<UiStatus>(UiStatus.INITIAL);
  const [redirect, setRedirect] = React.useState<string | null>(null);
  const [focus, setFocus] = React.useState<'password' | 'confirmPassword'>('password');
  const [password, setPassword] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [isWorking, setIsWorking] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [errorMessageDetails, setErrorMessageDetails] = React.useState<string[] | null>(null);
  const loginWithPassword = useLoginWithPassword('/2fa');
  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  const ceid = params.get('ceid');
  const statusIs = React.useCallback((s: UiStatus) => s === status, [status]);

  const clearError = () => {
    setErrorMessage(null);
    setErrorMessageDetails(null);
  };

  const setError = (message: string, details: string[] | null = null) => {
    setErrorMessage(message);
    setErrorMessageDetails(details);
  };

  const handleSendNewToken = React.useCallback(() => {
    if (!email || !ceid) return;
    setIsWorking(true);
    sendOnboardingWelcomeEmail(email, ceid)
      .then(() => {
        setIsWorking(false);
        setStatus(UiStatus.NEW_TOKEN_SENT);
      })
      .catch((err) => {
        setIsWorking(false);
        setError(err.message);
      });
  }, [email, ceid]);

  // validate token
  React.useEffect(() => {
    if (!queryParamIsString(token)) {
      setRedirect('/signin');
      return;
    }
    setDocumentTitle('Start your trial');
    verifyCreatePasswordToken(token as string)
      .then((res) => {
        if (res.redirectToSignin) {
          window.location.assign(`/?companyId=${res.companyId}`);
        } else if (res.hasExpiredToken) {
          setStatus(UiStatus.NEW_TOKEN_SENT);
          setEmail(res.userEmail);
          handleSendNewToken();
          Tracker.track({ eventName: TrackerEvents.ONBOARDING_VIEWED_TOKEN_EXPIRED });
        } else {
          setEmail(res.userEmail);
          setStatus(UiStatus.CREATE_PASSWORD);
          Tracker.track({ eventName: TrackerEvents.ONBOARDING_VIEWED_CREATE_PASSWORD });
        }
      })
      .catch(() => {
        setRedirect('/signin');
      });
  }, [token, handleSendNewToken]);

  // clear form errors
  React.useEffect(() => {
    if (statusIs(UiStatus.CREATE_PASSWORD)) {
      if (password === confirmPassword && password.length > 8) clearError();
    }
  }, [password, confirmPassword, status, statusIs, email]);

  const handleCreatePassword = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (password !== confirmPassword) {
        setError('Your passwords do not match.');
        return;
      }

      setIsWorking(true);
      setShowPassword(false);
      clearError();

      resetPassword(password, `${token}`)
        .then(() => loginWithPassword(email, password, '/onboarding'))
        .catch((err: Error) => {
          if (err instanceof InvalidPasswordError) {
            setError(err.message, err.messageDetails);
          } else {
            setError(err.message || 'Unable to create password.');
          }
        })
        .finally(() => {
          setIsWorking(false);
        });
      return;
    },
    [password, confirmPassword, token, email, loginWithPassword]
  );

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return (
    <CreatePasswordLayout
      leftPane={
        <LeftPane>
          {statusIs(UiStatus.NEW_TOKEN_SENT) && (
            <>
              <Heading variant="page" css={{ marginBottom: '$space6' }}>
                Create a password
              </Heading>
              <Banner variant="error" css={{ mb: '$space6' }}>
                <Banner.Text>
                  Whoops! Your access token has expired. Check your inbox, we just sent a new email
                  with an updated link.
                </Banner.Text>
              </Banner>
            </>
          )}

          {statusIs(UiStatus.CREATE_PASSWORD) && (
            <form onSubmit={handleCreatePassword}>
              <Heading variant="page" css={{ marginBottom: '$space6' }}>
                Create a password
              </Heading>
              <Text css={{ mb: '$space4' }}>
                To get started with your free trial, please create a password.
              </Text>
              {isWorking && !email ? (
                <LoadingIndicator />
              ) : (
                <>
                  <FormField css={{ mb: '$space4' }}>
                    <FormField.Label htmlFor="new-password" />
                    <InputGroup>
                      <TextInput
                        autoFocus
                        id="new-password"
                        placeholder="Password"
                        onFocus={() => setFocus('password')}
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                      />
                      {focus === 'password' && (
                        <InputGroup.RightElement>
                          <InputGroup.InlineButton
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? 'Hide' : 'Show'}
                          </InputGroup.InlineButton>
                        </InputGroup.RightElement>
                      )}
                    </InputGroup>
                  </FormField>

                  <FormField css={{ mb: '$space4' }}>
                    <FormField.Label htmlFor="confirm-new-password" />
                    <InputGroup>
                      <TextInput
                        onFocus={() => setFocus('confirmPassword')}
                        id="confirm-new-password"
                        placeholder="Re-enter password"
                        type={showPassword ? 'text' : 'password'}
                        value={confirmPassword}
                        onChange={(event) => setConfirmPassword(event.target.value)}
                      />
                      {focus === 'confirmPassword' && (
                        <InputGroup.RightElement>
                          <InputGroup.InlineButton
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? 'Hide' : 'Show'}
                          </InputGroup.InlineButton>
                        </InputGroup.RightElement>
                      )}
                    </InputGroup>
                    {Boolean(errorMessage) && (
                      <FormField.ErrorText>
                        {errorMessage}
                        {errorMessageDetails && (
                          <List>
                            {errorMessageDetails.map((message) => (
                              <List.Item key={message}>{message}</List.Item>
                            ))}
                          </List>
                        )}
                      </FormField.ErrorText>
                    )}
                  </FormField>

                  <Button
                    type="submit"
                    css={{
                      width: '100%',
                      mb: '$space8',
                    }}
                    loading={isWorking}
                  >
                    Next
                  </Button>
                </>
              )}
            </form>
          )}
        </LeftPane>
      }
      rightPane={
        <Box
          css={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            overflow: 'hidden',
          }}
        >
          <Box as="img" src={startTrial} css={{ maxWidth: '805px' }} />
        </Box>
      }
    />
  );
};

function queryParamIsString(val: unknown): val is string {
  return typeof val === 'string' && val.length > 0;
}

function LeftPane({ children }: { children: React.ReactNode }) {
  return (
    <Box css={{ width: '100%', maxWidth: AUTH_PAGE_WIDTH }}>
      <Wordmark
        title="Attentive reset password page"
        width="140px"
        css={{ mb: '$space12', display: 'block' }}
      />
      {children}
    </Box>
  );
}
