import React from 'react';
import { useAtom, useAtomValue } from 'jotai';

import {
  Button,
  StandardDialog,
  Box,
  Heading,
  Grid,
  Wordmark,
  Stack,
  TabGroup,
} from '@attentive/picnic';
import { Config, usePermission, inSneakPreviewAtom } from '@attentive/acore-utils';
import { Permission } from '@attentive/data';

import {
  developerBannerVisibilityAtom,
  persistDeveloperBannerState,
  canShowDeveloperBanner,
} from '../DeveloperBanner';
import { PerformanceTable } from './PerformanceTable';
import { BuildInfoTable } from './BuildInfoTable';
import { escapeFromSneakPreview } from '@attentive/sneak-preview-utils/sneak-preview';

import { ThemeSwitcher } from '../ThemeSwitcher/ThemeSwitcher';
import { SessionVariablesTable } from './SessionVariablesTable';
import { RecentDeploys } from './RecentDeploys';
import { FeatureFlagsTable } from './FeatureFlagsTable';
import { ApiTools } from './ApiTools';
import { Phony } from './Phony';

interface VersionDialogProps {
  timings: PerformanceEntryList;
  open: boolean;
  onOpenChange: (visible: boolean) => void;
}

export const VersionDialog: React.FC<VersionDialogProps> = ({ timings, open, onOpenChange }) => {
  const developerBannerDisplayMode = Config.get('developerBannerDisplayMode');
  const [developerBannerVisibility, updateDeveloperBannerVisibility] = useAtom(
    developerBannerVisibilityAtom
  );
  const versionDialogAccess = usePermission(Permission.VersionDialogAccess);

  const inSneakPreview = useAtomValue(inSneakPreviewAtom);

  const handleShowDeveloperBanner = () => {
    persistDeveloperBannerState('visible');
    updateDeveloperBannerVisibility('visible');
    onOpenChange(true);
  };

  const showDeveloperBannerCta =
    canShowDeveloperBanner(versionDialogAccess, window.location, developerBannerDisplayMode) &&
    developerBannerVisibility === 'hidden';
  const showDevTools = showDeveloperBannerCta || inSneakPreview;

  return (
    <StandardDialog open={open} onOpenChange={onOpenChange}>
      <StandardDialog.Content css={{ minWidth: 'min(85vw, 400px)', width: '100%', maxWidth: 600 }}>
        <StandardDialog.Header>
          <Box as="span" css={{ display: 'inline-block', alignSelf: 'center' }}>
            <Wordmark title="Attentive" width="100px" color="$iconDecorative2" />
          </Box>
        </StandardDialog.Header>
        <StandardDialog.Body>
          <TabGroup defaultValue="build-info">
            <TabGroup.List>
              <TabGroup.Tab value="build-info">Build Info</TabGroup.Tab>
              <TabGroup.Tab value="feature-flags">Feature Flags</TabGroup.Tab>
              <TabGroup.Tab value="api-tools">API Tools</TabGroup.Tab>
              <TabGroup.Tab value="phony">Phony</TabGroup.Tab>
            </TabGroup.List>
            <TabGroup.Panel value="build-info">
              <Stack spacing="$space4">
                <BuildInfoTable />

                <PerformanceTable timings={timings} />

                <SessionVariablesTable />

                {versionDialogAccess && <RecentDeploys />}

                {showDevTools && (
                  <Box css={{ width: '100%' }}>
                    <Heading as="h2" variant="sm" css={{ mb: '$space1' }}>
                      Dev Tools
                    </Heading>
                    <Grid columns={[1, 2]} css={{ gap: '$space1' }}>
                      {showDeveloperBannerCta && (
                        <Button size="small" onClick={handleShowDeveloperBanner}>
                          Show developer banner
                        </Button>
                      )}
                      {inSneakPreview && (
                        <Button size="small" onClick={() => escapeFromSneakPreview(location)}>
                          Exit sneak preview
                        </Button>
                      )}
                    </Grid>
                  </Box>
                )}
                {versionDialogAccess && <ThemeSwitcher />}
              </Stack>
            </TabGroup.Panel>
            <TabGroup.Panel value="feature-flags">
              <FeatureFlagsTable />
            </TabGroup.Panel>
            <TabGroup.Panel value="api-tools">
              <ApiTools />
            </TabGroup.Panel>
            <TabGroup.Panel value="phony">
              <Phony />
            </TabGroup.Panel>
          </TabGroup>
        </StandardDialog.Body>
      </StandardDialog.Content>
    </StandardDialog>
  );
};
