import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as queryString from 'query-string';

import {
  Button,
  Heading,
  Link,
  Text,
  Banner,
  FormField,
  TextInput,
  Box,
  Wordmark,
} from '@attentive/picnic';
import {
  sendPasswordResetEmail,
  useLoginStrategyClient,
  StrategyType,
  DEMO_EMAIL,
} from '@attentive/auth-core';

import { setDocumentTitle, validateEmail } from '../utils';
import { AUTH_PAGE_WIDTH } from '../constants';
import { SignInLayout } from '@attentive/auth-ui';

const ForgotPassword: React.FC = () => {
  const loginStrategyClient = useLoginStrategyClient();
  const [email, setEmail] = React.useState('');
  const [isWorking, setIsWorking] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [emailSuccess, setEmailSuccess] = React.useState(false);

  React.useEffect(() => {
    const { email: emailAddress } = queryString.parse(location.search);
    if (emailAddress) {
      setEmail(`${emailAddress}`);
    }
    setDocumentTitle('Forgot Password');
  }, []);

  const submitForm = React.useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      setIsWorking(true);
      setErrorMessage(null);

      if (email === DEMO_EMAIL) {
        setErrorMessage("This user's password cannot be reset.");
        setIsWorking(false);
        return;
      }

      const strategy = await loginStrategyClient.getStrategy(email);

      if (strategy.type !== StrategyType.CREDENTIALS) {
        setErrorMessage(
          'Your organization uses single sign-on. Please contact your administrator for help resetting your password.'
        );
        setIsWorking(false);
        return;
      }

      sendPasswordResetEmail(email)
        .then(() => {
          setIsWorking(false);
          setEmailSuccess(true);
        })
        .catch((err) => {
          setIsWorking(false);
          setErrorMessage(err.message);
        });
    },
    [email, loginStrategyClient]
  );

  const isValidEmail = validateEmail(email);

  return (
    <SignInLayout>
      <Box css={{ width: '100%', maxWidth: AUTH_PAGE_WIDTH }}>
        <form onSubmit={submitForm}>
          <Wordmark
            title="Attentive forgot password page"
            width="140px"
            css={{ mb: '$space12', display: 'block' }}
          />
          {emailSuccess ? (
            <>
              <Heading variant="page" css={{ marginBottom: '$space6' }}>
                Email sent
              </Heading>
              <Text css={{ mb: '$space4' }}>
                Check your inbox for a link to reset your password. Please check your spam folder if
                you don't see it.
              </Text>

              <Link as={RouterLink} css={{ display: 'block', mb: '$space4' }} to="/signin">
                Return to sign in
              </Link>
            </>
          ) : (
            <>
              <Heading variant="page" css={{ marginBottom: '$space6' }}>
                Reset your password
              </Heading>
              {Boolean(errorMessage) && (
                <Banner variant="error" css={{ mb: '$space4' }}>
                  <Banner.Text>{errorMessage}</Banner.Text>
                </Banner>
              )}
              <Text css={{ mb: '$space4' }}>
                To reset your password, enter the email address you use to sign in to your account.
              </Text>

              <FormField css={{ mb: '$space4' }}>
                <FormField.Label htmlFor="email">Email Address</FormField.Label>
                <TextInput
                  id="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value.trim())}
                  autoFocus
                />
              </FormField>

              <Button
                type="submit"
                disabled={!isValidEmail}
                loading={isWorking}
                css={{ width: '100%', mb: '$space8' }}
              >
                Reset password
              </Button>

              <Box css={{ textAlign: 'center' }}>
                <Link as={RouterLink} to="/signin">
                  Cancel
                </Link>
              </Box>
            </>
          )}
        </form>
      </Box>
    </SignInLayout>
  );
};

export default ForgotPassword;
