import { Tracker, TrackerEvents } from '@attentive/acore-utils';

export function trackCreateDropdownClicked() {
  Tracker.track({
    eventName: TrackerEvents.ACORE_NAV_CREATE_DROPDOWN_CLICKED,
    properties: {
      source: 'topnav dropdown',
    },
  });
}

export function trackCompanySelected(toCompanyId: number, toCompanyName: string) {
  Tracker.track({
    eventName: TrackerEvents.USER_COMPANY_SELECTION_CHANGED,
    properties: {
      toCompanyId,
      toCompanyName,
    },
  });
}
