import { createEntryPoint } from '@attentive/data-router';

import Query from './__generated__/AffiliateUserManagementQuery.graphql';

export const AffiliateUserManagementPageEntryPoint = createEntryPoint({
  component: () => import('./AffiliateUserManagementPage'),
  getQueries: ({ getCurrentCompanyId }) => ({
    query: { parameters: Query, variables: { companyId: getCurrentCompanyId(), first: 10 } },
  }),
});
