import React from 'react';
import { useLocation } from 'react-router-dom';

import { PicnicCss } from '@attentive/picnic';

import { SelectedPathsContext, getSelectedNavItemPaths } from './useSelectedItemPaths';
import { SidebarNavAccordion } from './SidebarNavAccordion';

export const SidebarNavContainer: React.FC<{ css?: PicnicCss }> = ({ children, css }) => {
  const location = useLocation();
  const selectedPaths = getSelectedNavItemPaths(location.pathname, children);

  return (
    <nav>
      <SelectedPathsContext.Provider value={selectedPaths}>
        <SidebarNavAccordion.Root css={css} defaultValue={selectedPaths.selectedNavItemPath || ''}>
          {children}
        </SidebarNavAccordion.Root>
      </SelectedPathsContext.Provider>
    </nav>
  );
};
