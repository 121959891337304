import React from 'react';

import { Box, Icon, Separator, Text, styled } from '@attentive/picnic';
import { useOpenZendeskLink } from '@attentive/auth-core';

import { HelpCenterArticle } from '../api';

interface ZendeskArticleListProps {
  articles: HelpCenterArticle[];
}

const ArticleLink = styled('a', {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  p: '$space4',

  '&:hover': {
    backgroundColor: '$bgRowHover',
  },
});

const ZendeskArticle: React.VFC<HelpCenterArticle> = ({ title, snippet, htmlUrl }) => {
  const openZendeskLink = useOpenZendeskLink(htmlUrl, '_blank');

  return (
    <ArticleLink
      href={htmlUrl}
      target="_blank"
      rel="noopener noreferrer"
      onClick={openZendeskLink}
      data-nav-id="help-center-search-article-link"
    >
      <Box>
        <Text variant="caption" css={{ fontWeight: '$bold' }}>
          {title}
        </Text>
        {snippet && (
          <Text css={{ mt: '$space1', maxLines: '2' }} variant="caption">
            {snippet}
          </Text>
        )}
      </Box>
      <Icon name="ChevronRight" size="small" css={{ flexShrink: 0, ml: '$space3' }} />
    </ArticleLink>
  );
};

export const ZendeskArticleList: React.VFC<ZendeskArticleListProps> = ({ articles }) => {
  return (
    <>
      {articles.map((article, index) => {
        const isLast = index === articles.length - 1;

        return (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            <ZendeskArticle {...article} />
            {!isLast && <Separator />}
          </React.Fragment>
        );
      })}
    </>
  );
};
