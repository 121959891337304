import { createEntryPoint } from '@attentive/data-router';

import Query from './__generated__/SyncDetail_EDSLib_Query.graphql';

export const SyncDetailEntryPoint = createEntryPoint({
  component: () => import('./SyncDetail'),
  getQueries: ({ params }) => {
    return {
      query: {
        parameters: Query,
        variables: {
          syncId: params.id || '',
        },
      },
    };
  },
});
