import React from 'react';

import { Text, Grid, PicnicCss, Heading, Box } from '@attentive/picnic';

import { DateFormat, formatDateWithTimezone, Locale, Timezone } from '@attentive/locale-utils';
import { commitDetailsAtom } from './commit-details-atom';
import { useAtomValue } from 'jotai';

const cellCss: PicnicCss = {
  p: '$space1',
};

export const BuildInfoTable: React.FC = () => {
  const { commitSha, timestamp } = useAtomValue(commitDetailsAtom);

  return (
    <Box css={{ width: '100%' }}>
      <Heading as="h2" variant="md">
        Build Info
      </Heading>
      <Grid columns={2} css={{ rowGap: '$space1', width: '100%' }}>
        <Grid.Cell css={cellCss}>
          <Text>Commit SHA:</Text>
        </Grid.Cell>
        <Grid.Cell css={cellCss}>
          <Text css={{ textAlign: 'right' }} data-testid="all-apps:version">
            {commitSha.substring(0, 7)}
          </Text>
        </Grid.Cell>
        <Grid.Cell css={cellCss}>
          <Text>Build Date:</Text>
        </Grid.Cell>
        <Grid.Cell css={cellCss}>
          <Text css={{ textAlign: 'right' }} data-testid="formatted-date:version">
            {formatDateWithTimezone(timestamp, DateFormat.SHORT_DATE_WITH_TIME, {
              locale: Locale.enUS,
              timezone: Timezone.EST,
            })}
          </Text>
        </Grid.Cell>
      </Grid>
    </Box>
  );
};
