import _useActiveDismissibleModalFragment_query from "./__generated__/useActiveDismissibleModalFragment_query.graphql";
import { useFragment } from 'react-relay';
import { graphql } from '@attentive/data';
import useActiveDismissibleModalFragment_query from './__generated__/useActiveDismissibleModalFragment_query.graphql';
import { getUrlPath } from './utils';
_useActiveDismissibleModalFragment_query;
export const useActiveDismissibleModal = queryRef => {
  const {
    dismissibleModals
  } = useFragment(useActiveDismissibleModalFragment_query, queryRef);
  const DEFAULT_MODAL_PATH = 'https://ui.attentivemobile.com/reference/dismiss-modal';
  return dismissibleModals === null || dismissibleModals === void 0 ? void 0 : dismissibleModals.edges.map(_ref => {
    let {
      node
    } = _ref;

    if (node.url.startsWith('http://') || node.url.startsWith('https://')) {
      return {
        route: {
          path: getUrlPath(node.url)
        },
        content: node
      };
    }

    return {
      route: {
        path: getUrlPath(DEFAULT_MODAL_PATH)
      },
      content: node
    };
  });
};