/**
 * @generated SignedSource<<507e76173ec8478f10de786f9d6b441f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useReadNotificationsClientUIMutation$variables = {
  companyId: string;
  notificationInstanceId: string;
};
export type useReadNotificationsClientUIMutation$data = {
  readonly readNotification: {
    readonly notification: {
      readonly " $fragmentSpreads": FragmentRefs<"NotificationsMenuRowClientUIFragment_notification">;
    };
  } | null;
};
export type useReadNotificationsClientUIMutation$rawResponse = {
  readonly readNotification: {
    readonly notification: {
      readonly callToAction: string;
      readonly description: string;
      readonly dismissable: boolean;
      readonly id: string;
      readonly read: boolean;
      readonly severity: string;
      readonly title: string;
      readonly url: string;
    };
  } | null;
};
export type useReadNotificationsClientUIMutation = {
  rawResponse: useReadNotificationsClientUIMutation$rawResponse;
  response: useReadNotificationsClientUIMutation$data;
  variables: useReadNotificationsClientUIMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "notificationInstanceId"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "companyId",
        "variableName": "companyId"
      },
      {
        "kind": "Variable",
        "name": "notificationInstanceId",
        "variableName": "notificationInstanceId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useReadNotificationsClientUIMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReadNotificationPayload",
        "kind": "LinkedField",
        "name": "readNotification",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Notification",
            "kind": "LinkedField",
            "name": "notification",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "NotificationsMenuRowClientUIFragment_notification"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useReadNotificationsClientUIMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReadNotificationPayload",
        "kind": "LinkedField",
        "name": "readNotification",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Notification",
            "kind": "LinkedField",
            "name": "notification",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "severity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dismissable",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "callToAction",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "read",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fe97832d7e50db19c1bacc83d135bad6",
    "id": null,
    "metadata": {},
    "name": "useReadNotificationsClientUIMutation",
    "operationKind": "mutation",
    "text": "mutation useReadNotificationsClientUIMutation(\n  $companyId: ID!\n  $notificationInstanceId: ID!\n) {\n  readNotification(input: {companyId: $companyId, notificationInstanceId: $notificationInstanceId}) {\n    notification {\n      ...NotificationsMenuRowClientUIFragment_notification\n      id\n    }\n  }\n}\n\nfragment NotificationsMenuRowClientUIFragment_notification on Notification {\n  id\n  severity\n  dismissable\n  title\n  description\n  callToAction\n  url\n  read\n}\n"
  }
};
})();

(node as any).hash = "7698cf62573e197e2e5d93a35e661375";

export default node;
