import { createEntryPoint } from '@attentive/data-router';

import Query from './__generated__/SuppressionListTab_CRMLib_Query.graphql';

export const SuppressionListTabEntryPoint = createEntryPoint({
  component: () => import('./SuppressionListTab'),
  getQueries: ({ getCurrentCompanyId }) => ({
    query: {
      parameters: Query,
      variables: { companyId: getCurrentCompanyId(), first: 10, filter: { status: 'SUPPRESSED' } },
    },
  }),
});
