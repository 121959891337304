import { createEntryPoint } from '@attentive/data-router';

import Query from './__generated__/AIJourneysPerformancePageQuery.graphql';

export const AIJourneysPerformancePageEntryPoint = createEntryPoint({
  component: () => import('./AIJourneysPerformancePage'),
  getQueries: ({ getCurrentCompanyId }) => {
    return {
      query: {
        parameters: Query,
        variables: {
          companyId: getCurrentCompanyId(),
        },
      },
    };
  },
});
