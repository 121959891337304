import { QueryClient } from '@attentive/data/react-query';

type QueryClientConfigType = {
  defaultOptions: {
    queries: {
      refetchInterval?: number | false;
      refetchIntervalInBackground?: boolean;
      refetchOnWindowFocus?: boolean | 'always';
      refetchOnReconnect?: boolean | 'always';
      retry?: boolean;
    };
  };
};

// Define the query client config separate from the QueryClient constructor so we have better control of queryClient in tests
export const queryClientConfig: QueryClientConfigType = {
  defaultOptions: {
    queries: {
      refetchInterval: false, //prevent automatic refetchs
      refetchIntervalInBackground: false, // if refetches are enabled, prevents them in the background
      refetchOnWindowFocus: false, // prevent refetch on window refocus
      refetchOnReconnect: false, // prevent refetch on reconnect
      retry: false, // prevent retries
    },
  },
};

export const queryClient = new QueryClient(queryClientConfig);
