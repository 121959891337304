import React from 'react';
import { useAtom } from 'jotai';

import { Select, ThemeName } from '@attentive/picnic';
import { themeNameAtom } from '@attentive/acore-utils';

import { storeEnvVarInSessionStorage } from '../../utils/app-persistence';

export const ThemeSwitcher = () => {
  const [themeName, setThemeName] = useAtom(themeNameAtom);

  const setTheme = (value: string) => {
    storeEnvVarInSessionStorage('themeName', value);

    setThemeName(value as ThemeName);
  };

  return (
    <Select onChange={setTheme} value={themeName} aria-label="Theme switcher">
      <Select.Item value={ThemeName.Theme2021}>Default</Select.Item>
      <Select.Item value={ThemeName.ThemeDark}>Dark mode (experimental)</Select.Item>
    </Select>
  );
};
