import { createEntryPoint } from '@attentive/data-router';

import HomeUIBodyQuery from './__generated__/HomeUIBodyQuery.graphql';
import { AppStore, LifecycleStatus, companyLifecycleAtom } from '@attentive/acore-utils';

export const HomeEntryPoint = createEntryPoint({
  component: () => import('./HomeMfe'),
  getQueries: ({ getCurrentCompanyId }) => {
    return {
      query: {
        parameters: HomeUIBodyQuery,
        variables: {
          companyId: getCurrentCompanyId(),
          // Use of AppStore.store.get decided while pairing w app platform as this is the
          // only use of this atom
          // do not copy pattern for other atoms, they might not yet be defined
          isTenured: AppStore.store.get(companyLifecycleAtom) === LifecycleStatus.TENURED,
        },
      },
    };
  },
});
