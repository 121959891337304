import React from 'react';

import { Dialog, Heading, Stack, Text } from '@attentive/picnic';
import { CompanySearchDropdown } from './CompanySearchDropdown';

export interface SwitchCompanyDialogProps {
  open: boolean;
  onClose?: () => void;
  onSelectCompany: (companyId: string | null) => void;
}

export const SwitchCompanyDialog: React.FC<SwitchCompanyDialogProps> = ({
  open,
  onClose,
  onSelectCompany,
}) => {
  return (
    <Dialog
      open={open}
      includeOverlay
      onOpenChange={(isOpen) => (!isOpen && onClose ? onClose() : null)}
    >
      <Dialog.Content css={{ p: '$space8', overflow: 'visible' }}>
        <Dialog.CloseButton css={{ top: '$space4', right: '$space4', position: 'absolute' }} />
        <Stack>
          <Heading variant="lg">Switch accounts</Heading>
          <Text variant="body">Sign in to Attentive under a different account.</Text>
          <CompanySearchDropdown onSelect={onSelectCompany} />
        </Stack>
      </Dialog.Content>
    </Dialog>
  );
};
