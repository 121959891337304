import React, { ComponentProps } from 'react';
import { Box, Badge, BadgeVariant, IconCircle, IconName, PicnicCss } from '@attentive/picnic';

interface SidebarIconBadgeProps {
  selected: boolean;
  iconName: IconName;
  size: ComponentProps<typeof IconCircle>['size'];
  badgeContent?: string | null;
  badgeVariant?: BadgeVariant | null;
  badgeAriaLabel?: string;
}

const PU_BADGE_POSITION: PicnicCss = { transform: 'unset', right: '-8px', top: '-4px' };

export const SidebarIconBadge: React.VFC<SidebarIconBadgeProps> = ({
  selected,
  iconName,
  badgeContent,
  badgeVariant,
  badgeAriaLabel,
  size = 'medium',
  ...rest
}) => {
  const iconCircleColor = selected ? 'brand' : 'inverted';
  // Hide the circle background but maintain positioning with when the circle is visible.
  const iconCircleCss = selected ? {} : { backgroundColor: 'transparent' };

  return (
    <Box as="span" css={{ position: 'relative' }}>
      <IconCircle
        iconName={iconName}
        size={size}
        color={iconCircleColor}
        css={iconCircleCss}
        {...rest}
      />
      {badgeContent && (
        <Badge
          position="raised"
          variant={badgeVariant ?? (selected ? 'active' : 'standard')}
          css={PU_BADGE_POSITION}
          role="status"
          aria-label={badgeAriaLabel}
        >
          {badgeContent}
        </Badge>
      )}
    </Box>
  );
};
