import React from 'react';

import { Button, Text } from '@attentive/picnic';
import { GRAPHQL_API_ORIGIN_KEY, graphqlApiOriginAtom } from '@attentive/acore-utils';
import { useAtomValue } from 'jotai';
import { resetEnvVarsInSessionStorage } from '../../utils/app-persistence';

export const ApiOverrideMessage = () => {
  const graphqlOrigin = useAtomValue(graphqlApiOriginAtom);
  return (
    <>
      <Text css={{ marginBottom: '$space8' }}>
        An API override is in place.
        <br />
        Graphql's API Source is set to <code>{graphqlOrigin}</code>
        <br />
        Start your local server and refresh the page, or reset your session variables.
        <br />
        You may want to <strong>sign on to the VPN</strong> to connect to dev resources via your
        local graphql-gateway.
      </Text>

      <Button
        onClick={() => {
          resetEnvVarsInSessionStorage();

          const newUrl = new URL(window.location.href);
          newUrl.searchParams.delete(GRAPHQL_API_ORIGIN_KEY);

          window.location.href = newUrl.toString();
        }}
      >
        Reset Session Variables
      </Button>
    </>
  );
};
