import { createEntryPoint } from '@attentive/data-router';

import Query from './__generated__/AIProTrialSignUp_Query.graphql';

export const AIProTrialSignUpPageEntryPoint = createEntryPoint({
  component: () => import('./AIProTrialSignUp'),
  getQueries: ({ getCurrentCompanyId }) => {
    return {
      query: {
        parameters: Query,
        variables: {
          companyId: getCurrentCompanyId(),
        },
      },
    };
  },
});
