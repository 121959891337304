import React from 'react';

import { styled, Heading, Dialog, PicnicCss, Stack, Text } from '@attentive/picnic';

import toolsImage from '../../assets/tools.png';

const ImageStyled = styled('img', {
  width: 145,
  marginBottom: '$space4',
});

const contentStyles: PicnicCss = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  p: '$space4',
  maxWidth: 'min(calc(100vw - 2 * $space4), 350px)',
};

interface SystemStatusDialogProps {
  defaultOpen: boolean;
  onClose: (open: Boolean) => void;
}

const SystemStatusDialogComponent: React.FC<SystemStatusDialogProps> = ({
  defaultOpen,
  onClose,
}) => {
  return (
    <Dialog defaultOpen={defaultOpen} onOpenChange={onClose}>
      <Dialog.Content css={contentStyles}>
        <Dialog.CloseButton css={{ position: 'fixed', top: 0, right: 0 }} />
        <Stack
          spacing="$space4"
          css={{
            justifyContent: 'center',
            alignItems: 'center',
            p: '$space4',
          }}
        >
          <ImageStyled src={toolsImage} alt="Tools Image" />
          <Heading variant="xl">We're experiencing intermittent issues.</Heading>
          <Text>We're working on a solution &mdash; please check back soon!</Text>
          <Dialog.Close>Got it</Dialog.Close>
        </Stack>
      </Dialog.Content>
    </Dialog>
  );
};

export const SystemStatusDialog = SystemStatusDialogComponent;
