import React from 'react';

import { Text, Grid, PicnicCss, Heading, Box } from '@attentive/picnic';
import {
  MARK_CLIENT_UI_RENDERING,
  MARK_CLIENT_UI_STARTED,
  MARK_MFE_LOADED,
} from '@attentive/acore-utils';

const cellCss: PicnicCss = {
  p: '$space1',
};

function calculatePerformanceTimes(timings: PerformanceEntryList) {
  function findPerfStartTime(name: string) {
    const item = timings.find((t) => t.name === name);
    if (item) {
      return item.startTime;
    }
    return Infinity;
  }

  return [
    { name: 'First paint', startTime: findPerfStartTime('first-paint') },
    { name: 'App start', startTime: findPerfStartTime(MARK_CLIENT_UI_STARTED) },
    { name: 'App ready', startTime: findPerfStartTime(MARK_CLIENT_UI_RENDERING) },
    { name: 'MFE ready', startTime: findPerfStartTime(MARK_MFE_LOADED) },
  ].filter(({ startTime }) => Number.isFinite(startTime));
}

export const PerformanceTable: React.FC<{ timings: PerformanceEntryList }> = ({ timings }) => {
  const appPerfMarks = calculatePerformanceTimes(timings);

  function formatTime(ms: number) {
    if (ms > 1000) {
      return `${(ms / 1000).toFixed(1)}s`;
    }
    return `${ms.toFixed(0)}ms`;
  }

  return (
    <Box css={{ width: '100%' }}>
      <Heading as="h2" variant="md">
        Performance
      </Heading>
      <Grid columns={2} css={{ rowGap: '$space1', width: '100%', mb: '$space4' }}>
        {appPerfMarks.map(({ name, startTime }) => (
          <React.Fragment key={name}>
            <Grid.Cell css={cellCss}>
              <Text>{name}</Text>
            </Grid.Cell>
            <Grid.Cell
              css={cellCss}
              data-testid={`${name.toLowerCase().replace(' ', '-')}:version`}
            >
              <Text css={{ textAlign: 'right' }}>{formatTime(startTime)}</Text>
            </Grid.Cell>
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
};
