import React, { useEffect } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { useResetAtom } from 'jotai/utils';
import { useSetAtom } from 'jotai';
import { useSearchParams, useNavigate } from 'react-router-dom';

import {
  ToastType,
  clientUIFeatureFlagsAtom,
  currentUserAtom,
  useSetCurrentCompanyId,
  useToast,
  Tracker,
  addDatadogContext,
} from '@attentive/acore-utils';
import {
  CompanyTransitionChallenge,
  handleChallenge,
  checkForUnavailableAuthenticatorError,
} from '@attentive/auth-core';

import {
  setUnregisteredAuthenticator,
  commitCompanyTransitionTransaction,
} from '../store/store-mutations';
import { useClientUICurrentUserFragment } from '../components/authenticated-app/AppCurrentUser';
import { trackCompanySelected } from '../utils/trackerUtils';
import { storeSessionCompanyId } from '../utils/app-persistence';
import { generateRedirectUrlFromSearchParams } from '../utils/redirects';
import { useAppBodyQueryData } from '../components/authenticated-app/AppBody';

export const defaultErrorString =
  'Challenge failed. Please reload the page and try again. If you continue to fail, return to sign in.';

export const Fido2Challenge = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [createToast] = useToast();
  const resetFeatureFlagsAtom = useResetAtom(clientUIFeatureFlagsAtom);
  const setCurrentCompanyId = useSetCurrentCompanyId();
  const updateCurrentUserAtom = useSetAtom(currentUserAtom);
  const queryData = useAppBodyQueryData();
  const currentUser = useClientUICurrentUserFragment(queryData);
  const navigate = useNavigate();
  const environment = useRelayEnvironment();

  const nonce = searchParams.get('challenge');
  const companyId = searchParams.get('companyId');

  useEffect(() => {
    if (!companyId || !nonce) {
      //Throw?
      return;
    }

    handleChallenge(nonce, companyId, environment)
      .then((res) => {
        // remove search params set for use in this component
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete('challenge');
        newSearchParams.delete('companyId');
        setSearchParams(newSearchParams);

        resetFeatureFlagsAtom();
        setCurrentCompanyId(companyId);
        updateCurrentUserAtom(currentUser);
        Tracker.identify('client-ui', currentUser);
        trackCompanySelected(res.companyInternalId, res.companyName);

        const companyRestId = res.companyInternalId;
        storeSessionCompanyId(companyRestId);

        commitCompanyTransitionTransaction();
        addDatadogContext('companyId', companyRestId);

        const redirectUrl = generateRedirectUrlFromSearchParams(newSearchParams);
        location.assign(redirectUrl);
        return;
      })
      .catch((err) => {
        if (checkForUnavailableAuthenticatorError(err)) {
          setUnregisteredAuthenticator();
          return;
        }
        createToast({
          type: ToastType.Error,
          text: err || defaultErrorString,
          title: 'Failed',
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    nonce,
    createToast,
    companyId,
    resetFeatureFlagsAtom,
    setCurrentCompanyId,
    currentUser,
    updateCurrentUserAtom,
    navigate,
  ]);

  return <CompanyTransitionChallenge />;
};
