import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAtomValue } from 'jotai';

import { Box } from '@attentive/picnic';

import { SignInLayout } from '@attentive/auth-ui';
import { useUserIsAuthenticated } from '../store/site-data';
import { MockAccountForm, PasswordGate } from '../components/mock-accounts';
import {
  MockLoginGateStrategy,
  mockLoginGateStrategyAtom,
} from '../components/mock-accounts/mock-accounts-gated-atom';

export const MockSignin: React.VFC = () => {
  const mockLoginGateStrategy = useAtomValue(mockLoginGateStrategyAtom);
  const [showPersonas, setShowPersonas] = useState(
    mockLoginGateStrategy === MockLoginGateStrategy.None
  );
  const navigate = useNavigate();
  const isAuthenticated = useUserIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      // Already logged in - change history to app root
      navigate({ pathname: '/' });
    }
  }, [isAuthenticated, navigate]);

  return (
    <SignInLayout>
      <Box css={{ width: '100%' }}>
        {showPersonas && <MockAccountForm />}
        {!showPersonas && <PasswordGate onCorrectPassword={() => setShowPersonas(true)} />}
      </Box>
    </SignInLayout>
  );
};
