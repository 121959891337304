import { ZodFormattedError } from 'zod';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logZodErrors = (errors: ZodFormattedError<any>) => {
  for (const [envVarName, errorDetails] of Object.entries(errors)) {
    if (errorDetails && '_errors' in errorDetails) {
      for (const error of errorDetails._errors) {
        console.error(`Invalid environment variable "${envVarName}" value: ${error}`);
      }
    } else if (errorDetails && errorDetails.length > 0) {
      for (const error of errorDetails) {
        console.error(error);
      }
    }
  }
};
