import React, { useCallback, useEffect } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { useCurrentCompanyId, useSetCurrentCompanyId } from '@attentive/acore-utils';
import {
  checkForUnauthenticatedError,
  useAuthClient,
  checkForUnavailableAuthenticatorError,
} from '@attentive/auth-core';
import {
  beginAppTeardownTransaction,
  setCompanyAccessRevoked,
  setUnregisteredAuthenticator,
} from '../../../store/store-mutations';
import { useRefreshTimer } from '../useRefreshTimer';
import { useSearchParams } from 'react-router-dom';

const JWT_DURATION_MS = 15 * 60 * 1000; // 15 minutes
const REFRESH_INTERVAL_MS = 60 * 1000;

export function AuthClock() {
  const authClient = useAuthClient();
  const currentCompanyId = useCurrentCompanyId();
  const setCurrentCompanyId = useSetCurrentCompanyId();
  const relayEnvironment = useRelayEnvironment();
  const [searchParams, setSearchParams] = useSearchParams();

  const lastAuthTimeRef = React.useRef<number>(Date.now());

  // Verify that the user is authenticated, if not then begin the signout process
  const verifySession = useCallback(async () => {
    try {
      const { companyId: newCompanyId, challengeString } = await authClient.verifySession(
        {
          companyId: currentCompanyId,
        },
        relayEnvironment
      );

      // Most likely a user will not run into a challenge timing out - if they do not perform the challenge, their session token will time out and they will be logged out of the UI before the challenge has expired.
      const currentChallenge = searchParams.get('challenge')?.toString();

      const isCurrentChallengeEqualToNew = currentChallenge === challengeString;

      if (challengeString && !isCurrentChallengeEqualToNew) {
        const params = new URLSearchParams(searchParams);
        params.set('challenge', challengeString);
        params.set('companyId', newCompanyId);
        setSearchParams(params);
        return;
      }

      // if error begin app teardown

      if (newCompanyId !== currentCompanyId) {
        // If newCompanyId and currentCompanyId don't match, the user lost access to currentCompanyId.
        // Show an error screen allowing them to switch to whichever company the backend chose.
        setCurrentCompanyId(newCompanyId);
        setCompanyAccessRevoked();
      }
      lastAuthTimeRef.current = Date.now();
    } catch (error) {
      if (checkForUnavailableAuthenticatorError(error)) {
        setUnregisteredAuthenticator();
        return;
      }
      const timeSinceLastAuth = Date.now() - lastAuthTimeRef.current;
      if (checkForUnauthenticatedError(error) || timeSinceLastAuth > JWT_DURATION_MS) {
        beginAppTeardownTransaction({ reasonMessage: error.message });
      }
    }
  }, [
    authClient,
    currentCompanyId,
    setCurrentCompanyId,
    relayEnvironment,
    searchParams,
    setSearchParams,
  ]);

  // If the authClient has changed, the user may now be signed out.
  // If the currentCompanyId has changed, the user may need to re-authenticate with their new company.
  // Either way, we should immediately re-verify the current session.
  useEffect(() => {
    verifySession();
  }, [verifySession]);

  useRefreshTimer(verifySession, REFRESH_INTERVAL_MS);

  return null;
}
