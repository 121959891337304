import React from 'react';
import { Outlet } from 'react-router-dom';

import { SectionName } from '../shared/constants/routes';
import { SlotNameProvider } from '../shared/utils/logger';
import { RouteErrorBoundary } from './RouteErrorBoundary';

type SettingsPageRouteProps = {
  sectionName: SectionName;
};

const SettingsPageRoute: React.FC<SettingsPageRouteProps> = ({ sectionName }) => {
  return (
    <SlotNameProvider value={sectionName}>
      <RouteErrorBoundary sectionName={sectionName}>
        <Outlet />
      </RouteErrorBoundary>
    </SlotNameProvider>
  );
};

export { SettingsPageRoute };
