import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCurrentCompanyId } from '@attentive/acore-utils';
import { Box, Button, Text } from '@attentive/picnic';
import { beginCompanyTransitionTransaction } from '../store/store-mutations';
import AppError from './AppError';
import { useAppErrorMessage } from '../store/site-data';

const CompanyAccessRevoked: React.FC = () => {
  const navigate = useNavigate();
  const message = useAppErrorMessage();
  const companyId = useCurrentCompanyId();

  const switchCompanies = useCallback(() => {
    if (companyId !== null) {
      beginCompanyTransitionTransaction({ companyId });
    }
    navigate('/', { replace: true });
  }, [companyId, navigate]);

  return (
    <AppError>
      <Box css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Text css={{ mb: '$space4' }}>
          {message || 'Your session is being switched to a different company.'}
        </Text>
        <Button onClick={switchCompanies}>Switch companies</Button>
      </Box>
    </AppError>
  );
};

export default CompanyAccessRevoked;
