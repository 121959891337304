import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { DropdownMenu } from '@attentive/picnic';
import { Routes } from '../../../../../routes';

export const EmergencyAppNavigationItems = () => {
  return (
    <DropdownMenu.TextItem asChild>
      <RouterLink to={Routes.Campaigns}>Client UI</RouterLink>
    </DropdownMenu.TextItem>
  );
};
