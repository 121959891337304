import { useQuery } from '@attentive/data/react-query';

import { API } from '@attentive/acore-utils';
import { vendors, IntegrationError } from '@attentive/integrations-common';

type UseIntegrationErrorsCountProps = {
  isIntegrationErrorsEnabled: boolean;
  companyId: string;
};

export const useIntegrationErrorsCount = ({
  isIntegrationErrorsEnabled,
  companyId,
}: UseIntegrationErrorsCountProps) => {
  const { data: integrationErrors } = useQuery<IntegrationError[]>(
    ['integrationErrors', companyId],
    async () => {
      // GMRU: GET /integrations/errors
      try {
        const response = await API.get('/integrations/errors');
        if (response.status < 400) {
          return response.body as IntegrationError[];
        }
        return [];
      } catch (e) {
        return [];
      }
    }
  );

  const visibleIntegrationErrors = integrationErrors?.filter(
    (error) => vendors[error.vendor]?.errorReportingEnabled
  );

  if (isIntegrationErrorsEnabled && visibleIntegrationErrors) {
    return visibleIntegrationErrors.length;
  }

  return 0;
};
