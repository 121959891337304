import { createEntryPoint } from '@attentive/data-router';

import Query from './__generated__/CustomAttributeList_TargetingCommon_Query.graphql';

export const CustomAttributesListEntryPoint = createEntryPoint({
  component: () => import('./CustomAttributeList'),
  getQueries: ({ getCurrentCompanyId }) => ({
    query: {
      parameters: Query,
      variables: { companyId: getCurrentCompanyId() },
    },
  }),
});
