import { ClientSiteAppStatus, AppStatus, AppErrorCodes } from '@attentive/data';
import { AppStore } from '@attentive/acore-utils';
import {
  appErrorCodeAtom,
  appErrorMessageAtom,
  appStatusAtom,
  appTeardownReasonMessageAtom,
  degradationWarningAtom,
  transitionCompanyIdAtom,
} from './site-data';

type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export const setAppStatus = (
  appStatus: WithOptional<ClientSiteAppStatus, 'errorCode' | 'errorMessage'>
) => {
  const { state, errorCode = null, errorMessage = null } = appStatus;
  AppStore.store.set(appStatusAtom, state);
  AppStore.store.set(appErrorCodeAtom, errorCode);
  AppStore.store.set(appErrorMessageAtom, errorMessage || '');
};

export const beginCompanyTransitionTransaction = ({ companyId }: { companyId: string }) => {
  AppStore.store.set(transitionCompanyIdAtom, companyId);
  setAppStatus({
    state: AppStatus.COMPANY_TRANSITION,
  });
};

export const commitCompanyTransitionTransaction = () => {
  setAppStatus({
    state: AppStatus.ACTIVE,
  });
};

export const abortCompanyTransitionTransaction = (errorMessage: string) => {
  setAppStatus({
    state: AppStatus.ERROR,
    errorCode: AppErrorCodes.COMPANY_TRANSITION_ABORTED,
    errorMessage,
  });
};

export const setUnregisteredAuthenticator = () => {
  setAppStatus({
    state: AppStatus.ERROR,
    errorCode: AppErrorCodes.AUTHENTICATOR_NOT_REGISTERED,
    errorMessage: 'Please register an authenoticator before switching to a client cmpany.',
  });
};

export const beginAppTeardownTransaction = ({
  reasonMessage,
}: {
  reasonMessage: string | null;
}) => {
  AppStore.store.set(appTeardownReasonMessageAtom, reasonMessage);
  setAppStatus({
    state: AppStatus.TERMINATED,
  });
};

export const abortAppTeardownTransaction = (errorMessage: string) => {
  setAppStatus({
    state: AppStatus.ERROR,
    errorCode: AppErrorCodes.LOGOUT_ABORTED,
    errorMessage,
  });
};

export const setCompanyAccessRevoked = () => {
  setAppStatus({
    state: AppStatus.ERROR,
    errorCode: AppErrorCodes.COMPANY_ACCESS_REVOKED,
    errorMessage: 'Your access to your current company has been revoked.',
  });
};

export const setDegradationWarningMessage = (value: string | null) => {
  AppStore.store.set(degradationWarningAtom, value);
};
