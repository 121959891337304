/**
 * @generated SignedSource<<dbcdee91cb99c89296ab98ee00de0834>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConciergeTopBarDropdownMenuClientUIFragment_account$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccountNameClientUIFragment_account">;
  readonly " $fragmentType": "ConciergeTopBarDropdownMenuClientUIFragment_account";
};
export type ConciergeTopBarDropdownMenuClientUIFragment_account$key = {
  readonly " $data"?: ConciergeTopBarDropdownMenuClientUIFragment_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConciergeTopBarDropdownMenuClientUIFragment_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConciergeTopBarDropdownMenuClientUIFragment_account",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccountNameClientUIFragment_account"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "9a0c5728dc05003994dcfdd3def940f0";

export default node;
