import React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { CompanyFeatureFlagNames, RoutePermissions } from '@attentive/acore-utils';
import { Permission } from '@attentive/data';
import { RoutesFn } from '@attentive/data-router';

import { RelayConventionsPageEntryPoint } from './pages/graphql-examples/relay-conventions/RelayConventionsPage.entrypoint';
import { WelcomeEntryPoint } from './pages/welcome/Welcome.entrypoint';

const routes: RoutesFn<CompanyFeatureFlagNames> = () => (
  <Route Component={React.lazy(() => import('./ReferenceMfe'))}>
    <Route index {...WelcomeEntryPoint} />
    <Route path="more" Component={React.lazy(() => import('./pages/More'))} />
    <Route path="dismiss-modal" Component={React.lazy(() => import('./pages/DismissModal'))} />
    <Route element={<RoutePermissions permission={Permission.ReferenceMfeAccess} />}>
      <Route path="graphql/*">
        <Route path="relay-conventions" {...RelayConventionsPageEntryPoint} />
        <Route
          path="presenter"
          Component={React.lazy(() => import('./pages/graphql-examples/presenter/PresenterPage'))}
        />
        <Route
          path="paginated-list"
          Component={React.lazy(() => import('./pages/graphql-examples/pagination/PaginationPage'))}
        />
        <Route
          index
          Component={React.lazy(() => import('./pages/graphql-examples/GraphQLExamples'))}
        />
      </Route>
      <Route
        path="graphql-explorer"
        Component={React.lazy(() => import('./pages/GraphQLExplorer'))}
      />
      <Route
        path="graphql-voyager"
        Component={React.lazy(() => import('./pages/GraphQLVoyager'))}
      />
      <Route path="*" element={<Navigate to="more" replace />} />
    </Route>
  </Route>
);

export { routes };
