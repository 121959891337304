import _siteDataClientUIQuery from "./__generated__/siteDataClientUIQuery.graphql";
import { fetchQuery } from 'react-relay';
import { graphql } from '@attentive/data'; // TODO: Move all this into Jotai atoms

import { atom, useAtomValue } from 'jotai';
import { AppStore } from '@attentive/acore-utils';
export const SiteDataClientUIQuery = _siteDataClientUIQuery;
export const transitionCompanyIdAtom = atom(null);
export const appTeardownReasonMessageAtom = atom(null);
export const userIsAuthenticatedAtom = atom(false);
export const appStatusAtom = atom('');
export const appErrorCodeAtom = atom(null);
export const appErrorMessageAtom = atom('');
export const degradationWarningAtom = atom(null);
export let UserAuthStatus;

(function (UserAuthStatus) {
  UserAuthStatus["unknown"] = "unknown";
  UserAuthStatus["authenticated"] = "authenticated";
  UserAuthStatus["unauthenticated"] = "unauthenticated";
})(UserAuthStatus || (UserAuthStatus = {}));

export const fetchSiteData = async relayEnvironment => {
  const siteData = await fetchQuery(relayEnvironment, SiteDataClientUIQuery, {}).toPromise();

  if (!siteData) {
    throw 'Site data query not retrieved';
  }

  return siteData;
};
export const hydrateSiteData = queryData => {
  if (queryData) {
    const {
      companyTransition,
      appTeardownTransaction,
      authStatus,
      appStatus,
      degradationWarningMessage
    } = queryData.site;
    AppStore.store.set(transitionCompanyIdAtom, companyTransition.companyId);
    AppStore.store.set(appTeardownReasonMessageAtom, appTeardownTransaction.reasonMessage);
    AppStore.store.set(userIsAuthenticatedAtom, authStatus.userAuthStatus === UserAuthStatus.authenticated);
    AppStore.store.set(appStatusAtom, appStatus.state);
    AppStore.store.set(appErrorCodeAtom, appStatus.errorCode);
    AppStore.store.set(appErrorMessageAtom, appStatus.errorMessage || '');
    AppStore.store.set(degradationWarningAtom, degradationWarningMessage);
  }
};
export const useTransitionCompanyId = () => {
  return useAtomValue(transitionCompanyIdAtom);
};
export const useAppTeardownReasonMessage = () => {
  return useAtomValue(appTeardownReasonMessageAtom);
};
export const useUserIsAuthenticated = () => {
  return useAtomValue(userIsAuthenticatedAtom);
};
export const useAppStatus = () => {
  return useAtomValue(appStatusAtom);
};
export const useAppErrorCode = () => {
  return useAtomValue(appErrorCodeAtom);
};
export const useAppErrorMessage = () => {
  return useAtomValue(appErrorMessageAtom);
};
export const useDegradationWarning = () => {
  return useAtomValue(degradationWarningAtom);
};