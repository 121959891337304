import { createEntryPoint } from '@attentive/data-router';
import Query from './__generated__/SubscriberUploadDialog_SettingsUI_Query.graphql';

export const SubscriberUploadDialogEntryPoint = createEntryPoint({
  component: () => import('./SubscriberUploadDialog'),
  getQueries: ({ getCurrentCompanyId }) => ({
    query: {
      parameters: Query,
      variables: { companyId: getCurrentCompanyId() },
    },
  }),
});
