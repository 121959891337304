import React from 'react';

import { Button, Icon, PicnicCss } from '@attentive/picnic';
import { useOpenZendeskLink } from '@attentive/auth-core';

interface ViewAllResultsButtonProps {
  helpCenterUrl: string;
  css?: PicnicCss;
}

export const ViewAllResultsButton: React.VFC<ViewAllResultsButtonProps> = ({
  helpCenterUrl,
  css,
  ...rest
}) => {
  const openZendeskLink = useOpenZendeskLink(helpCenterUrl, '_blank');

  return (
    <Button
      as="a"
      href={helpCenterUrl}
      target="_blank"
      rel="noopener noreferrer"
      variant="secondary"
      size="small"
      css={{ alignContent: 'center', ...css }}
      data-nav-id="help-center-search-view-results"
      onClick={openZendeskLink}
      {...rest}
    >
      View all results
      <Icon
        name="NewWindow"
        size="extraSmall"
        css={{
          ml: '$space2',
          // PU - this icon needs a slight adjustment to align correctly with the button text
          mt: '-1px',
        }}
      />
    </Button>
  );
};
