/**
 * @generated SignedSource<<c626f3d820759feb7513e6ab5e80e86f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Role = "ROLE_ADMIN_ACCESS" | "ROLE_ATTENTIVE_ACCOUNT" | "ROLE_CLIENT_ACCOUNT" | "ROLE_CLIENT_ADMIN_ACCOUNT" | "ROLE_CLIENT_LIMITED_ACCOUNT" | "ROLE_CLIENT_MEMBER_ACCOUNT" | "ROLE_CLIENT_STRATEGY" | "ROLE_CONCIERGE" | "ROLE_CONCIERGE_OPERATIONS" | "ROLE_CS_TAM" | "ROLE_E2E_TEST_USER" | "ROLE_ENGINEERING" | "ROLE_ENGINEERING_IDENTITY" | "ROLE_ENGINEERING_SALES" | "ROLE_ENGINEERING_SOLUTIONS" | "ROLE_IT_SECURITY" | "ROLE_MESSAGING_OPS" | "ROLE_PRODUCT_MANAGEMENT" | "ROLE_READONLY_CONCIERGE" | "ROLE_SUBSCRIBER_AUDITOR" | "ROLE_SUPER_USER" | "ROLE_UNKNOWN" | "%future added value";
export type Scope = "SCOPE_ALL_ACCOUNTS" | "SCOPE_ALL_CONCIERGE_SETTINGS" | "SCOPE_ALL_COUPONS" | "SCOPE_ALL_CUSTOM_ATTRIBUTES" | "SCOPE_ALL_CUSTOM_EVENTS" | "SCOPE_ALL_ECOMMERCE_EVENTS" | "SCOPE_ALL_IDENTITY" | "SCOPE_ALL_MESSAGES" | "SCOPE_ALL_OFFERS" | "SCOPE_ALL_POLLS" | "SCOPE_ALL_PRIVACY_REQUESTS" | "SCOPE_ALL_PRODUCT_CATALOGS" | "SCOPE_ALL_PROVISION" | "SCOPE_ALL_SCIM" | "SCOPE_ALL_SUBSCRIBER_EVENTS" | "SCOPE_ALL_WEBHOOKS" | "SCOPE_AUDIT_SUBSCRIPTIONS" | "SCOPE_UNKNOWN" | "SCOPE_VIEW_POLLS" | "SCOPE_VIEW_PRIVACY_REQUESTS" | "SCOPE_VIEW_PRODUCT_CATALOGS" | "SCOPE_VIEW_PROVISION" | "SCOPE_VIEW_SCIM" | "SCOPE_VIEW_SUBSCRIBER_EVENTS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ConciergeTopBarClientUIQueryFragment_query$data = {
  readonly viewer: {
    readonly account: {
      readonly " $fragmentSpreads": FragmentRefs<"ConciergeAvailabilityToggleFragment_account" | "ConciergeTopBarDropdownMenuClientUIFragment_account">;
    };
    readonly roles: {
      readonly roles: ReadonlyArray<Role>;
    } | null;
    readonly scopes: ReadonlyArray<Scope> | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ConciergeTemplateRequestsCountBadgeClientUIQueryFragment_query">;
  readonly " $fragmentType": "ConciergeTopBarClientUIQueryFragment_query";
};
export type ConciergeTopBarClientUIQueryFragment_query$key = {
  readonly " $data"?: ConciergeTopBarClientUIQueryFragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConciergeTopBarClientUIQueryFragment_query">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "companyId",
    "variableName": "companyId"
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "companyId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConciergeTopBarClientUIQueryFragment_query",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "concreteType": "Account",
              "kind": "LinkedField",
              "name": "account",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ConciergeAvailabilityToggleFragment_account"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ConciergeTopBarDropdownMenuClientUIFragment_account"
                }
              ],
              "storageKey": null
            },
            "action": "THROW",
            "path": "viewer.account"
          },
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "GetRolesResponse",
            "kind": "LinkedField",
            "name": "roles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "roles",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v0/*: any*/),
            "kind": "ScalarField",
            "name": "scopes",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConciergeTemplateRequestsCountBadgeClientUIQueryFragment_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "cdd7b864836cb66b284f332911c3158b";

export default node;
