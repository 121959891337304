import React, { ComponentProps, FC } from 'react';
import { Box, Icon, Separator, IconButton, PicnicCss } from '@attentive/picnic';
import { Transition, TransitionStatus } from 'react-transition-group';
import { PU_SUBDUED_MEDIUM_ICON_BUTTON_FLUSH_RIGHT_CSS } from './utils';

type FullscreenPanelProps = Partial<ComponentProps<typeof Transition>> & {
  open: boolean;
  onClose: () => void;
  css?: PicnicCss;
};

const defaultDuration = 300;

const transitionStyles: { [key in TransitionStatus]: PicnicCss } = {
  entering: { transform: 'translateX(0%)', boxShadow: '$shadow3' },
  entered: { transform: 'translateX(0%)', boxShadow: '$shadow3' },
  exiting: { transform: 'translateX(-100%)', boxShadow: '$shadow3' },
  exited: { transform: 'translateX(-100%)', boxShadow: 'none' },
  unmounted: { transform: 'translateX(-100%)', boxShadow: 'none' },
};

export const FullscreenPanel: FC<FullscreenPanelProps> = ({
  timeout = defaultDuration,
  open,
  onClose,
  css,
  children,
  ...rest
}) => {
  return (
    <Transition in={open} timeout={timeout} {...rest}>
      {(state) => (
        <Box
          css={{
            position: 'fixed',
            backgroundColor: '$bgDefault',
            top: 0,
            bottom: 0,
            right: 0,
            width: '100%',
            height: '100%',
            zIndex: '999',
            overflowY: 'scroll',
            transition: `transform ${timeout}ms ease-in-out`,
            ...transitionStyles[state],
            ...css,
          }}
        >
          <Box
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: '$space6',
            }}
          >
            <Icon name="Logo" />
            <IconButton
              iconName="X"
              onClick={onClose}
              description="Close Window"
              css={{ ...PU_SUBDUED_MEDIUM_ICON_BUTTON_FLUSH_RIGHT_CSS }}
            />
          </Box>
          <Separator />
          <Box css={{ marginTop: '$space3' }}>{children}</Box>
        </Box>
      )}
    </Transition>
  );
};
