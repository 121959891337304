/**
 * @generated SignedSource<<c76e7fc7fc74c2c8f8bd56d0be0b4df5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminAccountDropdownMenuClientUIQueryFragment_query$data = {
  readonly viewer: {
    readonly account: {
      readonly " $fragmentSpreads": FragmentRefs<"AccountNameClientUIFragment_account">;
    };
  };
  readonly " $fragmentType": "AdminAccountDropdownMenuClientUIQueryFragment_query";
};
export type AdminAccountDropdownMenuClientUIQueryFragment_query$key = {
  readonly " $data"?: AdminAccountDropdownMenuClientUIQueryFragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminAccountDropdownMenuClientUIQueryFragment_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminAccountDropdownMenuClientUIQueryFragment_query",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "concreteType": "Account",
              "kind": "LinkedField",
              "name": "account",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AccountNameClientUIFragment_account"
                }
              ],
              "storageKey": null
            },
            "action": "THROW",
            "path": "viewer.account"
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "viewer"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "2cd1868894fda6b1fb3613498b541c9e";

export default node;
