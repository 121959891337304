import { inSneakPreview } from '@attentive/sneak-preview-utils/sneak-preview';

import { DeveloperBannerVisibility, DEVELOPER_BANNER_STORAGE_KEY } from './DeveloperBanner';

export const persistDeveloperBannerState = (visibility: DeveloperBannerVisibility) => {
  sessionStorage.setItem(DEVELOPER_BANNER_STORAGE_KEY, visibility);
};

export const retrieveDeveloperBannerState = () => {
  return sessionStorage.getItem(DEVELOPER_BANNER_STORAGE_KEY) as DeveloperBannerVisibility;
};

export const canShowDeveloperBanner = (
  isAttentiveAccount: boolean,
  location: Location,
  displayMode: string
) => {
  const isInSneakPreview = inSneakPreview(location.pathname);

  if (displayMode === 'always') {
    return true;
  } else if (isAttentiveAccount && isInSneakPreview && displayMode === 'sneakPreviewOnly') {
    return true;
  }

  return false;
};
