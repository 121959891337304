/**
 * After a user has switched companies via company switcher,
 * we want to redirect them to the page they were on before.
 */

export const generateRedirectUrlFromSearchParams = (urlSearchParams: URLSearchParams) => {
  const redir = urlSearchParams.get('redir') || '/home';
  const hasRedir = urlSearchParams.has('redir');

  // after we redirect we don't want to keep the redir and companyId/cId params
  urlSearchParams.delete('redir');
  urlSearchParams.delete('companyId');
  urlSearchParams.delete('cId');

  // Specific change for sneak-previews.
  if (!hasRedir && location.href.includes('sneak-preview')) {
    const match = location.href.match(/(.*?\/){5}/);
    return `${match?.[0]}home?${urlSearchParams}`;
  }
  return `${redir}?${urlSearchParams}`;
};

export const createSignInRedirectUrlWithError = (errorMessage: string) => {
  const redir = `signin?error=${encodeURIComponent(errorMessage)}`;

  if (location.href.includes('sneak-preview')) {
    const match = location.href.match(/(.*?\/){5}/);
    return `${match?.[0]}${redir}`;
  }
  return `/${redir}`;
};
