import _AuthorizationDataProviderQueryFragment_query from "./__generated__/AuthorizationDataProviderQueryFragment_query.graphql";
import _AuthorizationDataProviderQuery from "./__generated__/AuthorizationDataProviderQuery.graphql";
import { fetchQuery, readInlineData, useRelayEnvironment } from 'react-relay';
import { isEqual } from '@attentive/nodash';
import { AppStore, rolesRWAtom, scopesRWAtom, useCurrentCompanyId } from '@attentive/acore-utils';
import { graphql } from '@attentive/data';
import { useRefreshTimer } from '../clocks/useRefreshTimer';
const AuthorizationDataProviderQuery = _AuthorizationDataProviderQuery;
const AuthorizationDataProviderQueryFragment = _AuthorizationDataProviderQueryFragment_query;
const REFRESH_INTERVAL_MS = 60 * 1000; // avoids recreating this on every render which will cayse the dependencies
// of useEffect Hook change on every render.

const stableEmptyArray = [];
export const fetchAuthAtoms = async _ref => {
  let {
    relayEnvironment,
    companyId
  } = _ref;
  const authDataQueryRef = await fetchQuery(relayEnvironment, AuthorizationDataProviderQuery, {
    companyId
  }).toPromise();

  if (!authDataQueryRef) {
    throw 'Auth query failed!';
  }

  return readInlineData(AuthorizationDataProviderQueryFragment, authDataQueryRef);
};
export const hydrateAuthAtoms = async authData => {
  var _authData$viewer, _authData$viewer$role, _authData$viewer2;

  const rolesAtom = AppStore.store.get(rolesRWAtom);
  const scopesAtom = AppStore.store.get(scopesRWAtom);
  const roles = (_authData$viewer = authData.viewer) === null || _authData$viewer === void 0 ? void 0 : (_authData$viewer$role = _authData$viewer.roles) === null || _authData$viewer$role === void 0 ? void 0 : _authData$viewer$role.roles;
  const scopes = ((_authData$viewer2 = authData.viewer) === null || _authData$viewer2 === void 0 ? void 0 : _authData$viewer2.scopes) || stableEmptyArray;
  const sortedRoles = [...roles].sort();
  const sortedScopes = [...scopes].sort();

  if (!isEqual(rolesAtom, sortedRoles)) {
    AppStore.store.set(rolesRWAtom, [...roles].sort());
  }

  if (!isEqual(scopesAtom, sortedScopes)) {
    AppStore.store.set(scopesRWAtom, [...scopes].sort());
  }
};
export const AuthorizationDataProvider = _ref2 => {
  let {
    children
  } = _ref2;
  const relayEnvironment = useRelayEnvironment();
  const companyId = useCurrentCompanyId();
  useRefreshTimer(async () => {
    hydrateAuthAtoms(await fetchAuthAtoms({
      relayEnvironment,
      companyId
    }));
  }, REFRESH_INTERVAL_MS);
  return children;
};