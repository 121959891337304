import React from 'react';

import { AppAnnouncementsContainer } from '../app-announcements';
import { AuthClock } from '../clocks';
import { AppBody } from './AppBody';
import { Outlet } from 'react-router-dom';
import { FidoChallengeChecker } from './FidoChallengeChecker';

export const AuthenticatedApp = () => {
  return (
    <AppBody>
      <AppAnnouncementsContainer />
      <AuthClock />
      <FidoChallengeChecker />
      <Outlet />
    </AppBody>
  );
};

export default AuthenticatedApp;
