import React, { ReactElement } from 'react';
import ReactDom from 'react-dom';

import { DEFAULT_THEME, usePicnicStyles } from '@attentive/picnic';
import {
  inSneakPreview,
  sneakPreviewCommitSha,
  sneakPreviewPathName,
} from '@attentive/sneak-preview-utils/sneak-preview';
import { Tracker } from '@attentive/acore-utils';

import AppError from '../pages/AppError';
import { useLocation } from 'react-router-dom';

export const historyBaseName = ({ pathname }: Location) => {
  const sneakPreview = inSneakPreview(pathname);
  if (sneakPreview) {
    const sneakPreviewPathSegment = sneakPreviewPathName(pathname);
    const commitSha = sneakPreviewCommitSha(pathname);
    return `/${sneakPreviewPathSegment}/${commitSha}`;
  }

  return '';
};

export function usePageTracker() {
  const location = useLocation();

  React.useEffect(() => {
    Tracker.trackPageView(location.pathname);
  }, [location.pathname, location.search]);
}

export const InitError: React.FC = ({ children }) => {
  usePicnicStyles(DEFAULT_THEME);

  return <AppError>{children as ReactElement}</AppError>;
};

export function renderError(errorMessage: JSX.Element) {
  ReactDom.render(<InitError>{errorMessage}</InitError>, document.getElementById('app'));
}
