import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import omit from 'lodash/omit';
import { useLocation } from 'react-router-dom';
import { isEqual } from '@attentive/nodash';

import { GraphqlIncompleteDataError, Permission } from '@attentive/data';
import {
  AppStore,
  clientUIFeatureFlagsAtom,
  CompanyFeatureFlagNames,
  CompanyFeatureFlags,
  getClientUIFeatureFlagFragment,
  getPermission,
  Tracker,
  TrackerEvents,
  updateCompanyFeatureFlags,
  useClientUIFeatureFlagFragment,
  usePermission,
} from '@attentive/acore-utils';

import { useAppBodyQueryData } from './AppBody';
import { getFeatureFlagTrackingProperties } from './AppFeatureFlagsTrackingUtils';
import { ClientUIAppBodyQuery$data } from './__generated__/ClientUIAppBodyQuery.graphql';

const RELAY_INTERNAL_PROPERTY_NAMES = [
  '__fragmentOwner',
  '__fragments',
  '__id',
  '__typename',
  ' $fragmentRefs',
  ' $refType',
] as const;

export const cleanFeatureFlagsFragment = (
  featureFlags: CompanyFeatureFlags | null
): CompanyFeatureFlags => {
  if (featureFlags === null) {
    throw new GraphqlIncompleteDataError('company is null');
  }

  // Remove any internal Relay object keys
  return omit(featureFlags, RELAY_INTERNAL_PROPERTY_NAMES);
};

// As long as a user has permission to modify feature flags, we allow them to
// override feature flags using URL search params, eg `?ENABLE_MY_FEATURE=true`.
const useFeatureFlagOverrides = (featureFlags: CompanyFeatureFlags) => {
  const { search } = useLocation();
  const canOverrideFeatureFlags = usePermission(Permission.FeatureFlagsManagementAccess);

  useEffect(() => {
    if (!canOverrideFeatureFlags) {
      return;
    }

    const overrides: Partial<Record<CompanyFeatureFlagNames, boolean>> = {};
    const searchParams = new URLSearchParams(search);
    for (const [key, value] of searchParams) {
      if (key in featureFlags) {
        overrides[key as CompanyFeatureFlagNames] = value === 'true';
      }
    }

    updateCompanyFeatureFlags(overrides);
  }, [search, featureFlags, canOverrideFeatureFlags]);
};

const getFeatureFlagOverrides = (featureFlags: CompanyFeatureFlags) => {
  const { search } = window.location;
  const canOverrideFeatureFlags = getPermission(Permission.FeatureFlagsManagementAccess);

  if (!canOverrideFeatureFlags) {
    return;
  }

  const overrides: Partial<Record<CompanyFeatureFlagNames, boolean>> = {};
  const searchParams = new URLSearchParams(search);
  for (const [key, value] of searchParams) {
    if (key in featureFlags) {
      overrides[key as CompanyFeatureFlagNames] = value === 'true';
    }
  }

  updateCompanyFeatureFlags(overrides);
};

export const AppFeatureFlags: React.FC = ({ children }) => {
  const queryData = useAppBodyQueryData();
  const featureFlagsFragData = useClientUIFeatureFlagFragment(queryData);
  const [featureFlags, setFeatureFlags] = useAtom(clientUIFeatureFlagsAtom);
  const [featureFlagsUpdated, setFeatureFlagsUpdated] = useState(false);

  React.useEffect(() => {
    const updatedFeatureFlags = cleanFeatureFlagsFragment(
      featureFlagsFragData.company as CompanyFeatureFlags
    );

    if (!isEqual(featureFlags.company, updatedFeatureFlags)) {
      setFeatureFlags({ company: updatedFeatureFlags });
    }
    setFeatureFlagsUpdated(true);
    // We only want to set feature flags and call onload once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useFeatureFlagOverrides(featureFlagsFragData.company as CompanyFeatureFlags);

  if (!featureFlagsUpdated) {
    return null;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export const hydrateAppFeatureFlags = (queryData: ClientUIAppBodyQuery$data) => {
  const featureFlagsFragData = getClientUIFeatureFlagFragment(queryData);
  const featureFlags = AppStore.store.get(clientUIFeatureFlagsAtom);

  const updatedFeatureFlags = cleanFeatureFlagsFragment(
    featureFlagsFragData.company as CompanyFeatureFlags
  );

  if (!isEqual(featureFlags.company, updatedFeatureFlags)) {
    AppStore.store.set(clientUIFeatureFlagsAtom, { company: updatedFeatureFlags });

    const flagEventTrackingProperties = getFeatureFlagTrackingProperties(updatedFeatureFlags);

    Tracker.track({
      eventName: TrackerEvents.TRACK_NAMED_FEATURE_FLAGS_ON_INIT,
      properties: flagEventTrackingProperties,
    });

    // Event handles serving a Pendo guide. Pendo controls how frequently a user sees the guide. Remove event when guide is retired.
    if (updatedFeatureFlags.ENABLE_AI_PRO_CLIENT_FACING_EXPERIENCE) {
      Tracker.track({
        eventName: TrackerEvents.AI_PRO_CLIENT_FACING_FLAG_ENABLED,
      });
    }
  }

  // TODO: Need to possibly get this back in.
  getFeatureFlagOverrides(featureFlagsFragData.company as CompanyFeatureFlags);
};
