import React from 'react';
import { Environment } from 'react-relay';
import { AuthSession } from '@attentive/acore-utils';
import { AuthFlowStrategy, relayEnvironment, ApiDataSource } from '@attentive/data';
import { loadPicnicFonts } from '@attentive/picnic/fonts';
import { Box, Button, Stack, Text } from '@attentive/picnic';
import {
  escapeFromSneakPreview,
  inSneakPreview,
  sneakPreviewCommitSha,
  redirectAppToUiDocsSneakPreview,
} from '@attentive/sneak-preview-utils';
import { getAuthClient, checkForUnavailableAuthenticatorError } from '@attentive/auth-core';

import { UserAuthStatus } from '../store/site-data';
import { renderError } from './app';
import { AbortInit } from './abort-init-error';
import { fetchPlatformManifest, fetchPlatformStatus } from './api';
import {
  EnvironmentVariables,
  EnvironmentVariableNames,
  parseEnvVarsFromUrl,
  appDocumentEnvVarsSchema,
  AppDocumentEnvVars,
  AppInitEnvVars,
  AppDocumentEnvVarsNames,
  AppInitEnvVarsNames,
  platformEnvVarsSchema,
} from '../store/env-vars';
import {
  parseRequestedCompanyId,
  readEnvVarsFromSessionStorage,
  storeEnvVarsInSessionStorage,
  storeSessionCompanyId,
} from './app-persistence';
import { createSignInRedirectUrlWithError } from './redirects';
import { logZodErrors } from './zod-utils';

export type ValidateAuthStatusResult =
  | {
      status: UserAuthStatus.authenticated;
      companyId: string;
      companyRestId: number;
      challengeString?: string;
    }
  | {
      status: UserAuthStatus.unauthenticated;
      companyId: null;
      companyRestId: null;
      challengeString?: string;
    };

const SneakPreviewError = () => (
  <Stack>
    <Text>This sneak preview commit SHA does not match the HTML commit SHA.</Text>
    <Box css={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
      <Button onClick={() => window.location.reload()}>Try again</Button>
      <Button
        onClick={() => {
          escapeFromSneakPreview(window.location as Location);
        }}
      >
        Leave Sneak Preview
      </Button>
    </Box>
  </Stack>
);

export async function initMockServiceWorker() {
  const { setupMockServiceWorker } = await import('../../mocks/browser');
  await setupMockServiceWorker();
}

export function checkUnsupportedBrowser() {
  if (/unsupported-browser/.test(document.documentElement.className)) {
    // Abort initialization so we don't try to start the app if this is an unsupported browser. No
    // need to render an error here as we already did in pure JS in unsupportedBrowser.hbs
    throw new AbortInit();
  }
}

export function parseAppDocumentEnvVars(jsonEnvVars?: string | null) {
  if (jsonEnvVars) {
    const envVars = JSON.parse(jsonEnvVars);

    // NOTE: change to `envVarSchema.strict().parse(envVars);` when all env vars are in the schema
    return appDocumentEnvVarsSchema.parse(envVars);
  }

  throw new Error('Env vars missing from <meta name="attentive-env-vars" value="..."> tag');
}

export async function initPicnic() {
  loadPicnicFonts();
}

export function initRelay(graphqlApiUrl: string, apiDataSource: ApiDataSource) {
  return relayEnvironment(graphqlApiUrl, {
    tokenCallback: () => AuthSession.retrieveTokenForGraph(),
    apiDataSource,
    onUnauthenticated: () => {
      const authClient = getAuthClient(AuthSession.retrieveStrategy());
      authClient.logout('Your session has expired. Please sign in again.');
    },
  });
}

export async function checkPlatformAvailability() {
  const platformStatus = await fetchPlatformStatus(window.location);

  if (!platformStatus.platformAvailable && platformStatus.errorMessage) {
    const errorMessageEl = (
      <span dangerouslySetInnerHTML={{ __html: platformStatus.errorMessage }} />
    );
    renderError(errorMessageEl);
    // Early return if the fetch to /platform-status.json returns an error message
    throw new AbortInit();
  }
}

export function parsePlatformEnvVars(
  envVarOverrides: Partial<EnvironmentVariables>
): Partial<EnvironmentVariables> {
  if (envVarOverrides) {
    const result = platformEnvVarsSchema.deepPartial().strict().safeParse(envVarOverrides);

    if (result.success) {
      return result.data;
    }

    console.group('Error with environment variables from platform-status.json');

    logZodErrors(result.error.format());

    console.warn('No overrides applied');

    console.groupEnd();
  }

  return {};
}

export const updateFaviconForSneakPreview = () => {
  // When we are in sneak preview mode, we update the favicon to cotton candy.
  const favicon = document.getElementById('favicon') as HTMLLinkElement;
  if (favicon) {
    favicon.href = '/favicon.cotton-candy.png';
  }
};

export async function initSneakPreview(location: Location) {
  return new Promise<string | null>((resolve) => {
    const isSneakPreview = inSneakPreview(location.pathname);

    if (!isSneakPreview) {
      resolve(null);
      return;
    }

    // Currently, client-ui's lambda does not support PR based sneak previews.
    // The ui-docs sneak preview does though, so we'll redirect to there and
    // let it handle the pr -> commit translation and redirect back to here.
    const PR_SNEAK_PREVIEW_RE = /^\/sneak-preview\/pr\/(\d+)(\/.*)?/;
    const prMatch = location.pathname.match(PR_SNEAK_PREVIEW_RE);
    if (prMatch) {
      const [, prId, path = ''] = prMatch;
      const redir = `${path}${location.search}`;
      redirectAppToUiDocsSneakPreview('client-ui', 'pr', prId, redir);
    } else {
      updateFaviconForSneakPreview();

      try {
        resolve(sneakPreviewCommitSha(location.pathname));
      } catch (e) {
        renderError(<SneakPreviewError />);
        throw new AbortInit();
      }
    }
  });
}

export const checkValidSneakPreview = (previewSha: string | null, commitSha: string) => {
  if (previewSha && previewSha !== commitSha) {
    renderError(<SneakPreviewError />);
    throw new AbortInit();
  }
};

export async function validateAuthStatus(relayEnv: Environment): Promise<ValidateAuthStatusResult> {
  // Get optional identifiers for the requested company for this session.
  const { requestedCompanyRestId, requestedCompanyExternalId } = parseRequestedCompanyId();

  try {
    // Validate the user has access to the requested company.
    const { verifySession } = getAuthClient(AuthSession.retrieveStrategy());
    const { companyId, companyRestId } = await verifySession(
      {
        companyRestId: requestedCompanyRestId,
        companyExternalId: requestedCompanyExternalId,
      },
      relayEnv
    );

    // Always update the session id to the resolved value.
    storeSessionCompanyId(companyRestId);

    return {
      status: UserAuthStatus.authenticated,
      companyId,
      companyRestId,
    };
  } catch (err) {
    if (checkForUnavailableAuthenticatorError(err)) {
      // if a user is trying to access a client company without an authenticator,
      // we need to clear the params and have them log in again
      const errorMessage =
        'You need an authenticator to access this company, please try logging in again.';
      const redirectUrl = createSignInRedirectUrlWithError(errorMessage);
      location.assign(redirectUrl);
    }
    /* User is not logged in but we still load the app anyways */
  }

  return { status: UserAuthStatus.unauthenticated, companyId: null, companyRestId: null };
}

export const establishPlatformEnvVars = async (location: Location) => {
  const platformManifestResponse = await fetchPlatformManifest(location);

  return parsePlatformEnvVars(platformManifestResponse);
};

export const establishSessionEnvVars = (location: Location, allowDevApiOverride: boolean) => {
  const queryStringEnvVars = parseEnvVarsFromUrl(location.search, {
    allowApiOverride: allowDevApiOverride,
  });

  const currentSessionEnvVars = readEnvVarsFromSessionStorage();

  const sessionEnvVars: Partial<EnvironmentVariables> = {
    ...currentSessionEnvVars,
    ...queryStringEnvVars,
  };

  storeEnvVarsInSessionStorage(sessionEnvVars);

  return sessionEnvVars;
};

export const establishEnvironmentVariables = (
  appDocumentEnvVars: AppDocumentEnvVars,
  appInitEnvVars: AppInitEnvVars,
  platformEnvVars: Partial<EnvironmentVariables>,
  sessionEnvVars: Partial<EnvironmentVariables>
): EnvironmentVariables => {
  return {
    ...appDocumentEnvVars,
    ...appInitEnvVars,
    ...platformEnvVars,
    ...sessionEnvVars,
  };
};

export const logEnvVarOverrides = (
  appDocumentEnvVars: AppDocumentEnvVars,
  appInitEnvVars: AppInitEnvVars,
  platformEnvVars: Partial<EnvironmentVariables>,
  sessionEnvVars: Partial<EnvironmentVariables>,
  finalEnvVars: EnvironmentVariables
) => {
  const hasPlatformEnvVarsOverrides = Object.keys(platformEnvVars).length > 0;
  const hasSessionEnvVarsOverrides = Object.keys(sessionEnvVars).length > 0;

  console.group('Environment variables');

  if (hasPlatformEnvVarsOverrides || hasSessionEnvVarsOverrides) {
    const overwrittenEnvVars = {
      ...platformEnvVars,
      ...sessionEnvVars,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tableInfo: any = {};
    Object.keys(overwrittenEnvVars).forEach((envVarName) => {
      tableInfo[envVarName] = {
        'App document': appDocumentEnvVars[envVarName as AppDocumentEnvVarsNames],
        'App init': appInitEnvVars[envVarName as AppInitEnvVarsNames],
        'Platform manifest': platformEnvVars[envVarName as EnvironmentVariableNames],
        Session: sessionEnvVars[envVarName as EnvironmentVariableNames],
        Final: finalEnvVars[envVarName as EnvironmentVariableNames],
      };
    });

    console.table(tableInfo);
  } else {
    console.log('No overrides');
  }

  console.groupEnd();
};

export const establishApiDataSource = async (apiDataSource: ApiDataSource) => {
  const persistedStrategy = AuthSession.retrieveStrategy();

  // If we were using the mock strategy but now we should be using the real one clear out the mock auth stuff
  if (persistedStrategy === AuthFlowStrategy.Mock && apiDataSource === ApiDataSource.Real) {
    AuthSession.clearStorage();
  }

  if (apiDataSource === ApiDataSource.Mock) {
    await initMockServiceWorker();
  }
};
