import { Tracker } from '@attentive/acore-utils';

//Source: https://github.com/attentive-mobile/code/blob/master/adserver/src/main/java/com/attentivemobile/adserver/model/Subscriber.java#L20
export const EMAIL_REGEX = /^[A-Z0-9.'_%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i;
export const validateEmail = (email: string) => EMAIL_REGEX.test(email);

export const setDocumentTitle = (title: string) => {
  document.title = `Attentive - ${title}`;
};

export const trackPageView = (pageURL: string) => {
  Tracker.trackPageView(pageURL);
};
