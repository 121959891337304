import React, { useMemo, useState } from 'react';
import { useAtomValue } from 'jotai';

import {
  Box,
  Switch,
  Table,
  TextInput,
  InputGroup,
  Icon,
  IconButton,
  Banner,
} from '@attentive/picnic';
import {
  clientUIFeatureFlagsAtom,
  CompanyFeatureFlagNames,
  CompanyFeatureFlags,
  updateCompanyFeatureFlags,
} from '@attentive/acore-utils';

type Writable<T> = { -readonly [K in keyof T]: T[K] };

export const FeatureFlagsTable: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const featureFlags = useAtomValue(clientUIFeatureFlagsAtom);

  const filteredFeatureFlags = useMemo(() => {
    const featureFlagNames = Object.keys(featureFlags.company) as CompanyFeatureFlagNames[];
    const filteredFeatureFlagsNames = featureFlagNames.filter((name) =>
      name.toLocaleLowerCase().includes(searchTerm)
    );
    return filteredFeatureFlagsNames.sort().reduce((obj, key) => {
      const value = featureFlags.company[key];
      obj[key] = value;
      return obj;
    }, {} as Writable<CompanyFeatureFlags>);
  }, [featureFlags.company, searchTerm]);

  const hasResults = useMemo(() => {
    return Object.keys(filteredFeatureFlags).length > 0;
  }, [filteredFeatureFlags]);

  function toggleFeatureFlag(name: CompanyFeatureFlagNames) {
    const existingFlagValue = featureFlags.company[name];

    updateCompanyFeatureFlags({ [name]: !existingFlagValue });
  }

  return (
    <Box>
      <InputGroup>
        <InputGroup.LeftElement>
          <Icon size="small" name="MagnifyingGlass" />
        </InputGroup.LeftElement>
        <TextInput
          size="small"
          placeholder="Search"
          name="MagnifyingGlass"
          aria-label="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.currentTarget.value.trim().toLocaleLowerCase())}
        />
        {searchTerm.length && (
          <InputGroup.RightElement>
            <IconButton
              size="small"
              iconName="X"
              description="Clear the search bar"
              onClick={() => setSearchTerm('')}
            />
          </InputGroup.RightElement>
        )}
      </InputGroup>

      {hasResults && (
        <Table columns={[2, 1]} css={{ mt: '$space4', width: '100%' }}>
          <Table.Header>
            <Table.HeaderRow>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell css={{ justifyContent: 'flex-end' }}>Enabled</Table.HeaderCell>
            </Table.HeaderRow>
          </Table.Header>
          <Table.Body>
            {Object.entries(filteredFeatureFlags).map(([name, value]) => {
              return (
                <Table.BodyRow key={name}>
                  <Table.BodyCell>{name}</Table.BodyCell>
                  <Table.BodyCell css={{ justifyContent: 'flex-end' }}>
                    <Switch.SwitchItem
                      aria-label={name}
                      checked={value}
                      value={name}
                      onChange={() => toggleFeatureFlag(name as CompanyFeatureFlagNames)}
                    />
                  </Table.BodyCell>
                </Table.BodyRow>
              );
            })}
          </Table.Body>
        </Table>
      )}
      {!hasResults && (
        <Banner variant="warning" css={{ mt: '$space4' }}>
          <div>No feature flags match search term "{searchTerm}"</div>

          <small>
            <b>NOTE</b>: only feature flags that the UI uses will show on this list!
          </small>
        </Banner>
      )}
    </Box>
  );
};
