import React from 'react';

import { PicnicCss } from '@attentive/picnic';
import { Config } from '@attentive/acore-utils';

import { Routes } from '../../../../../routes';
import { SidebarNavContainer, SidebarNavItem } from '../SidebarNav';

const referenceUiVisible = Config.get('referenceUiVisibleInSidebar');

interface EmergencySidebarNavProps {
  css?: PicnicCss;
  onClickItem?: (parameters?: { hasSubElements?: boolean }) => void;
}

export const EmergencySidebarNav: React.FC<EmergencySidebarNavProps> = ({
  css,
  onClickItem = () => {},
}) => {
  return (
    <SidebarNavContainer css={css}>
      <SidebarNavItem
        path={Routes.Campaigns}
        label="Campaigns"
        iconName="Megaphone"
        onClick={onClickItem}
      />
      <SidebarNavItem
        path={Routes.Segments}
        label="Segments"
        iconName="Segments"
        onClick={onClickItem}
      />
      {referenceUiVisible && (
        <SidebarNavItem
          path={Routes.Reference}
          label="Reference UI"
          iconName="EyeStrikethrough"
          onClick={onClickItem}
        />
      )}
    </SidebarNavContainer>
  );
};
