import { createEntryPoint } from '@attentive/data-router';

import Query from './__generated__/ConciergeSettingsPageQuery.graphql';

export const ConciergeSettingsPageEntryPoint = createEntryPoint({
  component: () => import('./ConciergeSettingsPage'),
  getQueries: ({ getCurrentCompanyId }) => ({
    query: {
      parameters: Query,
      variables: { companyId: getCurrentCompanyId() },
    },
  }),
});
