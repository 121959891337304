import React from 'react';
import { Navigate, Route, redirect } from 'react-router-dom';

import {
  NotFound,
  AppStore,
  getRoles,
  getPermission,
  RoutePermissions,
  getCompanyFeatureFlag,
} from '@attentive/acore-utils';
import { Permission, Role } from '@attentive/data';
import { routes as ReferenceRoutes } from '@attentive/reference-ui';
import { routes as CRMRoutes } from '@attentive/crm-ui';
import { routes as HomeRoutes } from '@attentive/home-ui';
import { routes as SegmentationRoutes } from '@attentive/segmentation-ui';
import { routes as SettingsRoutes } from '@attentive/settings-ui';
import { routes as MleRoutes, AIProTrialRoutes } from '@attentive/ml-ui';
import { MFELayoutRoute } from '../components/MFELayoutRoute';
import { AdminRoutes, Routes, ConciergeRoutes, OnboardingRoutes } from './routes-enum';
import PlatformUnavailable from '../components/../pages/PlatformUnavailable';
import ClientLayout from '../components/layouts/client-layout/ClientLayout';
import AdminLayout from '../components/layouts/admin-layout/AdminLayout';
import OnboardingLayout from '../components/layouts/OnboardingLayout';
import EmergencyClientLayout from '../components/layouts/client-layout/EmergencyClientLayout';
import EmergencyAdminLayout from '../components/layouts/admin-layout/EmergencyAdminLayout';
import ConciergeLayout from '../components/layouts/concierge-layout/ConciergeLayout';
import { ConciergeAgentLayout } from '../components/layouts/ConciergeAgentLayout';
import { hydratedOnboardingAtom } from '../components/onboarding/useOnboarding';
import { getEmergencyMode } from '../store/env-vars';
import { Fido2Challenge } from '../pages/Fido2Challenge';
import { AdminFido2Challenge } from '../pages/AdminFido2Challenge';

const conciergeAgentRoutes = () => {
  return (
    <Route element={<ConciergeAgentLayout />}>
      <Route path={`${ConciergeRoutes.Concierge}/*`} element={<ConciergeLayout />}>
        <Route element={<MFELayoutRoute />}>
          <Route
            id="concierge-ui"
            Component={React.lazy(() => import('@attentive/concierge-ui'))}
            handle={{ title: 'Concierge', appName: 'concierge-ui' }}
            path="*"
          />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to={ConciergeRoutes.Concierge} replace />} />
    </Route>
  );
};

const clientLayoutRoutes = () => {
  const isAttentiveUser = getRoles().has(Role.RoleAttentiveAccount);

  const showPlatformUnavailable = getCompanyFeatureFlag('SHOW_PLATFORM_UNAVAILABLE_PAGE_CLIENT_UI');
  const conversationsRouteEnabled = getCompanyFeatureFlag('CONVERSATIONS');
  const integrationsRouteEnabled = getCompanyFeatureFlag('UI_INTEGRATIONS');
  const brandKitRouteEnabled = getCompanyFeatureFlag('ENABLE_BRAND_KIT');
  const isEmailEnabled = getCompanyFeatureFlag('ENABLE_TRIGGERED_EMAIL');
  const isAIPro60DayTrialEnabled = getCompanyFeatureFlag('ENABLE_AI_PRO_SIXTY_TRIAL_SIGNUP');

  if (showPlatformUnavailable) {
    return <Route element={<PlatformUnavailable />} />;
  }

  return (
    <Route element={<MFELayoutRoute />}>
      <Route id="home-ui" path={`${Routes.Home}/*`} handle={{ appName: 'home-ui', title: 'Home' }}>
        {HomeRoutes}
      </Route>
      <Route
        id="subscribers-ui"
        Component={React.lazy(() => import('@attentive/subscribers-ui'))}
        handle={{ appName: 'subscribers-ui', title: 'Sign-up Units' }}
        path={`${Routes.SignUpUnitsRoot}/*`}
      />
      <Route
        id="crm-ui"
        handle={{ appName: 'crm-ui', title: 'Subscribers' }}
        path={`${Routes.Subscribers}/*`}
      >
        {CRMRoutes}
      </Route>
      <Route
        id="journeys-ui"
        Component={React.lazy(() => import('@attentive/journeys-ui'))}
        handle={{ appName: 'journeys-ui', title: 'Journeys' }}
        path={`${Routes.Journeys}/*`}
      />
      <Route
        id="messages-ui"
        Component={React.lazy(() => import('@attentive/messages-ui'))}
        handle={{ appName: 'messages-ui', title: 'Campaigns', availableInAnEmergency: true }}
        path={`${Routes.Campaigns}/*`}
      />
      <Route
        id="segmentation-ui"
        handle={{ availableInAnEmergency: true, appName: 'segmentation-ui', title: 'Segments' }}
        path={`${Routes.Segments}/*`}
      >
        {SegmentationRoutes}
      </Route>
      <Route
        id="analytics-ui-reports"
        Component={React.lazy(() => import('@attentive/analytics-ui'))}
        handle={{ appName: 'analytics-ui', title: 'Reports' }}
        path={`${Routes.Reports}/*`}
      />
      <Route
        id="analytics-ui"
        Component={React.lazy(() => import('@attentive/analytics-ui'))}
        path={`${Routes.Analytics}/*`}
        handle={{ appName: 'analytics-ui', title: 'Analytics' }}
      />
      <Route
        id="settings-ui"
        path={`${Routes.Settings}/*`}
        handle={{ appName: 'settings-ui', title: 'Settings' }}
      >
        {SettingsRoutes}
      </Route>
      <Route
        id="reference-ui"
        path={`${Routes.Reference}/*`}
        handle={{ appName: 'reference-ui', title: 'Reference', availableInAnEmergency: true }}
      >
        {ReferenceRoutes}
      </Route>
      <Route
        id="offers-ui"
        Component={React.lazy(() => import('@attentive/offers-ui'))}
        handle={{ appName: 'offers-ui', title: 'Offers' }}
        path={`${Routes.Offers}/*`}
      />
      <Route
        id="tactical-ui"
        Component={React.lazy(() => import('@attentive/tactical-ui'))}
        handle={{ appName: 'tactical-ui', title: 'Tactical' }}
        path={`${Routes.Tactical}/*`}
      />
      <Route
        id="tag-ui"
        Component={React.lazy(() => import('@attentive/tag-ui'))}
        handle={{ appName: 'tag-ui', title: 'Tag' }}
        path={`${Routes.Tag}/*`}
      />
      <Route
        id="ml-ui"
        path={`${Routes.JourneysAI}/*`}
        handle={{ appName: 'ml-ui', title: 'AI Journeys' }}
      >
        {MleRoutes}
      </Route>

      {/* Routes that require Permissions or Feature Flags */}
      <Route element={<RoutePermissions permission={() => conversationsRouteEnabled} />}>
        <Route
          id="conversations-ui"
          Component={React.lazy(() => import('@attentive/conversations-ui'))}
          handle={{ appName: 'conversations-ui', title: 'Conversations' }}
          path={`${Routes.Conversations}/*`}
        />
      </Route>
      <Route element={<RoutePermissions permission={() => integrationsRouteEnabled} />}>
        <Route
          id="integrations-ui"
          Component={React.lazy(() => import('@attentive/integrations-ui'))}
          path={`${Routes.Integrations}/*`}
          handle={{ appName: 'integrations-ui', title: 'Integrations' }}
        />
      </Route>
      <Route element={<RoutePermissions permission={() => brandKitRouteEnabled} />}>
        <Route
          id="brand-kit-ui"
          Component={React.lazy(() => import('@attentive/brand-kit-ui'))}
          handle={{ appName: 'brand-kit-ui', title: 'Brand Kit' }}
          path={`${Routes.BrandKit}/*`}
        />
      </Route>
      <Route element={<RoutePermissions permission={() => isEmailEnabled} />}>
        <Route
          id="templates-ui"
          Component={React.lazy(() => import('@attentive/templates-ui'))}
          handle={{ appName: 'templates-ui', title: 'Email Templates' }}
          path={`${Routes.Templates}/*`}
        />
      </Route>
      <Route element={<RoutePermissions permission={() => isAttentiveUser} />}>
        <Route
          id="email-editor-test-harness-ui"
          Component={React.lazy(() => import('@attentive/email-editor-test-harness-ui'))}
          handle={{ appName: 'email-editor-test-harness-ui', title: 'Email Editor Test Harness' }}
          path={`${Routes.EmailEditorTestHarness}/*`}
        />
      </Route>
      <Route element={<RoutePermissions permission={() => isAIPro60DayTrialEnabled} />}>
        <Route
          id="ai-pro-trial-60"
          path={Routes.AIPro60DayTrial}
          handle={{ appName: 'ml-ui', title: 'AI Pro 60 Day Trial' }}
        >
          {AIProTrialRoutes}
        </Route>
      </Route>
      {/* Redirects and catch-all */}
      <Route
        path="/messages/:messageId/overview-v2"
        element={<Navigate replace to="/campaigns/:messageId/overview" />}
      />
      <Route path="/" element={<Navigate replace to={Routes.Home} />} />
      <Route path={`${Routes.Dashboard}/*`} element={<Navigate replace to={Routes.Home} />} />
      <Route path={Routes.NotFound} element={<NotFound />} />
      <Route path="*" element={<Navigate replace to={Routes.NotFound} />} />
    </Route>
  );
};

const adminLayoutRoutes = () => {
  return (
    <Route
      loader={({ params }) => {
        const redir = `admin/${params['*']}`;
        const hasAdminAccess = getRoles().has(Role.RoleAdminAccess);
        const isAttentiveUser = getRoles().has(Role.RoleAttentiveAccount);
        const requireWebAuthnForAdminAccess = getCompanyFeatureFlag(
          'REQUIRE_WEBAUTHN_FOR_ADMIN_ACCESS'
        );

        if (requireWebAuthnForAdminAccess && !hasAdminAccess && isAttentiveUser) {
          return redirect(`/admin-challenge?redir=${encodeURIComponent(redir)}`);
        }

        return null;
      }}
      element={<RoutePermissions permission={Permission.AdminUIAccess} />}
    >
      <Route element={<MFELayoutRoute />}>
        <Route
          id="admin-center-ui"
          Component={React.lazy(() => import('@attentive/admin-center-ui'))}
          handle={{
            appName: 'admin-center-ui',
            title: 'Admin Center',
            availableInAnEmergency: true,
          }}
          path="*"
        />
      </Route>
    </Route>
  );
};

const onboardingLayoutRoutes = () => {
  return (
    <Route element={<OnboardingLayout />}>
      <Route element={<MFELayoutRoute />}>
        <Route
          id="onboarding-ui"
          Component={React.lazy(() => import('@attentive/onboarding-ui'))}
          handle={{ title: 'Onboarding', appName: 'onboarding-ui' }}
          path={`${OnboardingRoutes.Onboarding}/*`}
        />
      </Route>
    </Route>
  );
};

export const appRoutesRoutes = () => {
  const inEmergencyMode = getEmergencyMode();

  const hasConciergeAccess = getPermission(Permission.ConciergeAccess);

  const isConciergeAgent = getRoles().has(Role.RoleConcierge);

  const { redirectToOnboarding, onboardingPath, hasOnboardingAccess, blockOnboardingCompanyForm } =
    AppStore.store.get(hydratedOnboardingAtom);

  if (inEmergencyMode) {
    return (
      <>
        <Route path={`${AdminRoutes.AdminCenter}/*`} element={<EmergencyAdminLayout />}>
          {adminLayoutRoutes()}
        </Route>
        <Route element={<EmergencyClientLayout />}>{clientLayoutRoutes()}</Route>
      </>
    );
  }

  // Concierge Agents only have access to /concierge - all other routes redirect to /concierge
  if (isConciergeAgent) {
    return conciergeAgentRoutes();
  }

  // If company in onboarding phase, clients should only be able to access the onboarding page
  if (redirectToOnboarding) {
    return (
      <>
        {onboardingLayoutRoutes()}
        <Route element={<RoutePermissions permission={() => Boolean(onboardingPath)} />}>
          <Route path="*" element={<Navigate replace to={onboardingPath} />} />
        </Route>
      </>
    );
  }

  return (
    <>
      <Route element={<RoutePermissions permission={() => hasConciergeAccess} />}>
        <Route element={<ConciergeLayout />}>
          <Route element={<MFELayoutRoute />}>
            <Route
              path={`${ConciergeRoutes.Concierge}/*`}
              id="concierge-ui"
              handle={{ title: 'Concierge', appName: 'concierge-ui' }}
              Component={React.lazy(() => import('@attentive/concierge-ui'))}
            />
          </Route>
        </Route>
      </Route>
      <Route path={`${AdminRoutes.AdminCenter}/*`} element={<AdminLayout />}>
        {adminLayoutRoutes()}
      </Route>
      <Route element={<RoutePermissions permission={() => hasOnboardingAccess} />}>
        <Route
          path="/onboarding/*"
          element={
            <>
              {onboardingLayoutRoutes()}
              <Route element={<RoutePermissions permission={() => blockOnboardingCompanyForm} />}>
                <Route path="*" element={<Navigate replace to={onboardingPath} />} />
              </Route>
            </>
          }
        />
      </Route>
      <Route path="/admin-challenge/*" element={<AdminFido2Challenge />} />
      <Route path="/challenge" element={<Fido2Challenge />} />
      <Route element={<ClientLayout />}>{clientLayoutRoutes()}</Route>
    </>
  );
};
