/**
 * @generated SignedSource<<abf09204ce4f61b0be3c3d4424a4d1db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationsMenuContentClientUIFragment_notificationConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"NotificationsMenuRowClientUIFragment_notification">;
    };
  }>;
  readonly " $fragmentType": "NotificationsMenuContentClientUIFragment_notificationConnection";
};
export type NotificationsMenuContentClientUIFragment_notificationConnection$key = {
  readonly " $data"?: NotificationsMenuContentClientUIFragment_notificationConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationsMenuContentClientUIFragment_notificationConnection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationsMenuContentClientUIFragment_notificationConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "NotificationEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Notification",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "NotificationsMenuRowClientUIFragment_notification"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "NotificationConnection",
  "abstractKey": null
};

(node as any).hash = "3ee2286e2c5b92f28c307deba8884930";

export default node;
