import { atom } from 'jotai';

export type CommitDetails = {
  message: string;
  commitSha: string;
  timestamp: string;
};

export const commitDetailsRWAtom = atom<CommitDetails>({
  message: '',
  commitSha: '',
  timestamp: '',
});
export const commitDetailsAtom = atom<CommitDetails>((get) => get(commitDetailsRWAtom));
