import React from 'react';
import * as Accordion from '@radix-ui/react-accordion';

import { keyframes, styled, PicnicCss, Icon } from '@attentive/picnic';

const StyledList = styled('ul', {
  listStyleOverride: 'unstyled',
});
const StyledListItem = styled('li', {});

const Root: React.FC<{
  defaultValue: string;
  css?: PicnicCss;
}> = (props) => {
  return (
    <Accordion.Root asChild type="single" collapsible={true}>
      <StyledList {...props} />
    </Accordion.Root>
  );
};

const Item: React.FC<{
  value: string;
  disabled?: boolean;
  css?: PicnicCss;
}> = (props) => {
  const { value, disabled, ...rest } = props;
  return (
    <Accordion.Item asChild value={value} disabled={disabled}>
      <StyledListItem {...rest} />
    </Accordion.Item>
  );
};

const Header: React.FC = (props) => {
  return <Accordion.Header {...props} asChild />;
};

const Trigger = Accordion.Trigger;

const open = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-accordion-content-height)' },
});

const close = keyframes({
  from: { height: 'var(--radix-accordion-content-height)' },
  to: { height: 0 },
});

const Content: React.FC = (props) => {
  return (
    <Accordion.Content asChild>
      <StyledList
        {...props}
        css={{
          overflow: 'hidden',
          '&[data-state="open"]': { animation: `${open} 150ms ease-out` },
          '&[data-state="closed"]': { animation: `${close} 150ms ease-out` },
        }}
      />
    </Accordion.Content>
  );
};

const ChevronIcon: React.VFC = () => {
  return (
    <Icon
      name="ChevronRight"
      size="extraSmall"
      css={{
        '[data-state=open] &': { transform: 'rotate(90deg)' },
        transition: 'transform 150ms ease-out',
      }}
    />
  );
};

export const SidebarNavAccordion = {
  Root,
  Item,
  Header,
  Trigger,
  Content,
  Icon: ChevronIcon,
};
