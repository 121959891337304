import React from 'react';
import { object, string } from 'yup';

import { Form, Box } from '@attentive/picnic';

import { sha256 } from './hash';

const passwordSha = '0ead2060b65992dca4769af601a1b3a35ef38cfad2c2c465bb160ea764157c5d';

const formSchema = object({
  password: string()
    .required()
    .test('password', '${path} is not a valid password', async (value) => {
      if (value) {
        return (await sha256(value)) === passwordSha;
      }
      return false;
    })
    .label('Password'),
});

interface PasswordGateProps {
  onCorrectPassword: () => void;
}

export const PasswordGate: React.FC<PasswordGateProps> = ({ onCorrectPassword }) => {
  return (
    <Form
      initialValues={{ password: '' }}
      validationSchema={formSchema}
      onSubmit={onCorrectPassword}
    >
      <Form.FormField name="password" css={{ mb: '$space8' }}>
        <Form.Label>Password</Form.Label>
        <Form.TextInput type="password" />
        <Form.HelperText>Enter the right password to unlock mock login</Form.HelperText>
      </Form.FormField>
      <Box css={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Form.SubmitButton>Continue</Form.SubmitButton>
      </Box>
    </Form>
  );
};
