import React from 'react';

import { Config } from '@attentive/acore-utils';

import { GRID_CONTENT_ELEMENT_ID } from '../../../constants';
import { AdminTopBar } from './topbar/AdminTopBar';
import { AdminSidebar } from './sidebar/AdminSidebar';
import { DeveloperBanner } from '../../DeveloperBanner';
import {
  AdminContentArea,
  AdminLayoutGrid,
  AdminNotificationsArea,
  AdminSidebarArea,
  AdminTopbarArea,
} from './styledComponents';

import { SuspendedOutlet } from '../SuspendedOutlet';

const AdminLayout: React.FC = () => {
  return (
    <AdminLayoutGrid>
      <AdminNotificationsArea>
        <DeveloperBanner localDev={Config.get('isLocalDev')} />
      </AdminNotificationsArea>
      <AdminSidebarArea>
        <AdminSidebar />
      </AdminSidebarArea>
      <AdminTopbarArea>
        <AdminTopBar />
      </AdminTopbarArea>
      <AdminContentArea id={GRID_CONTENT_ELEMENT_ID}>
        <SuspendedOutlet />
      </AdminContentArea>
    </AdminLayoutGrid>
  );
};

export default AdminLayout;
