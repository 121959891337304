import React from 'react';

import { Box, Separator } from '@attentive/picnic';

import { buildZendeskSearchUrl, ZendeskSearchUrlType, ZendeskSearchData } from '../api';

import { ZendeskArticleList } from './ArticleList';
import { ViewAllResultsButton } from './ViewAllResultsButtons';
import { SearchResultsPlaceholder } from './SearchResultsPlaceholder';

interface ZendeskSearchResultsProps {
  data: ZendeskSearchData;
  query: string;
  isLoading: boolean;
  isError: boolean;
  isQueryTooShort: boolean;
}

// The Zendesk search API we're using doesn't do a great fuzzy search,
// so we encourage the user to keep typing even if there are no results, up to a certain char limit.
const MIN_QUERY_CHARS_FOR_NO_RESULTS = 7;

export const ZendeskSearchResults: React.VFC<ZendeskSearchResultsProps> = ({
  data: {
    articles,
    pageInfo: { pageCount },
  },
  query,
  isLoading,
  isError,
  isQueryTooShort,
}) => {
  const helpCenterUrl = buildZendeskSearchUrl(ZendeskSearchUrlType.UI, {
    query,
  });

  if (isError) {
    return (
      <SearchResultsPlaceholder
        iconName="CircleExclamation"
        color="warning"
        helpCenterUrl={helpCenterUrl}
      >
        Oops, something went wrong.
      </SearchResultsPlaceholder>
    );
  }

  if (isLoading) {
    return (
      <SearchResultsPlaceholder iconName="MagnifyingGlass" color="disabled">
        Searching...
      </SearchResultsPlaceholder>
    );
  }

  if (isQueryTooShort || (articles.length === 0 && query.length < MIN_QUERY_CHARS_FOR_NO_RESULTS)) {
    return (
      <SearchResultsPlaceholder iconName="MagnifyingGlass" color="disabled">
        Keep typing...
      </SearchResultsPlaceholder>
    );
  }

  if (articles.length === 0) {
    return (
      <SearchResultsPlaceholder
        iconName="FrownOutline"
        color="disabled"
        helpCenterUrl={helpCenterUrl}
      >
        Sorry, no results were found.
      </SearchResultsPlaceholder>
    );
  }

  return (
    <>
      <ZendeskArticleList articles={articles} />
      {pageCount > 1 && (
        <>
          <Separator />
          <Box css={{ p: '$space4' }}>
            <ViewAllResultsButton helpCenterUrl={helpCenterUrl} />
          </Box>
        </>
      )}
    </>
  );
};
