import React from 'react';

import { Box, Select } from '@attentive/picnic';
import { AuthFlowStrategy } from '@attentive/data';
import { Persona } from '@attentive/mock-data';
import { AuthSession } from '@attentive/acore-utils';
import { parseRedirectPath } from '@attentive/auth-core';

const personas = [
  { value: Persona.Remy.id, label: `${Persona.Remy.firstName} ${Persona.Remy.lastName}` },
  { value: Persona.Carl.id, label: `${Persona.Carl.firstName} ${Persona.Carl.lastName}` },
  { value: Persona.Sally.id, label: `${Persona.Sally.firstName} ${Persona.Sally.lastName}` },
];

export const MockAccountForm = () => {
  const handleLogin = (id: string) => {
    AuthSession.persistStrategy(AuthFlowStrategy.Mock);
    AuthSession.persistToken(AuthFlowStrategy.Mock, id);

    const redirectPath = parseRedirectPath(location);
    window.location.assign(redirectPath);
  };

  return (
    <Box>
      <Select onChange={handleLogin} placeholder="Pick your faux account">
        {personas.map(({ value, label }) => {
          return (
            <Select.Item key={value} value={value}>
              {label}
            </Select.Item>
          );
        })}
      </Select>
    </Box>
  );
};
