import React, { forwardRef, PropsWithChildren } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { PicnicCss, styled, VariantProps } from '@attentive/picnic';

interface SidebarLinkProps extends VariantProps<typeof SidebarRouterLink> {
  path: string;
  css?: PicnicCss;
  onClick?: React.MouseEventHandler;
}

export const SidebarLinkStyle = {
  // No vertical padding allows us to control the height better and align with our medium Buttons, Inputs, etc.
  minHeight: '$size9',
  padding: '$space0 $space4',
  border: '$borderWidths$borderWidth2 solid transparent',
  transition: 'background-color 200ms ease-in-out',
  color: '$textDefault',
  lineHeight: '$lineHeight1',
  textDecoration: 'none',
  '&:hover': {
    backgroundColor: '$bgBrand',
  },

  '&:focus-visible': {
    outline: 'none',
    borderColor: '$borderFocus',
  },

  variants: {
    variant: {
      default: {},
      selected: {
        fontWeight: '$bold',
      },
    },
  },
};

const SidebarRouterLink = styled(ReactRouterLink, SidebarLinkStyle);
const SidebarExternalLink = styled('a', SidebarLinkStyle);

export const SidebarButton = styled('button', {
  ...SidebarLinkStyle,
  width: '100%',
  backgroundColor: 'transparent',
  outline: 'none',
  cursor: 'pointer',
});

export const SidebarLink = forwardRef<HTMLAnchorElement, PropsWithChildren<SidebarLinkProps>>(
  ({ path, ...rest }, ref) => {
    const isExternal = new URL(path, location.origin).origin !== location.origin;

    if (isExternal) {
      return (
        <SidebarExternalLink
          href={path}
          target="_blank"
          rel="noopener noreferrer"
          ref={ref}
          {...rest}
        />
      );
    }

    return <SidebarRouterLink to={path} ref={ref} {...rest} />;
  }
);
