import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUserIsAuthenticated } from '../store/site-data';

export const REDIRECT_PATH = '/signin';

export const PrivateRoute: React.FC = () => {
  const isAuthenticated = useUserIsAuthenticated();

  if (isAuthenticated) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <Outlet />;
  }

  return (
    <Navigate
      to={`${REDIRECT_PATH}?redir=${encodeURIComponent(location.pathname + location.search)}`}
    />
  );
};
