import { createEntryPoint } from '@attentive/data-router';
import Query from './__generated__/UploadDetail_SettingsUI_Query.graphql';

export const UploadDetailEntryPoint = createEntryPoint({
  component: () => import('./UploadDetail'),
  getQueries: ({ params }) => ({
    query: {
      parameters: Query,
      variables: { jobId: params.id || '' },
    },
  }),
});
