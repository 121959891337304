import React from 'react';

import { Box } from '@attentive/picnic';

import { LogoEasterEgg, LogoEasterEggVariant } from '../../../../common/LogoEasterEgg';
import { SidebarRegion } from '../SidebarRegion';
import { EmergencySidebarNav } from './EmergencySidebarNav';

export const EmergencySidebar: React.VFC = () => {
  return (
    <SidebarRegion>
      <EmergencySidebarNav css={{ pb: '$space4' }} />
      <Box css={{ flex: 1 }} />
      <Box css={{ p: '$space0 $space4 $space8 $space6' }}>
        <LogoEasterEgg variant={LogoEasterEggVariant.WORD} />
      </Box>
    </SidebarRegion>
  );
};
