import React from 'react';
import { Navigate, Outlet, Route } from 'react-router-dom';

import { Text } from '@attentive/picnic';

import { MockSignin } from '../pages/MockSignin';
import { PrivateRoute } from '../components/PrivateRoute';
import AuthenticatedApp from '../components/authenticated-app/AuthenticatedApp';
import { SignIn } from '../pages/SignIn';
import { MultiFactorAuth } from '@attentive/auth-ui';
import { CreatePassword } from '../pages/CreatePassword';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import { apiDataSourceAtom, AppStore } from '@attentive/acore-utils';
import { ApiDataSource, AppErrorCodes, AppStatus } from '@attentive/data';
import CompanyAccessRevoked from '../pages/CompanyAccessRevoked';
import { AuthenticatorUnavailable } from '../pages/AuthenticatorUnavailable';
import { useAppErrorCode, useAppErrorMessage, useAppStatus } from '../store/site-data';
import AppError from '../pages/AppError';
import { CompanyTransition } from '../components/company-transition/CompanyTransition';
import { AppTeardown } from '../components/app-teardown';
import { appRoutesRoutes } from './app-routes';

const AppStatusRouteCheck = () => {
  const appStatus = useAppStatus();
  const appErrorCode = useAppErrorCode();
  const errorMessage = useAppErrorMessage();

  if (appStatus === AppStatus.ERROR) {
    if (appErrorCode === AppErrorCodes.COMPANY_ACCESS_REVOKED) {
      return <CompanyAccessRevoked />;
    } else if (appErrorCode === AppErrorCodes.AUTHENTICATOR_NOT_REGISTERED) {
      return <AuthenticatorUnavailable />;
    }

    return (
      <AppError>
        <Text>
          Our app encountered an error. We’re working on a solution &mdash; please check back soon!
        </Text>
        <Text>{errorMessage}</Text>
      </AppError>
    );
  } else if (appStatus === AppStatus.COMPANY_TRANSITION) {
    return <CompanyTransition />;
  } else if (appStatus === AppStatus.TERMINATED) {
    return <AppTeardown />;
  }

  return <Outlet />;
};

export const appRoutes = () => {
  const apiDataSource = AppStore.store.get(apiDataSourceAtom);

  if (apiDataSource === ApiDataSource.Mock) {
    return (
      <>
        <Route path="/signin" element={<MockSignin />} />
        <Route element={<PrivateRoute />}>
          <Route path="*" element={<AuthenticatedApp />} />
        </Route>
      </>
    );
  }

  return (
    <Route element={<AppStatusRouteCheck />}>
      <Route path="/login" element={<Navigate to="/signin" replace />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/2fa/*" element={<MultiFactorAuth />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/create-password" element={<CreatePassword />} />
      <Route element={<PrivateRoute />}>
        <Route element={<AuthenticatedApp />}>{appRoutesRoutes()}</Route>
      </Route>
    </Route>
  );
};
