import { styled, ResponsivePageGutters } from '@attentive/picnic';

export const TopBarContainer = styled(ResponsivePageGutters, {
  width: '100%',
  display: 'flex',
  flexShrink: 0,
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '$borderWidths$borderWidth1 solid $borderDefault',
});
