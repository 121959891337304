import { createEntryPoint } from '@attentive/data-router';

import { RESULTS_PER_PAGE } from './usePaginatedProductFeeds';

import Query from './__generated__/ProductFeedsList_Products_Query.graphql';

export const ProductFeedsListPageEntryPoint = createEntryPoint({
  component: () => import('./ProductFeedsList'),
  getQueries: ({ getCurrentCompanyId }) => ({
    query: {
      parameters: Query,
      variables: { companyId: getCurrentCompanyId(), first: RESULTS_PER_PAGE },
    },
  }),
});
