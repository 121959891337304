import { createEntryPoint } from '@attentive/data-router';

import Query from './__generated__/UserAccountManagementQuery.graphql';
import { ROW_COUNT } from './queryVariables';

export const UserAccountManagementPageEntryPoint = createEntryPoint({
  component: () => import('./UserAccountManagementPage'),
  getQueries: ({ getCurrentCompanyId }) => ({
    query: {
      parameters: Query,
      variables: { companyId: getCurrentCompanyId(), first: ROW_COUNT },
    },
  }),
});
