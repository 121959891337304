import React from 'react';

import { Button, Text } from '@attentive/picnic';

export const StandardMessage = () => {
  return (
    <>
      <Text css={{ marginBottom: '$space8' }}>We're working on a solution!</Text>
      <Button
        onClick={() => {
          window.location.reload();
        }}
      >
        Try again
      </Button>
    </>
  );
};
