import { createEntryPoint } from '@attentive/data-router';

import Query from './__generated__/AIJourneySettingsPersonalizedMessagesPageQuery.graphql';

export const AIJourneySettingsPersonalizedMessagesPageEntryPoint = createEntryPoint({
  component: () => import('./AIJourneySettingsPersonalizedMessagesPage'),
  getQueries: ({ getCurrentCompanyId }) => ({
    query: { parameters: Query, variables: { companyId: getCurrentCompanyId() } },
  }),
});
