/**
 * @generated SignedSource<<bfd01bc106737f0eada66dea7d2db594>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OnboardingLayoutClientUIFragment_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"OnboardingTopBarClientUIFragment_query">;
  readonly " $fragmentType": "OnboardingLayoutClientUIFragment_query";
};
export type OnboardingLayoutClientUIFragment_query$key = {
  readonly " $data"?: OnboardingLayoutClientUIFragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"OnboardingLayoutClientUIFragment_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OnboardingLayoutClientUIFragment_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OnboardingTopBarClientUIFragment_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "412bfb488a7808a1cc225e722c0ff6b1";

export default node;
