import { useMediaQuery } from '@react-hook/media-query';

import { media } from '@attentive/picnic';

import { TopBarVariant } from './topbar';
import { MarketingEventBannerProps } from './marketing-event-banner';
import { useFullScreenModeAtom } from '@attentive/acore-utils';

export const useTopBarVariant = () => {
  const atBp2 = useMediaQuery(media.bp2);

  if (atBp2) {
    return TopBarVariant.FULL_TEXT;
  }

  return TopBarVariant.HAMBURGER_MENU;
};

export const useHasSidebarArea = () => {
  const fullscreenMode = useFullScreenModeAtom();
  const atBp2 = useMediaQuery(media.bp2);

  if (fullscreenMode) {
    return false;
  }

  return Boolean(atBp2);
};

export const useHasTopbarArea = () => {
  const fullscreenMode = useFullScreenModeAtom();

  if (fullscreenMode) {
    return false;
  }

  return true;
};

export const useMarketingBannerVariant = (): MarketingEventBannerProps['mode'] => {
  const atBp3 = useMediaQuery(media.bp3);

  if (atBp3) {
    return 'inline';
  }

  return 'stacked';
};

export const useDisplayMarketingBannerBelowBar = () => {
  const atBp2 = useMediaQuery(media.bp2);

  if (atBp2) {
    return false;
  }

  return true;
};
