import React from 'react';
import * as queryString from 'query-string';
import { Link as RouterLink, Navigate } from 'react-router-dom';

import { validateResetToken, resetPassword, InvalidPasswordError } from '@attentive/auth-core';
import {
  Banner,
  Button,
  Heading,
  InputGroup,
  Text,
  Link,
  LoadingIndicator,
  FormField,
  Box,
  TextInput,
  Wordmark,
  List,
} from '@attentive/picnic';

import { setDocumentTitle } from '../utils';
import { AUTH_PAGE_WIDTH } from '../constants';
import { SignInLayout } from '@attentive/auth-ui';

const ResetPassword: React.FC = () => {
  const [email, setEmail] = React.useState('');
  const [redirect, setRedirect] = React.useState<string | null>(null);
  const [password, setPassword] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [isWorking, setIsWorking] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [errorMessageDetails, setErrorMessageDetails] = React.useState<string[] | null>(null);

  const { token } = queryString.parse(location.search);

  React.useEffect(() => {
    if (!token || token instanceof Array) {
      setRedirect(`/signin?error=${encodeURIComponent('Not a valid reset token')}`);
      return;
    }
    setDocumentTitle('Reset Password');
    validateResetToken(token as string)
      .then((resp) => {
        setEmail(resp.email);
      })
      .catch((err: Error) => {
        setRedirect(`/signin?error=${encodeURIComponent(err.message)}`);
      });
  }, [token]);

  const handleSubmitForm = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      setIsWorking(true);
      setShowPassword(false);
      setErrorMessage(null);
      setErrorMessageDetails(null);

      resetPassword(password, `${token}`)
        .then(() => {
          setRedirect(`/signin?email=${encodeURIComponent(email)}&success=1`);
        })
        .catch((err: Error) => {
          if (err instanceof InvalidPasswordError) {
            setErrorMessage(err.message);
            setErrorMessageDetails(err.messageDetails);
          } else {
            const message = err.message || 'Unable to reset password.';
            setErrorMessage(message);
            setErrorMessageDetails(null);
          }
        })
        .finally(() => {
          setIsWorking(false);
        });
      return;
    },
    [email, password, token]
  );

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return (
    <SignInLayout>
      <Box css={{ width: '100%', maxWidth: AUTH_PAGE_WIDTH }}>
        <form onSubmit={handleSubmitForm}>
          <Wordmark
            title="Attentive reset password page"
            width="140px"
            css={{ mb: '$space12', display: 'block' }}
          />
          <Heading variant="page" css={{ marginBottom: '$space6' }}>
            Reset password
          </Heading>
          {Boolean(errorMessage) && (
            <Banner variant="error" css={{ mb: '$space4' }}>
              <Banner.Text>{errorMessage}</Banner.Text>
              {errorMessageDetails && (
                <List>
                  {errorMessageDetails.map((message) => (
                    <List.Item key={message}>{message}</List.Item>
                  ))}
                </List>
              )}
            </Banner>
          )}
          {isWorking && !email ? (
            <div>
              <LoadingIndicator />
            </div>
          ) : (
            <>
              <Box css={{ mb: '$space4' }}>
                <Text>
                  <FormField.Label htmlFor="email">Email address</FormField.Label>
                  {email}
                  <input type="hidden" id="email" name="email" value={email} />
                </Text>
              </Box>

              <FormField css={{ mb: '$space4' }}>
                <FormField.Label htmlFor="new-password">Password</FormField.Label>
                <InputGroup>
                  <TextInput
                    autoFocus
                    id="new-password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <InputGroup.RightElement>
                    <InputGroup.InlineButton
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? 'Hide' : 'Show'}
                    </InputGroup.InlineButton>
                  </InputGroup.RightElement>
                </InputGroup>
              </FormField>

              <Button
                type="submit"
                css={{
                  width: '100%',
                  mb: '$space8',
                }}
                loading={isWorking}
              >
                Reset password
              </Button>

              <Box css={{ textAlign: 'center' }}>
                <Link as={RouterLink} to="/signin">
                  Cancel
                </Link>
              </Box>
            </>
          )}
        </form>
      </Box>
    </SignInLayout>
  );
};

export default ResetPassword;
