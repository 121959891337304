import { createEntryPoint } from '@attentive/data-router';

import Query from './__generated__/SyncList_EDSLib_Query.graphql';

export const SyncListEntryPoint = createEntryPoint({
  component: () => import('./SyncList'),
  getQueries: ({ getCurrentCompanyId }) => ({
    query: {
      parameters: Query,
      variables: { companyId: getCurrentCompanyId(), first: 100, filter: {} },
    },
  }),
});
