import { styled } from '@attentive/picnic';

export const SIDEBAR_WIDTH = 209;
export const SIDEBAR_COLLAPSED_WIDTH = 73;

export const SidebarRegion = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  borderRight: '1px solid $borderDefault',
  height: '100%',
  transition: 'width 200ms ease-out',
  backgroundColor: '$bgDefault',
  overflowY: 'auto',

  variants: {
    size: {
      default: {
        width: SIDEBAR_WIDTH,
      },
      collapsed: {
        width: SIDEBAR_COLLAPSED_WIDTH,
      },
    },
  },

  defaultVariants: {
    size: 'default',
  },
});
