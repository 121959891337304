import React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { SubscriberDetailEntryPoint, SubscriberListEntryPoint } from '@attentive/crm/entrypoints';

const routes = () => {
  return (
    <Route Component={React.lazy(() => import('./CrmMfe'))}>
      <Route path=":id/:activeDetailsTab" {...SubscriberDetailEntryPoint} />
      <Route path=":id" element={<Navigate to="./subscriptions" />} />
      <Route path="*" element={<Navigate to="crm" />} />
      <Route index {...SubscriberListEntryPoint} />
    </Route>
  );
};

export { routes };
