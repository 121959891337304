import React from 'react';

import { Text, ResponsivePageGutters } from '@attentive/picnic';

interface EmergencyNoticeProps {
  appName: string;
  title: string;
}

export const EmergencyNotice: React.FC<EmergencyNoticeProps> = ({ appName, title }) => {
  return (
    <ResponsivePageGutters data-app-name={appName} css={{ py: '$space4' }}>
      <Text css={{ mb: '$space4' }}>
        We are unable to load{' '}
        <Text as="span" css={{ fontWeight: '$bold' }}>
          {title}
        </Text>{' '}
        at this time.
      </Text>
    </ResponsivePageGutters>
  );
};
