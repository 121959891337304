import React from 'react';

import { useSyncDatadogUserFragmentData } from '@attentive/acore-utils';

import { useAppBodyQueryData } from './AppBody';

export const AppInitDatadogLogger: React.VFC = () => {
  const queryData = useAppBodyQueryData();

  useSyncDatadogUserFragmentData(queryData);

  return null;
};
