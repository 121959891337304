/**
 * @generated SignedSource<<c1410c56bea2939b578f97d7f7ce4efe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationsMenuRowClientUIFragment_notification$data = {
  readonly callToAction: string;
  readonly description: string;
  readonly dismissable: boolean;
  readonly id: string;
  readonly read: boolean;
  readonly severity: string;
  readonly title: string;
  readonly url: string;
  readonly " $fragmentType": "NotificationsMenuRowClientUIFragment_notification";
};
export type NotificationsMenuRowClientUIFragment_notification$key = {
  readonly " $data"?: NotificationsMenuRowClientUIFragment_notification$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationsMenuRowClientUIFragment_notification">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationsMenuRowClientUIFragment_notification",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "severity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dismissable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "callToAction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "read",
      "storageKey": null
    }
  ],
  "type": "Notification",
  "abstractKey": null
};

(node as any).hash = "00c90a60326f1faf8689554ad7fcfc00";

export default node;
