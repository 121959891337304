import { useAtomValue } from 'jotai';
import { z } from 'zod';

import { ThemeName } from '@attentive/picnic';
import {
  operatingModeRWAtom,
  themeNameAtom,
  OperatingModes,
  commitShaRWAtom,
  inSneakPreviewRWAtom,
  apiDataSourceAtom,
  tagDebugIgnoreMessageOriginMatchesAtom,
  tagCdnDomainSuffixAtom,
  legacyInboundWebhookServiceBaseUrlAtom,
  centralizedInboundWebhookServiceBaseUrlAtom,
  graphqlApiUrlRWAtom,
  graphqlApiOriginAtom,
  apiHostOverriddenAtom,
  liveSmsJourneyTemplateIdAtom,
  eventDestinationApiUrlAtom,
  AppStore,
} from '@attentive/acore-utils';
import { ApiDataSource } from '@attentive/data';

import { logZodErrors } from '../utils/zod-utils';
import { commitDetailsRWAtom } from '../components/version-dialog';
import {
  MockLoginGateStrategy,
  mockLoginGateStrategyAtom,
} from '../components/mock-accounts/mock-accounts-gated-atom';
import { zendeskBaseUrlAtom } from '@attentive/auth-core';
import { shopifyAppEmbedIdAtom } from '@attentive/acore-utils/src/atoms/integrations-atom';

export enum GraphqlDebugMode {
  Active = 'active',
  Inactive = 'inactive',
}

export const themeNameSchema = z.nativeEnum(ThemeName);
export const operatingModeSchema = z.nativeEnum(OperatingModes);
export const graphqlDebugModeSchema = z.nativeEnum(GraphqlDebugMode);
export const apiDataSourceSchema = z.nativeEnum(ApiDataSource);
export const mockLoginGateSchema = z.nativeEnum(MockLoginGateStrategy);

export const envVarSchema = z.object({
  apiUrl: z.string(),
  commitSha: z.string(),
  commitDetails: z.object({
    message: z.string(),
    timestamp: z.string(),
    commitSha: z.string(),
  }),
  datadogLoggingEnabled: z.boolean(),
  datadogSampleRate: z.number(),
  env: z.enum(['development', 'production']),
  envName: z.enum(['production', 'development', 'staging', 'local']),
  graphqlApiOrigin: z.string(),
  graphqlApiPathPrefix: z.string(),
  zendeskBaseUrl: z.string(),
  graphqlDebugMode: graphqlDebugModeSchema,
  inSneakPreview: z.boolean(),
  bundledWithEsbuild: z.boolean(),
  themeName: themeNameSchema,
  operatingMode: operatingModeSchema,
  apiDataSource: apiDataSourceSchema,
  mockLoginGateStrategy: mockLoginGateSchema,
  tagDebugIgnoreMessageOriginMatches: z.boolean(),
  tagCdnDomainSuffix: z.string(),
  legacyInboundWebhookServiceBaseUrl: z.string(),
  centralizedInboundWebhookServiceBaseUrl: z.string(),
  allowDevApiOverride: z.boolean(),
  apiHostOverridden: z.boolean(),
  liveSmsJourneyTemplateId: z.string(),
  shopifyAppEmbedId: z.string(),
  eventDestinationUploadApiUrl: z.string(),
});

export type EnvironmentVariables = z.infer<typeof envVarSchema>;

export type EnvironmentVariableNames = keyof EnvironmentVariables;

export type AppDocumentEnvVarsNames = keyof AppDocumentEnvVars;

export type AppInitEnvVarsNames = keyof AppInitEnvVars;

// These should be kept in sync
export type AppDocumentEnvVars = Omit<EnvironmentVariables, 'inSneakPreview' | 'apiHostOverridden'>;
export type AppInitEnvVars = Pick<EnvironmentVariables, 'inSneakPreview' | 'apiHostOverridden'>;

// This should match the AppDocumentEnvVars and AppInitEnvVars types above
export const appDocumentEnvVarsSchema = envVarSchema.omit({
  inSneakPreview: true,
  apiHostOverridden: true,
});

export const platformEnvVarsSchema = envVarSchema.omit({
  commitDetails: true,
  bundledWithEsbuild: true,
});

export const allowedQSEnvVarOverrides = (allowApiOverride: boolean) =>
  envVarSchema.pick({
    themeName: true,
    operatingMode: true,
    graphqlDebugMode: true,
    apiDataSource: true,
    tagDebugIgnoreMessageOriginMatchesAtom: true,
    graphqlApiOrigin: allowApiOverride ? true : undefined,
  });

export const hydrateEnvVarAtoms = (overrides: Partial<EnvironmentVariables>) => {
  if (overrides.themeName !== undefined) {
    AppStore.store.set(themeNameAtom, overrides.themeName);
  }

  if (overrides.operatingMode !== undefined) {
    AppStore.store.set(operatingModeRWAtom, overrides.operatingMode);
  }

  if (overrides.commitSha !== undefined) {
    AppStore.store.set(commitShaRWAtom, overrides.commitSha);
  }

  if (overrides.commitDetails !== undefined) {
    AppStore.store.set(commitDetailsRWAtom, overrides.commitDetails);
  }

  if (overrides.apiDataSource !== undefined) {
    AppStore.store.set(apiDataSourceAtom, overrides.apiDataSource);
  }

  if (overrides.mockLoginGateStrategy !== undefined) {
    AppStore.store.set(mockLoginGateStrategyAtom, overrides.mockLoginGateStrategy);
  }

  if (overrides.tagDebugIgnoreMessageOriginMatches !== undefined) {
    AppStore.store.set(
      tagDebugIgnoreMessageOriginMatchesAtom,
      overrides.tagDebugIgnoreMessageOriginMatches
    );
  }

  if (overrides.tagCdnDomainSuffix !== undefined) {
    AppStore.store.set(tagCdnDomainSuffixAtom, overrides.tagCdnDomainSuffix);
  }

  if (overrides.legacyInboundWebhookServiceBaseUrl !== undefined) {
    AppStore.store.set(
      legacyInboundWebhookServiceBaseUrlAtom,
      overrides.legacyInboundWebhookServiceBaseUrl
    );
  }

  if (overrides.centralizedInboundWebhookServiceBaseUrl !== undefined) {
    AppStore.store.set(
      centralizedInboundWebhookServiceBaseUrlAtom,
      overrides.centralizedInboundWebhookServiceBaseUrl
    );
  }

  if (overrides.shopifyAppEmbedId !== undefined) {
    AppStore.store.set(shopifyAppEmbedIdAtom, overrides.shopifyAppEmbedId);
  }

  if (overrides.inSneakPreview !== undefined) {
    AppStore.store.set(inSneakPreviewRWAtom, overrides.inSneakPreview);
  }

  if (overrides.graphqlApiOrigin !== undefined) {
    AppStore.store.set(graphqlApiOriginAtom, overrides.graphqlApiOrigin);
  }

  AppStore.store.set(
    graphqlApiUrlRWAtom,
    `${overrides.graphqlApiOrigin}${overrides.graphqlApiPathPrefix}`
  );

  AppStore.store.set(apiHostOverriddenAtom, overrides.apiHostOverridden || false);

  if (overrides.liveSmsJourneyTemplateId) {
    AppStore.store.set(liveSmsJourneyTemplateIdAtom, overrides.liveSmsJourneyTemplateId);
  }

  if (overrides.zendeskBaseUrl) {
    AppStore.store.set(zendeskBaseUrlAtom, overrides.zendeskBaseUrl);
  }

  if (overrides.eventDestinationUploadApiUrl) {
    AppStore.store.set(eventDestinationApiUrlAtom, overrides.eventDestinationUploadApiUrl);
  }
};

type ParseEnvVarsFromUrlOptions = {
  allowApiOverride: boolean;
};

export const parseEnvVarsFromUrl = (qs: string, options?: ParseEnvVarsFromUrlOptions) => {
  const searchParams = new URLSearchParams(qs);

  const params = Object.fromEntries(searchParams);

  const { allowApiOverride } = options || { allowApiOverride: false };

  const parsedEnvVars = allowedQSEnvVarOverrides(allowApiOverride).deepPartial().safeParse(params);

  if (parsedEnvVars.success) {
    return parsedEnvVars.data;
  }

  console.group("Error with environment variables from the URL's querystring");

  logZodErrors(parsedEnvVars.error.format());

  console.warn('No overrides applied');

  console.groupEnd();

  return {};
};

export const useThemeName = () => {
  return useAtomValue(themeNameAtom);
};

export const useEmergencyMode = () => {
  return useAtomValue(operatingModeRWAtom) === OperatingModes.Emergency;
};

export const getEmergencyMode = () => {
  return AppStore.store.get(operatingModeRWAtom) === OperatingModes.Emergency;
};
