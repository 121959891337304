import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { styled, List } from '@attentive/picnic';

import { useSelectedItemPaths } from './useSelectedItemPaths';
import { SidebarNavOnClickHandler } from './SidebarNavItem';

export interface SidebarSubNavItemProps {
  path: string;
  label: string;
  onClick?: SidebarNavOnClickHandler;
  trackingId?: string;
}

const SidebarLink = styled(ReactRouterLink, {
  width: '100%',
  // border width of parent + parent-icon-padding + parent-icon + icon-right-margin - marginLeft
  paddingLeft: 'calc($borderWidths$borderWidth2 + $space6 + $sizes$size8 + $space2 - $space2 )',
  transition: 'background-color 200ms ease-in-out',
  border: '$borderWidths$borderWidth2 solid transparent',
  color: '$textDefault',
  display: 'flex',
  alignItems: 'center',
  fontSize: '$fontSize2',
  lineHeight: '$lineHeight1',
  textDecoration: 'none',

  '&:hover': {
    backgroundColor: '$bgAccent',
  },

  '&:focus-visible': {
    outline: 'none',
    borderColor: '$borderFocus',
  },

  variants: {
    selected: {
      false: {},
      true: {
        backgroundColor: '$bgAccent',
      },
    },
  },
});

export const SidebarSubNavItem: React.VFC<SidebarSubNavItemProps> = ({
  path,
  label,
  onClick = () => {},
  trackingId,
}) => {
  const { selectedSubNavItemPath } = useSelectedItemPaths();
  const isSelected = path === selectedSubNavItemPath;

  return (
    <List.Item
      css={{
        minHeight: '$size9',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <SidebarLink
        selected={isSelected}
        to={path}
        onClick={() => onClick()}
        data-nav-id={trackingId}
      >
        {label}
      </SidebarLink>
    </List.Item>
  );
};
