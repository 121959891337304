import React, { Suspense } from 'react';
import { LoadingIndicator, Box } from '@attentive/picnic';
import { Outlet } from 'react-router-dom';

const css = { height: '100%', width: '100%', display: 'grid', placeItems: 'center' };

const SuspenseFallback: React.FC = () => (
  <Box css={css}>
    <LoadingIndicator />
  </Box>
);
/**
 * A router outlet wrapped with a centered loading fallback
 */
export const SuspendedOutlet: React.FC = () => (
  <Suspense fallback={<SuspenseFallback />}>
    <Outlet />
  </Suspense>
);
