/**
 * @generated SignedSource<<aa90cb07da6e6e4959a66a22ececc013>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConciergeAvailabilityToggleFragment_account$data = {
  readonly conciergeAgent: {
    readonly isAvailable: boolean;
    readonly isEnrolledIntoChats: boolean;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "ConciergeAvailabilityToggleFragment_account";
};
export type ConciergeAvailabilityToggleFragment_account$key = {
  readonly " $data"?: ConciergeAvailabilityToggleFragment_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConciergeAvailabilityToggleFragment_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConciergeAvailabilityToggleFragment_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ConciergeAgent",
      "kind": "LinkedField",
      "name": "conciergeAgent",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isAvailable",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isEnrolledIntoChats",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "cce327b7f8f62eb6303c8af9966797e5";

export default node;
