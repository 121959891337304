import React from 'react';

import { Heading, Button, ResponsiveImage, Stack, Text } from '@attentive/picnic';
import SomethingWentWrongImage from '../../assets/platform-unavailable.png';
import { ErrorPageContentWidth } from '@attentive/acore-utils';

export const SomethingWentWrong: React.FC = () => (
  <Stack
    css={{
      alignItems: 'center',
      marginTop: '$space5',
    }}
  >
    <ResponsiveImage
      ratio={1288 / 840}
      alt="Something Went Wrong"
      src={SomethingWentWrongImage}
      css={{ width: '100%', maxWidth: ErrorPageContentWidth, mb: '$space8' }}
    />
    <Heading variant="page">Something Went Wrong</Heading>
    <Text>Sorry, something went wrong. Please refresh the page and try again.</Text>
    <Button onClick={() => window.location.reload()}>Click here to refresh the page.</Button>
  </Stack>
);

export default SomethingWentWrong;
