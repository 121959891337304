import { useEffect, useRef } from 'react';

export const useRefreshTimer = (refreshCallback: () => void, intervalMs: number) => {
  const refreshCallbackRef = useRef(refreshCallback);
  refreshCallbackRef.current = refreshCallback;

  const refreshTimeoutRef = useRef<number | undefined>();
  // Track which "generation" of timer is currently supposed to run.
  // We capture the current value each time the effect is mounted,
  // and increment it when the effect is unmounted.
  // That way we can tell if any particular callback is "supposed" to run again.
  const generationRef = useRef(0);

  useEffect(() => {
    const generation = generationRef.current;
    const refreshWrapper = async () => {
      clearTimeout(refreshTimeoutRef.current);

      refreshTimeoutRef.current = window.setTimeout(async () => {
        try {
          await refreshCallbackRef.current();
        } catch (err) {
          console.log(err);
        }
        if (generationRef.current === generation) {
          // If generationRef has changed, the hook was unmounted.
          refreshWrapper();
        }
      }, intervalMs);
    };

    refreshWrapper();

    return () => {
      generationRef.current += 1;
      clearTimeout(refreshTimeoutRef.current);
    };
  }, [intervalMs]);
};
