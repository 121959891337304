import { API, NetworkError, ServerError } from '@attentive/acore-utils';
import { EnvironmentVariables } from '../store/env-vars';

import { logError } from './loggerInstance';

interface DegradationWarningResult {
  header: string;
  body: string;
}

export const fetchDegradationWarning = async () => {
  const endpoint = '/degradationWarning';
  // GMRU: GET /degradationWarning
  const { body, status, bodyText } = await API.get<DegradationWarningResult>(endpoint);
  if (status !== 200 || !body) {
    throw new ServerError(bodyText || 'Unable to retrieve degradation warning', status, endpoint);
  }

  return body.body;
};

interface PlatformStatusResponse {
  platformAvailable: boolean;
  errorMessage?: string;
}

// https://www.notion.so/attentivemobile/1761d9c0764146d98bebed9fcf905e98?v=d2b195e1ff7a444eb82f45fab14ead72
export const fetchPlatformStatus = async (location: Location): Promise<PlatformStatusResponse> => {
  const platformURL = `${location.origin}/platform-status.json`;

  try {
    const response = await fetch(platformURL);

    if (response.ok) {
      let message: string | null = null;
      const platformManifest = await response.json();
      message = platformManifest.message;

      if (typeof message === 'string') {
        return { platformAvailable: false, errorMessage: message.trim() };
      }
    }
  } catch (err) {
    // Tolerate improperly configured or missing platform status file
    logError(new NetworkError(err.message, platformURL));
  }

  return { platformAvailable: true };
};

// https://www.notion.so/attentivemobile/Emergency-Mode-d8fee306bb994964ae5d65dbdd60f523#4fb5195e055348bf81f1f2f82975f8e3
export const fetchPlatformManifest = async (
  location: Location
): Promise<Partial<EnvironmentVariables>> => {
  const platformURL = `${location.origin}/platform-manifest.json`;

  try {
    const response = await fetch(platformURL);

    if (response.ok) {
      return await response.json();
    }
  } catch (err) {
    logError(new NetworkError(err.message, platformURL));
  }

  return {};
};
