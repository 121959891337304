import React from 'react';

import { Box, Link, Text } from '@attentive/picnic';
import AppError from './AppError';

export const AuthenticatorUnavailable: React.FC = () => {
  return (
    <AppError>
      <Box css={{ textAlign: 'center' }}>
        <Text>Please register an authenticator before switching to a client company.</Text>
        <Text>
          Please navigate to{' '}
          <Link href="/settings/user-account-management/account-profile">here</Link> to register
          your authenticator.
        </Text>
      </Box>
    </AppError>
  );
};
