export const DEFAULT_MAX_RECENT_COMPANIES = 3;

export const suggestedCompanies = (
  recentCompanies: Array<{ id: string; externalId: number; name: string }>,
  extraCompanies: Array<{ id: string; externalId: number; name: string }>,
  options: { currentCompanyId?: string } = {}
) => {
  const { currentCompanyId = null } = options;

  const recentCompanyIds = recentCompanies.map(({ id }) => id);
  if (currentCompanyId) {
    recentCompanyIds.unshift(currentCompanyId);
  }

  const leftoverCompanies = extraCompanies.filter((c) => !recentCompanyIds.includes(c.id));
  return recentCompanies.filter((c) => c.id !== currentCompanyId).concat(leftoverCompanies);
};
