import React, { useState } from 'react';
import { IconButton, PicnicCss } from '@attentive/picnic';

import { SidebarNav, SidebarNavOnClickHandler } from '../sidebar/SidebarNav';

import { FullscreenPanel } from './FullscreenPanel';

export const HamburgerNavMenu: React.VFC<{
  css?: PicnicCss;
}> = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onClickItem: SidebarNavOnClickHandler = (parameters) => {
    // Some nav elements have subnavs which should be selectable in the fullscreen
    // panel. We shouldn't close the panel until a subnav has been selected.
    if (parameters && parameters.hasSubElements) {
      return;
    }
    setIsMenuOpen(false);
  };

  return (
    <>
      <IconButton
        iconName="Menu"
        description="Navigation Menu"
        onClick={() => {
          setIsMenuOpen(true);
        }}
        {...props}
      />
      <FullscreenPanel open={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
        <SidebarNav onClickItem={onClickItem} css={{ mb: '$space3' }} />
      </FullscreenPanel>
    </>
  );
};
