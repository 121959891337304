import React, { useMemo } from 'react';
import { atom, useAtom, useAtomValue } from 'jotai';

import { Box, Grid, Link, PicnicColorsToken, IconButton, ThemeName } from '@attentive/picnic';
import {
  commitShaAtom,
  Config,
  inSneakPreviewAtom,
  useApiDataSource,
  usePermission,
} from '@attentive/acore-utils';
import { escapeFromSneakPreview } from '@attentive/sneak-preview-utils/sneak-preview';
import { Permission, ApiDataSource } from '@attentive/data';

import {
  canShowDeveloperBanner,
  persistDeveloperBannerState,
  retrieveDeveloperBannerState,
} from './developer-banner-helpers';
import { useHydrateAtoms } from 'jotai/utils';
import { useThemeName } from '../../store/env-vars';

export const DEVELOPER_BANNER_STORAGE_KEY = 'developer-banner-visibility';
export type DeveloperBannerVisibility = 'visible' | 'hidden';

export const developerBannerVisibilityAtom = atom<DeveloperBannerVisibility>('hidden');

interface SneakPreviewLinkProps {
  githubCommitUrl: string;
  sneakPreviewCommitSha: string;
}

const SneakPreviewInfo = ({ githubCommitUrl, sneakPreviewCommitSha }: SneakPreviewLinkProps) => {
  return (
    <Box
      css={{ display: 'flex', alignItems: 'center', fontSize: '$fontSize1', whiteSpace: 'nowrap' }}
    >
      In Sneak Preview
      <Link
        css={{ ml: '$space1', mr: '$space1' }}
        href={githubCommitUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {sneakPreviewCommitSha}
      </Link>
      <IconButton
        size="small"
        iconName="EyeStrikethrough"
        description="Escape from sneak preview"
        onClick={() => escapeFromSneakPreview(location)}
      />
    </Box>
  );
};

interface DeveloperBannerProps {
  localDev?: boolean;
  onHide?: () => void;
}

export const DeveloperBanner: React.FC<DeveloperBannerProps> = ({
  localDev = false,
  onHide = () => {},
}) => {
  const acoreEnv = Config.get('env');
  const developerBannerDisplayMode: string = Config.get('developerBannerDisplayMode');
  const apiDataSource = useApiDataSource();
  const themeName = useThemeName();
  const isDarkMode = themeName === ThemeName.ThemeDark;

  const hasDeveloperBannerAccess = usePermission(Permission.DeveloperBannerAccess);

  const shouldShowBanner = useMemo(() => {
    const canShowBanner = canShowDeveloperBanner(
      hasDeveloperBannerAccess,
      window.location,
      developerBannerDisplayMode
    );

    if (retrieveDeveloperBannerState() === 'hidden') {
      return false;
    }
    return canShowBanner;
  }, [hasDeveloperBannerAccess, developerBannerDisplayMode]);

  useHydrateAtoms([[developerBannerVisibilityAtom, shouldShowBanner ? 'visible' : 'hidden']]);
  const [developerBannerVisibility, updateDeveloperBannerVisibility] = useAtom(
    developerBannerVisibilityAtom
  );

  const sneakPreview = useAtomValue(inSneakPreviewAtom);
  const sneakPreviewCommitSha = useAtomValue(commitShaAtom);

  if (developerBannerVisibility !== 'visible') {
    return null;
  }

  const dismissBanner = () => {
    persistDeveloperBannerState('hidden');
    updateDeveloperBannerVisibility('hidden');
    onHide();
  };

  let backgroundColor: PicnicColorsToken = Config.get(
    'developerBannerBgColor'
  ) as unknown as PicnicColorsToken;

  const envName = localDev ? 'local dev' : acoreEnv;
  const githubCommitUrl = `https://github.com/attentive-mobile/frontend-code/commit/${sneakPreviewCommitSha}`;

  if (isDarkMode) {
    backgroundColor = backgroundColor.replace('100', '800') as unknown as PicnicColorsToken;
  }

  const mocked = apiDataSource === ApiDataSource.Mock ? 'mocked' : '';

  return (
    <Grid
      css={{ background: backgroundColor, alignItems: 'center', p: '$space2' }}
      columns={[4, 6, 12, 12]}
      data-test-id="developer-banner"
    >
      <Grid.Cell colSpan={[3, 5, 11, 11]}>
        <Box css={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'baseline',
              '@bp2': {
                flexDirection: 'row',
              },
            }}
          >
            <Box css={{ whiteSpace: 'nowrap', '@bp2': { marginRight: '$space4' } }}>
              Environment: {mocked} {envName}
            </Box>
          </Box>
          {sneakPreview && (
            <SneakPreviewInfo
              githubCommitUrl={githubCommitUrl}
              sneakPreviewCommitSha={sneakPreviewCommitSha}
            />
          )}
        </Box>
      </Grid.Cell>
      <Grid.Cell css={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton
          iconName="X"
          size="extraSmall"
          variant="subdued"
          description="Close"
          onClick={dismissBanner}
        />
      </Grid.Cell>
    </Grid>
  );
};
