import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { Box, Text } from '@attentive/picnic';

import { SectionName } from '../shared/constants/routes';
import { logError } from '../shared/utils/logger';

const ErrorFallback: React.VFC = () => {
  return (
    <Box
      css={{
        display: 'grid',
        placeItems: 'center',
        width: '100%',
        height: '100%',
        padding: '$space4',
      }}
    >
      <Text css={{ textAlign: 'center' }}>
        Unfortunately, you've encountered an error. Please contact your Attentive representative or
        reach out to whiteglove@attentivemobile.com if this problem persists.
      </Text>
    </Box>
  );
};

type Props = {
  sectionName: SectionName;
};

const RouteErrorBoundary: React.FC<Props> = ({ sectionName, children }) => {
  const handleError = (err: Error, info: { componentStack: string }) => {
    logError(err, {
      slotName: sectionName,
      componentStack: info.componentStack,
    });
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
      {children}
    </ErrorBoundary>
  );
};

export { RouteErrorBoundary };
