/**
 * @generated SignedSource<<7601add110d5826e02a553290f19187a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PreferredMfaMethod = "PREFERRED_MFA_METHOD_EMAIL" | "PREFERRED_MFA_METHOD_SMS" | "%future added value";
export type SendMfaOneTimePasscodeInput = {
  deviceIdentifier?: string | null;
  mfaToken: string;
  preferredMfaMethod?: PreferredMfaMethod | null;
};
export type codeVerificationHooksSendMfaOneTimePasscodeMutation$variables = {
  input: SendMfaOneTimePasscodeInput;
};
export type codeVerificationHooksSendMfaOneTimePasscodeMutation$data = {
  readonly sendMfaOneTimePasscode: {
    readonly error: string | null;
    readonly successful: boolean;
  } | null;
};
export type codeVerificationHooksSendMfaOneTimePasscodeMutation = {
  response: codeVerificationHooksSendMfaOneTimePasscodeMutation$data;
  variables: codeVerificationHooksSendMfaOneTimePasscodeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SendMfaOneTimePasscodePayload",
    "kind": "LinkedField",
    "name": "sendMfaOneTimePasscode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "successful",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "codeVerificationHooksSendMfaOneTimePasscodeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "codeVerificationHooksSendMfaOneTimePasscodeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e7ed215a39d97bc9a1a41100b64dba63",
    "id": null,
    "metadata": {},
    "name": "codeVerificationHooksSendMfaOneTimePasscodeMutation",
    "operationKind": "mutation",
    "text": "mutation codeVerificationHooksSendMfaOneTimePasscodeMutation(\n  $input: SendMfaOneTimePasscodeInput!\n) {\n  sendMfaOneTimePasscode(input: $input) {\n    successful\n    error\n  }\n}\n"
  }
};
})();

(node as any).hash = "bbf53f5d6969f3fd3a40479a89138bfe";

export default node;
