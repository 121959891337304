import React from 'react';

import { styled, Heading, Text, PicnicCss, Stack } from '@attentive/picnic';

import platformUnavailableSrc from '../assets/platform-unavailable.png';

const ImageStyled = styled('img', {
  width: 660,
  marginTop: '$space9',
  marginBottom: '$space9',
});

const unavailableLayout: PicnicCss = {
  flex: 1,
  alignItems: 'center',
  gap: '$space3',
};

export const PlatformUnavailable: React.FC = () => {
  return (
    <Stack id="platform-unavailable" css={unavailableLayout}>
      <ImageStyled src={platformUnavailableSrc} alt="Platform Unavailable" />
      <Heading variant="page">Hmm... something's not right.</Heading>
      <Text>
        Our app is currently unavailable. We're working on a solution &mdash; please check back
        soon!
      </Text>
    </Stack>
  );
};

export default PlatformUnavailable;
