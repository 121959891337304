import React from 'react';

import { Box, Heading, ResponsiveImage } from '@attentive/picnic';

import platformUnavailableSrc from '../../assets/platform-unavailable.png';
import { useAtomValue } from 'jotai';
import { apiHostOverriddenAtom } from '@attentive/acore-utils';
import { ApiOverrideMessage } from './ApiOverrideMessage';
import { StandardMessage } from './StandardMessage';

const ErrorFallback = () => {
  const apiHostOverridden = useAtomValue(apiHostOverriddenAtom);

  return (
    <Box
      css={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}
    >
      <ResponsiveImage
        ratio={1333 / 801}
        alt="Platform Unavailable"
        src={platformUnavailableSrc}
        css={{ width: '100%', maxWidth: '660px', mb: '$space8' }}
      />
      <Heading variant="page" css={{ mb: '$space1' }}>
        Hmm... something's not right.
      </Heading>

      {apiHostOverridden ? <ApiOverrideMessage /> : <StandardMessage />}
    </Box>
  );
};

export default ErrorFallback;
