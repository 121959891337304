/**
 * @generated SignedSource<<83e66533ac6acdb7c0978645e63e4759>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConciergeTemplateRequestsCountBadgeClientUIQueryFragment_query$data = {
  readonly agentTemplateRequests: {
    readonly totalCount: number;
  } | null;
  readonly " $fragmentType": "ConciergeTemplateRequestsCountBadgeClientUIQueryFragment_query";
};
export type ConciergeTemplateRequestsCountBadgeClientUIQueryFragment_query$key = {
  readonly " $data"?: ConciergeTemplateRequestsCountBadgeClientUIQueryFragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConciergeTemplateRequestsCountBadgeClientUIQueryFragment_query">;
};

import ConciergeTemplateRequestsCountBadgeClientUIQueryFragmentRefetchQuery_graphql from './ConciergeTemplateRequestsCountBadgeClientUIQueryFragmentRefetchQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": ConciergeTemplateRequestsCountBadgeClientUIQueryFragmentRefetchQuery_graphql
    }
  },
  "name": "ConciergeTemplateRequestsCountBadgeClientUIQueryFragment_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TemplateRequestConnection",
      "kind": "LinkedField",
      "name": "agentTemplateRequests",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "0a455252d7d0f232b01189cc4c66232f";

export default node;
