import React, { ReactNode } from 'react';
import { Box, Grid, Link, Text } from '@attentive/picnic';

const PU_STEEL_BLUE = '#E0F1F4';

type CreatePasswordLayoutComponent = React.FC<
  | { children?: ReactNode; leftPane?: never; rightPane?: ReactNode }
  | { children?: never; leftPane: ReactNode; rightPane?: ReactNode }
>;

export const CreatePasswordLayout: CreatePasswordLayoutComponent = ({
  children,
  rightPane,
  leftPane,
}) => {
  return (
    <Box css={{ backgroundColor: '$bgAccent' }}>
      <Grid
        columns={[1, 2, 2, 3]}
        css={{
          $$signinLayout$contentTop: 'clamp(16px, 20vh, 256px)',
          backgroundColor: '$bgDefault',
          minHeight: '100vh',
          minWidth: '320px',
          overflowY: 'auto',
          maxWidth: '2200px',
          margin: '0 auto',
          boxShadow: '$shadow2',
        }}
      >
        <Grid.Cell colSpan={1} css={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            css={{
              flex: '1',
              display: 'flex',
              justifyContent: 'center',
              px: '$space8',
              paddingTop: '$$signinLayout$contentTop',
            }}
          >
            {children || leftPane}
          </Box>
          <Box css={{ padding: '$space2 $space2 $space2' }}>
            <Text variant="micro">
              &copy; {new Date().getFullYear()} Attentive. See{' '}
              <Link
                href="https://attentivemobile.com/terms/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms
              </Link>{' '}
              and{' '}
              <Link
                href="https://attentivemobile.com/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy
              </Link>
              .
            </Text>
          </Box>
        </Grid.Cell>
        <Grid.Cell
          colSpan={[1, 1, 1, 2]}
          css={{
            display: 'none',
            '@bp1': {
              justifyContent: 'center',
              display: 'grid',
              backgroundColor: PU_STEEL_BLUE,
              height: '100%',
            },
          }}
        >
          {rightPane}
        </Grid.Cell>
      </Grid>
    </Box>
  );
};
