import { createEntryPoint } from '@attentive/data-router';

import Query from './__generated__/AIJourneySettingsPageV2Query.graphql';

export const AIJourneySettingsPageEntryPoint = createEntryPoint({
  component: () => import('./AIJourneySettingsPageV2'),
  getQueries: ({ getCurrentCompanyId }) => ({
    query: { parameters: Query, variables: { companyId: getCurrentCompanyId() } },
  }),
});
