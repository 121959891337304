import _ClientUIAppBodyQuery from "./__generated__/ClientUIAppBodyQuery.graphql";
import { graphql } from '@attentive/data';
import { fetchQuery } from 'react-relay';
export const ClientUIAppBodyQueryQuery = _ClientUIAppBodyQuery;
export async function fetchClientUIAppBodyQuery(_ref) {
  let {
    relayEnvironment,
    isEmergencyMode,
    companyId
  } = _ref;
  const appQueryRef = await fetchQuery(relayEnvironment, ClientUIAppBodyQueryQuery, {
    companyId,
    isEmergencyMode
  }).toPromise();

  if (!appQueryRef) {
    throw 'App query not defined';
  }

  return appQueryRef;
}