import { createEntryPoint } from '@attentive/data-router';

import Query from '../ClientTargetingData/__generated__/ClientTargetingData_FeatureFlags_Query.graphql';

export const FeatureFlagsPageEntryPoint = createEntryPoint({
  component: () => import('./FeatureFlagsPage'),
  getQueries: ({ getCurrentCompanyId }) => ({
    query: {
      parameters: Query,
      variables: { companyId: getCurrentCompanyId(), first: 10 },
    },
  }),
});
