import React, { useState } from 'react';

import { VersionDialog } from '../version-dialog';
import { Icon, Wordmark } from '@attentive/picnic';

export enum LogoEasterEggVariant {
  WORD = 'Word',
  ICON = 'Icon',
}

interface LogoEasterEggProps {
  variant?: LogoEasterEggVariant;
}

export const LogoEasterEgg = ({ variant = LogoEasterEggVariant.WORD }: LogoEasterEggProps) => {
  const [versionDialogVisible, setVersionDialogVisible] = useState(false);

  const handleClick = (event: React.MouseEvent) => {
    if (!event.shiftKey) {
      return;
    }
    setVersionDialogVisible(true);
  };

  const handleOpenChange = (visible: boolean) => {
    setVersionDialogVisible(visible);
  };

  return (
    <>
      {variant === LogoEasterEggVariant.WORD && (
        <Wordmark title="Attentive" width="110px" onClick={handleClick} />
      )}
      {variant === LogoEasterEggVariant.ICON && (
        <Icon size="medium" name="Logo" color="default" onClick={handleClick} />
      )}
      <VersionDialog
        open={versionDialogVisible}
        onOpenChange={handleOpenChange}
        timings={window.performance.getEntries()}
      />
    </>
  );
};
