import { useState } from 'react';
import { useQuery } from '@attentive/data/react-query';

import { useDebouncedValue } from '@attentive/acore-utils';

import { logError } from '../../../../../../utils/loggerInstance';
import { queryZendeskHelpCenter, ZendeskSearchData } from './api';

const MIN_CHARS_FOR_SEARCH = 3;
const ITEMS_PER_PAGE = 5;
// Keep results in cache for 5 minutes.
const STALE_TIME_MILLIS = 1000 * 60 * 5;
const DEBOUNCE_DELAY_MILLIS = 200;

const DEFAULT_SEARCH_DATA: ZendeskSearchData = {
  pageInfo: {
    itemsPerPage: ITEMS_PER_PAGE,
    offset: 0,
    totalItems: 0,
    pageCount: 0,
  },
  articles: [],
};

interface UseZendeskSearch {
  query: string;
  setQuery: (value: string) => void;
  setOffset: (value: number) => void;
  isLoading: boolean;
  isError: boolean;
  isQueryTooShort: boolean;
  data: ZendeskSearchData;
  error?: Error;
}

export const useZendeskSearch = (): UseZendeskSearch => {
  const [query, setQuery] = useState('');
  const [offset, setOffset] = useState(0);
  const debouncedQuery = useDebouncedValue(query, DEBOUNCE_DELAY_MILLIS);

  const isQueryTooShort = debouncedQuery.length < MIN_CHARS_FOR_SEARCH;

  const { data, error, isLoading, isError } = useQuery<ZendeskSearchData, Error>(
    [debouncedQuery, offset],
    () =>
      queryZendeskHelpCenter({
        query: debouncedQuery,
        offset,
        itemsPerPage: ITEMS_PER_PAGE,
        // Disabling this for now as the current UX does not require category data.
        includeCategoryMetadata: false,
      }),
    {
      enabled: !isQueryTooShort,
      staleTime: STALE_TIME_MILLIS,
    }
  );

  const setQueryExternal = (newValue: string) => {
    setQuery(newValue);
    // Reset pagination whenever search query changes.
    setOffset(0);
  };

  const api = {
    query,
    setQuery: setQueryExternal,
    setOffset,
    isLoading,
    isError,
    isQueryTooShort,
  };

  if (error || !data) {
    if (error) {
      logError(error);
    }

    return {
      ...api,
      error: error as Error,
      data: DEFAULT_SEARCH_DATA,
    };
  }

  return {
    ...api,
    data,
  };
};
