import {
  AppStore,
  companyLifecycleAtom,
  getCompanyFeatureFlag,
  getRoles,
  LifecycleStatus,
} from '@attentive/acore-utils';
import { Role } from '@attentive/data';
import { atom, useAtomValue } from 'jotai';
import { readInlineData } from 'react-relay';
import { ClientUIMilestonesFragment_company$key } from './__generated__/ClientUIMilestonesFragment_company.graphql';
import { ClientUIAppBodyQuery$data } from '../authenticated-app/__generated__/ClientUIAppBodyQuery.graphql';
import { ClientUIMilestonesFragment } from './ClientUIMilestonesFragment';

export const hydratedOnboardingAtom = atom({
  redirectToOnboarding: false,
  hasOnboardingAccess: false,
  onboardingPath: '',
  blockOnboardingCompanyForm: false,
});

export const useOnboarding = () => {
  return useAtomValue(hydratedOnboardingAtom);
};

export const hydrateOnboarding = (queryRef: ClientUIAppBodyQuery$data) => {
  const isOnboardingCompany = getCompanyFeatureFlag('SELF_SERVE_ONBOARDING');

  const roles = getRoles();
  const isRedirectRole = roles.has(Role.RoleClientAccount) || roles.has(Role.RoleEngineering);
  const isSuperUser = roles.has(Role.RoleSuperUser);

  const frag = readInlineData<ClientUIMilestonesFragment_company$key>(
    ClientUIMilestonesFragment,
    queryRef.company
  );

  const incompleteMilestones = new Set(
    frag?.milestones?.milestones.filter((ms) => !ms.completed).map((ms) => ms.key)
  );

  // incomplete milestones
  const shouldCompleteForm =
    isOnboardingCompany && incompleteMilestones.has('MILESTONE_KEY_COMPLETED_COMPANY_FORM');
  const shouldCompleteIntegrationSurvey =
    isOnboardingCompany && incompleteMilestones.has('MILESTONE_KEY_COMPLETED_INTEGRATION_SURVEY');

  // completed onboarding in the past 24 hours
  const showNewbieHome =
    isOnboardingCompany && incompleteMilestones.has('MILESTONE_KEY_COMPLETED_ONBOARDING');

  const showOnboardingSurveys =
    isOnboardingCompany &&
    isRedirectRole &&
    (shouldCompleteForm || shouldCompleteIntegrationSurvey);

  /**
   * FIXME: temporary code
   * Smart recs data still relies on lifecycle and likely to change to use milestones directly
   * until then we derive lifecycle based on milestone here.
   **/
  if (showOnboardingSurveys) {
    AppStore.store.set(companyLifecycleAtom, LifecycleStatus.ONBOARDING);
  } else if (showNewbieHome) {
    AppStore.store.set(companyLifecycleAtom, LifecycleStatus.NEWBIE);
  } else {
    AppStore.store.set(companyLifecycleAtom, LifecycleStatus.TENURED);
  }

  let onboardingPath;

  if (shouldCompleteForm) {
    onboardingPath = '/onboarding/company';
  } else {
    onboardingPath = '/onboarding/integrations';
  }

  AppStore.store.set(hydratedOnboardingAtom, {
    redirectToOnboarding: showOnboardingSurveys,
    hasOnboardingAccess: isOnboardingCompany && isSuperUser,
    onboardingPath,
    blockOnboardingCompanyForm: !shouldCompleteForm,
  });
};
