/**
 * @generated SignedSource<<6c1dff1d5872a63e5d96caceed13aab7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Role = "ROLE_ADMIN_ACCESS" | "ROLE_ATTENTIVE_ACCOUNT" | "ROLE_CLIENT_ACCOUNT" | "ROLE_CLIENT_ADMIN_ACCOUNT" | "ROLE_CLIENT_LIMITED_ACCOUNT" | "ROLE_CLIENT_MEMBER_ACCOUNT" | "ROLE_CLIENT_STRATEGY" | "ROLE_CONCIERGE" | "ROLE_CONCIERGE_OPERATIONS" | "ROLE_CS_TAM" | "ROLE_E2E_TEST_USER" | "ROLE_ENGINEERING" | "ROLE_ENGINEERING_IDENTITY" | "ROLE_ENGINEERING_SALES" | "ROLE_ENGINEERING_SOLUTIONS" | "ROLE_IT_SECURITY" | "ROLE_MESSAGING_OPS" | "ROLE_PRODUCT_MANAGEMENT" | "ROLE_READONLY_CONCIERGE" | "ROLE_SUBSCRIBER_AUDITOR" | "ROLE_SUPER_USER" | "ROLE_UNKNOWN" | "%future added value";
export type Scope = "SCOPE_ALL_ACCOUNTS" | "SCOPE_ALL_CONCIERGE_SETTINGS" | "SCOPE_ALL_COUPONS" | "SCOPE_ALL_CUSTOM_ATTRIBUTES" | "SCOPE_ALL_CUSTOM_EVENTS" | "SCOPE_ALL_ECOMMERCE_EVENTS" | "SCOPE_ALL_IDENTITY" | "SCOPE_ALL_MESSAGES" | "SCOPE_ALL_OFFERS" | "SCOPE_ALL_POLLS" | "SCOPE_ALL_PRIVACY_REQUESTS" | "SCOPE_ALL_PRODUCT_CATALOGS" | "SCOPE_ALL_PROVISION" | "SCOPE_ALL_SCIM" | "SCOPE_ALL_SUBSCRIBER_EVENTS" | "SCOPE_ALL_WEBHOOKS" | "SCOPE_AUDIT_SUBSCRIPTIONS" | "SCOPE_UNKNOWN" | "SCOPE_VIEW_POLLS" | "SCOPE_VIEW_PRIVACY_REQUESTS" | "SCOPE_VIEW_PRODUCT_CATALOGS" | "SCOPE_VIEW_PROVISION" | "SCOPE_VIEW_SCIM" | "SCOPE_VIEW_SUBSCRIBER_EVENTS" | "%future added value";
export type AuthorizationDataProviderQuery$variables = {
  companyId: string;
};
export type AuthorizationDataProviderQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AuthorizationDataProviderQueryFragment_query">;
};
export type AuthorizationDataProviderQuery$rawResponse = {
  readonly viewer: {
    readonly roles: {
      readonly roles: ReadonlyArray<Role>;
    } | null;
    readonly scopes: ReadonlyArray<Scope> | null;
  } | null;
};
export type AuthorizationDataProviderQuery = {
  rawResponse: AuthorizationDataProviderQuery$rawResponse;
  response: AuthorizationDataProviderQuery$data;
  variables: AuthorizationDataProviderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "companyId",
    "variableName": "companyId"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GetRolesResponse",
        "kind": "LinkedField",
        "name": "roles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roles",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "kind": "ScalarField",
        "name": "scopes",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthorizationDataProviderQuery",
    "selections": [
      {
        "kind": "InlineDataFragmentSpread",
        "name": "AuthorizationDataProviderQueryFragment_query",
        "selections": (v2/*: any*/),
        "args": (v1/*: any*/),
        "argumentDefinitions": (v0/*: any*/)
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuthorizationDataProviderQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "b8231ee712d97aed3ecc8b3f5eb2a656",
    "id": null,
    "metadata": {},
    "name": "AuthorizationDataProviderQuery",
    "operationKind": "query",
    "text": "query AuthorizationDataProviderQuery(\n  $companyId: ID!\n) {\n  ...AuthorizationDataProviderQueryFragment_query_MWiJC\n}\n\nfragment AuthorizationDataProviderQueryFragment_query_MWiJC on Query {\n  viewer {\n    roles(companyId: $companyId) {\n      roles\n    }\n    scopes(companyId: $companyId)\n  }\n}\n"
  }
};
})();

(node as any).hash = "41fd79cccdbefd9eed875196211546a4";

export default node;
