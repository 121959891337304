import React, { VFC, useCallback } from 'react';

import { useCompanyFeatureFlag } from '@attentive/acore-utils';

import { useRefreshTimer } from '../../clocks/useRefreshTimer';
import { useUserIsAuthenticated } from '../../../store/site-data';

const REFRESH_INTERVAL_MS = 60 * 1000;

interface Props {
  onRefresh: () => void;
}

export const TemplateRequestsClock: VFC<Props> = ({ onRefresh }) => {
  const isAuthenticated = useUserIsAuthenticated();
  const lowDataMode = useCompanyFeatureFlag('UI_LOW_DATA_MODE');

  const refreshTemplateRequestsCount = useCallback(() => {
    if (lowDataMode || !isAuthenticated) {
      return;
    }

    onRefresh();
  }, [isAuthenticated, lowDataMode, onRefresh]);

  useRefreshTimer(refreshTemplateRequestsCount, REFRESH_INTERVAL_MS);

  React.useEffect(() => {
    refreshTemplateRequestsCount();
  }, [refreshTemplateRequestsCount]);

  return null;
};
