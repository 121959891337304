import { useMutation } from 'react-relay';
import { logError } from '../conciergeLogger';

import SendAgentHeartbeatMutation, {
  SendAgentHeartbeatMutation as SendAgentHeartbeatMutationType,
  SendAgentHeartbeatMutation$data as MutationResponse,
} from './__generated__/SendAgentHeartbeatMutation.graphql';

export const useSendAgentHeartbeatMutation = (accountId: string) => {
  const [commit, isInFlight] = useMutation<SendAgentHeartbeatMutationType>(
    SendAgentHeartbeatMutation
  );

  const handleCompleted = (resp: MutationResponse) => {
    if (!resp.sendAgentHeartbeat?.conciergeAgent) {
      logError(new Error('Failed to retrieve conciergeAgent in agent heartbeat'));
    }
  };

  const commitSendAgentHeartbeatMutation = (onSuccess?: () => void) => {
    commit({
      variables: { input: {} }, // Mutation expects empty input
      onCompleted: (resp: MutationResponse) => {
        handleCompleted(resp);
        onSuccess?.();
      },
      updater: (store) => {
        const agentRecord = store
          .getRootField('sendAgentHeartbeat')
          ?.getLinkedRecord('conciergeAgent');

        if (!agentRecord) {
          return;
        }
        const account = store.get(accountId);
        if (account && agentRecord) {
          account.setLinkedRecord(agentRecord, 'conciergeAgent');
        }
      },
      onError: (error) => {
        logError(error);
      },
    });
  };

  return {
    commitSendAgentHeartbeatMutation,
    isInFlight,
  };
};
