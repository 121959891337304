import React from 'react';

import { Box, Text, PicnicCss } from '@attentive/picnic';

import { AdminAccountDropdownMenu } from './AdminAccountDropdownMenu';

const topBarCss: PicnicCss = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderBottom: '$borderWidths$borderWidth1 solid $borderDefault',
  px: '$space6',
  flexShrink: 0,

  '@bp2': {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

export const AdminTopBar = () => {
  return (
    <Box css={{ ...topBarCss }}>
      <Text>Attentive Admin Center</Text>
      <AdminAccountDropdownMenu />
    </Box>
  );
};
