import React from 'react';

import { Box, Icon, IconCircle, Link, Text } from '@attentive/picnic';

interface SearchResultsPlaceholderProps
  extends Pick<React.ComponentProps<typeof IconCircle>, 'iconName' | 'color'> {
  helpCenterUrl?: string;
}

export const SearchResultsPlaceholder: React.FC<SearchResultsPlaceholderProps> = ({
  children,
  helpCenterUrl,
  ...iconCircleProps
}) => {
  return (
    <Box
      css={{
        display: 'flex',
        minHeight: '200px',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <IconCircle size="large" {...iconCircleProps} />
      <Text css={{ mt: '$space3' }}>{children}</Text>
      {helpCenterUrl && (
        <Link
          href={helpCenterUrl}
          target="_blank"
          rel="noopener noreferrer"
          css={{ mt: '$space3' }}
        >
          Visit the Help Center
          <Icon
            name="NewWindow"
            size="extraSmall"
            css={{
              ml: '$space2',
            }}
          />
        </Link>
      )}
    </Box>
  );
};
