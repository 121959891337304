import React from 'react';

import { PicnicCss, IconButton, TextInput, Icon, InputGroup } from '@attentive/picnic';

interface ZendeskSearchBarProps {
  query: string;
  onQueryChange: (query: string) => void;
  css?: PicnicCss;
}

export const ZendeskSearchBar: React.VFC<ZendeskSearchBarProps> = ({
  query,
  onQueryChange,
  css,
}) => {
  return (
    <InputGroup css={css}>
      <InputGroup.LeftElement>
        <Icon name="MagnifyingGlass" size="extraSmall" />
      </InputGroup.LeftElement>
      <TextInput
        placeholder="Search help center"
        size="small"
        value={query}
        onChange={(e) => {
          onQueryChange(e.currentTarget.value);
        }}
        data-nav-id="help-center-search-input"
      />
      {query && (
        <InputGroup.RightElement>
          <IconButton
            iconName="X"
            size="extraSmall"
            description="Clear search"
            onClick={() => onQueryChange('')}
          />
        </InputGroup.RightElement>
      )}
    </InputGroup>
  );
};
