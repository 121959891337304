import { createEntryPoint } from '@attentive/data-router';

import Query from './__generated__/SubscriberDetail_CRMLib_Query.graphql';

export const SubscriberDetailEntryPoint = createEntryPoint({
  component: () => import('./SubscriberDetail'),
  getQueries: ({ getCurrentCompanyId, params }) => ({
    query: {
      parameters: Query,
      variables: { companyId: getCurrentCompanyId(), subscriberId: params.id || '' },
    },
  }),
});
