import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Box, DropdownMenu, Separator } from '@attentive/picnic';
import { AccountMenuVariant } from './index';
import { linkBoxCss } from './AccountPanelMenu';
import { AdminRoutes } from '../../../../../routes';

export interface AdminMenuItemsProps {
  variant?: AccountMenuVariant;
}

export const AdminMenu = ({ variant }: AdminMenuItemsProps) => {
  if (variant === AccountMenuVariant.ICON_PANEL) {
    return (
      <>
        <Box as={RouterLink} css={linkBoxCss} to={AdminRoutes.AdminCenter}>
          Admin Center
        </Box>

        <Separator />
      </>
    );
  }
  return (
    <DropdownMenu.TextItem asChild>
      <RouterLink to={AdminRoutes.AdminCenter}>Admin Center</RouterLink>
    </DropdownMenu.TextItem>
  );
};
