import React, { useState } from 'react';
import { Banner, Box, FormField, Link, Text, Form } from '@attentive/picnic';
import { API } from '@attentive/acore-utils';
import { Link as RouterLink } from 'react-router-dom';
import { AdminRoutes } from '../../../routes';
import { object, string } from 'yup';

// must have a 555 in the number
const PHONE_REGEX_FROM = /^\+1\d{3}555\d{4}/;

export function Phony() {
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  return (
    <Form
      initialValues={{ from: '', body: '' }}
      validationSchema={object().shape({
        from: string()
          .matches(PHONE_REGEX_FROM, 'Must be a 555 number ex: +16315551212')
          .required('From is required'),
        body: string().required('Must include a message body'),
      })}
      onSubmit={async (values, actions) => {
        const response = await API.post<{ sendId: string }>('/phony-inbound', {
          from_phone: values.from,
          text: values.body,
        });

        actions.resetForm({ values: { ...values, body: '' } });

        if (response.status !== 200) {
          setErrorMsg('Error sending message');
          setSuccessMsg('');
        } else {
          setErrorMsg('');
          setSuccessMsg(`Message sent successfully ${response?.body?.sendId || ''}`);
        }
      }}
    >
      {successMsg && (
        <Banner css={{ marginBottom: '$space4' }} variant="success">
          <Text>
            {successMsg}, view in{' '}
            <Link css={{ display: 'inline' }} as={RouterLink} to={AdminRoutes.MessageExplorer}>
              Message Explorer
            </Link>
          </Text>
        </Banner>
      )}
      {errorMsg && (
        <Banner css={{ marginBottom: '$space4' }} variant="error">
          {errorMsg}
        </Banner>
      )}

      <Banner iconName="MessageBubbleDots" css={{ marginBottom: '$space4' }} variant="info">
        <Text>
          Phony enables the sending of inbound messages that bypass the providers.{' '}
          <Link href="https://attentivemobile.atlassian.net/l/cp/031w4WLw">
            Phony documentation
          </Link>
          .
        </Text>
        <br />
        <Text>Phony numbers must be in the form of +1xxx555xxxx</Text>
      </Banner>

      <Box css={{ display: 'flex', flexDirection: 'column', gap: '$space3' }}>
        <Form.FormField name="from">
          <FormField.Label>From</FormField.Label>
          <FormField.HelperText>The phone number of a Phony subscriber</FormField.HelperText>
          <Form.TextInput />
        </Form.FormField>
        <Form.FormField name="body">
          <FormField.Label>Body</FormField.Label>
          <Form.TextArea rows={3} />
        </Form.FormField>
        <Form.SubmitButton>Send</Form.SubmitButton>
      </Box>
    </Form>
  );
}
