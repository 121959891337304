import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { RelayEnvironmentProvider, Environment } from 'react-relay';
import { QueryClientProvider } from '@attentive/data/react-query';
import { Provider as JotaiProvider } from 'jotai/react';

import { CLIENT_UI_PROJECT_NAME, ProjectNameProvider } from '@attentive/acore-utils';
import { Tooltip } from '@attentive/picnic';

import ErrorFallback from './components/ErrorFallback/ErrorFallback';
import { logError } from './utils/loggerInstance';
import { queryClient } from './queryClient';

interface AppProvidersProps {
  relayEnvironment: Environment;
  router: ReturnType<typeof createBrowserRouter>;
  store: Parameters<typeof JotaiProvider>[0]['store'];
}

export const AppProviders: React.FC<AppProvidersProps> = ({ relayEnvironment, router, store }) => {
  return (
    <React.StrictMode>
      <JotaiProvider store={store}>
        <ProjectNameProvider value={CLIENT_UI_PROJECT_NAME}>
          <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
            <RelayEnvironmentProvider environment={relayEnvironment}>
              <QueryClientProvider client={queryClient}>
                <Tooltip.Provider>
                  <RouterProvider router={router} />
                </Tooltip.Provider>
              </QueryClientProvider>
            </RelayEnvironmentProvider>
          </ErrorBoundary>
        </ProjectNameProvider>
      </JotaiProvider>
    </React.StrictMode>
  );
};
