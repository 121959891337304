import React, { ReactNode } from 'react';

import { Box, PicnicCss } from '@attentive/picnic';

const loadingStyle: PicnicCss = {
  display: 'flex',
  placeItems: 'center',
  placeContent: 'center',
  textAlign: 'center',
  flex: 1,
  fontFamily: 'Helvetica, sans-serif',
  fontWeight: 400,
  letterSpacing: 'normal',
};

const AppLoading: React.FC = ({ children }) => {
  let message: ReactNode = <span>Loading, please wait...</span>;

  if (children) {
    message = children;
  }

  return <Box css={loadingStyle}>{message}</Box>;
};

export default AppLoading;
