import React from 'react';

import { Text, Grid, PicnicCss, Button, Heading, Box } from '@attentive/picnic';
import {
  readEnvVarsFromSessionStorage,
  resetEnvVarsInSessionStorage,
} from '../../utils/app-persistence';
import { useSearchParams } from 'react-router-dom';

const cellCss: PicnicCss = {
  p: '$space1',
};

export const SessionVariablesTable: React.FC = () => {
  const envVarsFromSessionStorage = readEnvVarsFromSessionStorage();
  const sessionEnvVars = Object.entries(envVarsFromSessionStorage);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleResetEnvVarsClick = () => {
    resetEnvVarsInSessionStorage();
    Object.keys(envVarsFromSessionStorage).forEach((key) => searchParams.delete(key));
    setSearchParams(searchParams);
    window.location.reload();
  };

  if (!sessionEnvVars.length) {
    return null;
  }

  return (
    <Box css={{ width: '100%' }}>
      <Grid columns={2} css={{ rowGap: '$space1', width: '100%' }}>
        <Grid.Cell css={{ display: 'flex', alignItems: 'center' }}>
          <Heading as="h2" variant="md">
            Session Env Variables
          </Heading>
        </Grid.Cell>
        <Grid.Cell css={{ display: 'flex', justifyContent: 'end' }}>
          <Button size="small" onClick={() => handleResetEnvVarsClick()}>
            Reset Env Vars
          </Button>
        </Grid.Cell>

        {sessionEnvVars.map(([key, value]) => (
          <React.Fragment key={key}>
            <Grid.Cell css={cellCss}>
              <Text>{key}:</Text>
            </Grid.Cell>
            <Grid.Cell css={cellCss}>
              <Text css={{ textAlign: 'right' }}>{value as string}</Text>
            </Grid.Cell>
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
};
