/**
 * @generated SignedSource<<958b4e2abfd75eb853225e85f63c1037>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientUIUnreadConversationsCountFragment_company$data = {
  readonly clientConversationUnreadCount: {
    readonly clientConversationUnreadCount: number;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "ClientUIUnreadConversationsCountFragment_company";
};
export type ClientUIUnreadConversationsCountFragment_company$key = {
  readonly " $data"?: ClientUIUnreadConversationsCountFragment_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientUIUnreadConversationsCountFragment_company">;
};

import ClientUIUnreadConversationsCountFragmentRefetchQuery_graphql from './ClientUIUnreadConversationsCountFragmentRefetchQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": ClientUIUnreadConversationsCountFragmentRefetchQuery_graphql,
      "identifierField": "id"
    }
  },
  "name": "ClientUIUnreadConversationsCountFragment_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UnreadCountResponse",
      "kind": "LinkedField",
      "name": "clientConversationUnreadCount",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clientConversationUnreadCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "61ba8dd1ca94cd47134e8e3a0459bbb4";

export default node;
