import { EnvironmentVariables, EnvironmentVariableNames } from '../store/env-vars';

export const MAX_RECENT_COMPANIES = 3;

export const SESSION_STORAGE_ENV_VARS_KEY = 'env-vars';

export const readEnvVarsFromSessionStorage = () => {
  try {
    const envVars = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_ENV_VARS_KEY) || '{}'
    ) as Partial<EnvironmentVariables>;
    return envVars;
  } catch (err) {
    console.error(err);
  }
  return {};
};

export const storeEnvVarsInSessionStorage = (envVars: Partial<EnvironmentVariables>) => {
  sessionStorage.setItem(SESSION_STORAGE_ENV_VARS_KEY, JSON.stringify(envVars));
};

export const resetEnvVarsInSessionStorage = () => {
  sessionStorage.removeItem(SESSION_STORAGE_ENV_VARS_KEY);
};

export const storeEnvVarInSessionStorage = (key: EnvironmentVariableNames, value: string) => {
  try {
    const envVars = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_ENV_VARS_KEY) || '{}');
    envVars[key] = value;
    storeEnvVarsInSessionStorage(envVars);
  } catch (err) {
    console.error(err);
  }
};

// @deprecated, use cId instead.
const companyRestIdQueryParam = 'companyId';
const companyExternalIdQueryParam = 'cId';
export const currentCompanyIdKey = 'currentCompanyId';

/**
 * Look for a requested company id for this session in the query params and session storage.
 */
export const parseRequestedCompanyId = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const companyRestIdFromQueryString = Number(searchParams.get(companyRestIdQueryParam));
  const companyExternalIdFromQueryString = Number(searchParams.get(companyExternalIdQueryParam));
  const companyRestIdFromSessionStorage = Number(sessionStorage.getItem(currentCompanyIdKey));

  if (companyExternalIdFromQueryString) {
    return {
      requestedCompanyExternalId: companyExternalIdFromQueryString,
    };
  }

  if (companyRestIdFromQueryString) {
    return {
      requestedCompanyRestId: companyRestIdFromQueryString,
    };
  }

  if (companyRestIdFromSessionStorage) {
    return {
      requestedCompanyRestId: companyRestIdFromSessionStorage,
    };
  }

  return {};
};

export const storeSessionCompanyId = (companyId: number) => {
  sessionStorage.setItem(currentCompanyIdKey, companyId.toString());
};
