import React, { createContext, useContext } from 'react';
import { matchPath } from 'react-router-dom';

import { SidebarNavItem } from './SidebarNavItem';
import { SidebarSubNavItem, SidebarSubNavItemProps } from './SidebarSubNavItem';

interface SelectedItemPaths {
  selectedNavItemPath?: string;
  selectedSubNavItemPath?: string;
}

export const SelectedPathsContext = createContext<SelectedItemPaths>({});

export const useSelectedItemPaths = () => {
  return useContext(SelectedPathsContext);
};

export const getSelectedNavItemPaths = (
  currentPath: string,
  children: React.ReactNode
): SelectedItemPaths => {
  let selectedNavItemPath;
  let selectedSubNavItemPath;

  const matchesCurrentPath = (path: string) => {
    return !!matchPath(
      {
        path,
        end: false,
      },
      currentPath
    );
  };

  React.Children.forEach(children, (child) => {
    if (!React.isValidElement(child) || child.type !== SidebarNavItem) {
      return;
    }

    const { path: navItemPath, children: navItemChildren } = child.props;

    if (matchesCurrentPath(navItemPath)) {
      selectedNavItemPath = navItemPath;
    }

    if (!child.props.children) {
      return;
    }

    React.Children.forEach(navItemChildren, (navItemChild) => {
      if (!React.isValidElement(navItemChild) || navItemChild.type !== SidebarSubNavItem) {
        return;
      }

      const { path: subNavItemPath } = navItemChild.props as SidebarSubNavItemProps;
      if (matchesCurrentPath(subNavItemPath)) {
        selectedNavItemPath = navItemPath;
        selectedSubNavItemPath = subNavItemPath;
      }
    });
  });

  return { selectedNavItemPath, selectedSubNavItemPath };
};
