import React from 'react';
import { ContainedLabel } from '@attentive/picnic';

export const NewFeatureLabel: React.VFC = () => {
  return (
    <ContainedLabel variant="decorative1" css={{ ml: '$space2' }}>
      New
    </ContainedLabel>
  );
};
