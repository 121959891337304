/**
 * @generated SignedSource<<baa2d07186e60ebfc843c576abd1653b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountMenuClientUIFragment_query$data = {
  readonly company: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"CompanyNameClientUIFragment_company">;
  };
  readonly viewer: {
    readonly account: {
      readonly " $fragmentSpreads": FragmentRefs<"AccountNameClientUIFragment_account">;
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"RecentCompaniesMenuSectionClientUIFragment_query">;
  readonly " $fragmentType": "AccountMenuClientUIFragment_query";
};
export type AccountMenuClientUIFragment_query$key = {
  readonly " $data"?: AccountMenuClientUIFragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountMenuClientUIFragment_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "companyId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountMenuClientUIFragment_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RecentCompaniesMenuSectionClientUIFragment_query"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "concreteType": "Account",
              "kind": "LinkedField",
              "name": "account",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AccountNameClientUIFragment_account"
                }
              ],
              "storageKey": null
            },
            "action": "THROW",
            "path": "viewer.account"
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "viewer"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": "company",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CompanyNameClientUIFragment_company"
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "company"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "5b65da04d9589f80fbdad9020b722dde";

export default node;
