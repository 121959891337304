import { createEntryPoint } from '@attentive/data-router';

import Query from './__generated__/SubscribersList_CRMLib_Query.graphql';

export const SegmentSubscribersListEntryPoint = createEntryPoint({
  component: () => import('./SegmentSubscribersListPage'),
  getQueries: ({ getCurrentCompanyId, params }) => ({
    query: {
      parameters: Query,
      variables: {
        companyId: getCurrentCompanyId(),
        first: 10,
        filter: { segmentInternalId: params.id },
      },
    },
  }),
});
