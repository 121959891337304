import React from 'react';

import { SidebarNavContainer, SidebarNavItem } from '../../client-layout/sidebar/SidebarNav';
import { AdminRoutes } from '../../../../routes';

export const EmergencyAdminSidebarNav: React.FC = () => {
  return (
    <SidebarNavContainer>
      <SidebarNavItem path={AdminRoutes.CampaignsControlCenter} label="Campaigns" />
      <SidebarNavItem path={AdminRoutes.FeatureFlags} label="Feature Flags" />
    </SidebarNavContainer>
  );
};
