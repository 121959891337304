import React from 'react';
import { Navigate, Outlet, matchPath, useLocation } from 'react-router-dom';

export const ConciergeAgentLayout = () => {
  const location = useLocation();
  const matchedClientUIConversationPageRoute = matchPath(
    {
      path: '/conversations/:userId',
      end: true,
    },
    location.pathname
  );

  if (matchedClientUIConversationPageRoute) {
    const conversationUserId = matchedClientUIConversationPageRoute.params.userId;
    const redirectPath = `/concierge/conversation/${conversationUserId}`;

    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};
