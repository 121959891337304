import React from 'react';

import { sendTrackedTags } from '../../../utils/performanceMark';
import { useRefreshTimer } from '../useRefreshTimer';

const REFRESH_INTERVAL_MS = 60 * 1000;

export function TrackerClock() {
  useRefreshTimer(sendTrackedTags, REFRESH_INTERVAL_MS);

  React.useEffect(() => {
    sendTrackedTags();
  }, []);

  return null;
}
