import React, { useState } from 'react';
import { Text, Button, TextInput, FormField, Stack, Box } from '@attentive/picnic';
import { GRAPHQL_API_ORIGIN_KEY, graphqlApiOriginAtom } from '@attentive/acore-utils';
import { useAtomValue } from 'jotai';

const DEV_LOCAL_URL = 'http://localhost:5002';

export const ApiTools: React.VFC = () => {
  const graphqlApiUrl = useAtomValue(graphqlApiOriginAtom);

  const [graphqlOrigin, setGraphqlOrigin] = useState(DEV_LOCAL_URL);

  const handleClick = () => {
    const newLocation = new URL(window.location.href);
    newLocation.searchParams.set(GRAPHQL_API_ORIGIN_KEY, graphqlOrigin);
    window.location.href = newLocation.toString();
  };

  return (
    <Stack>
      <Text>Overrides to allow interacting with a local API.</Text>
      <Text>
        Currently, graphqlAPIURL points to:
        <br />
        <Box as="code" css={{ background: '$bgAccent', p: '$space1' }}>
          {graphqlApiUrl}
        </Box>
      </Text>
      <FormField>
        <FormField.Label>Graphql API Source</FormField.Label>
        <FormField.HelperText>The new hostname for the Graphql API</FormField.HelperText>
        <TextInput value={graphqlOrigin} onChange={(e) => setGraphqlOrigin(e.target.value)} />
      </FormField>
      <Button onClick={handleClick}>Refresh Page With New Setting</Button>
    </Stack>
  );
};
