import { useState } from 'react';
import { isBefore } from 'date-fns';

export const useNewFeatureAnnouncement = (key: string, expirationDate?: Date) => {
  const storageKey = `clientUI_hasSeenAnnouncement__${key}`;

  const isOpenInitial =
    !localStorage.getItem(storageKey) && (!expirationDate || isBefore(new Date(), expirationDate));

  const [isOpen, setIsOpen] = useState(isOpenInitial);

  const setHasSeen = () => {
    localStorage.setItem(storageKey, 'true');
  };

  const dismiss = () => {
    setHasSeen();
    setIsOpen(false);
  };

  return { isOpen, setIsOpen, dismiss };
};
