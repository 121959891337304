import React from 'react';
import { Outlet } from 'react-router-dom';

import { Box, PicnicCss, Themes, usePicnicStyles } from '@attentive/picnic';
import { ToastContainer } from '@attentive/acore-utils';
import { ErrorBoundary } from 'react-error-boundary';
import { GRID_CONTENT_ELEMENT_ID } from './constants';
import { useThemeName } from './store/env-vars';
import { usePageTracker } from './utils/app';
import ErrorFallback from './components/ErrorFallback';
import { logError } from './utils/loggerInstance';

const rootStyles: PicnicCss = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
};

export const App: React.FC = () => {
  const themeName = useThemeName();

  usePageTracker();

  usePicnicStyles(Themes[themeName]);
  return (
    <Box css={rootStyles}>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
        <Outlet />
        <ToastContainer anchorElementId={GRID_CONTENT_ELEMENT_ID} />
      </ErrorBoundary>
    </Box>
  );
};
