import { CompanyFeatureFlagNames, CompanyFeatureFlags } from '@attentive/acore-utils';

const flags = new Set<CompanyFeatureFlagNames>(['ENABLE_TRIGGERED_EMAIL', 'ENABLE_CAMPAIGN_EMAIL']);

export const getFeatureFlagTrackingProperties = (featureFlags: CompanyFeatureFlags) => {
  const properties: { [key: string]: boolean } = {};
  flags.forEach((flag) => {
    if (flag in featureFlags) {
      Object.assign(properties, { [flag]: featureFlags[flag] });
    }
  });
  return properties;
};
